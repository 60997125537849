import { Component, inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { PipesModule } from 'src/app/modules/shared/pipes/pipes.module';
import { CommonService } from 'src/app/modules/shared/services/common.service';

/**
 * Component for displaying an inactive session warning.
 */
@Component({
  selector: 'app-inactive-session-warning',
  standalone: true,
  imports: [PipesModule, FeatherModule, MatDialogModule],
  templateUrl: './inactive-session-warning.component.html',
  styleUrl: './inactive-session-warning.component.scss',
})
export class InactiveSessionWarningComponent {
  /** Instance of CommonService injected into the component. */
  public commonService = inject(CommonService);
}
