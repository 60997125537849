<div class="dialog-sm">
  <div class="message-template">
    <div class="hs-tab-card-title flex-between">
      <h2>{{ contactData.header | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather name="check" class="pointer" (click)="saveParticipants()"></i-feather>
        <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
      </div>
    </div>
    <mat-dialog-content>
      <app-message-contacts
        [participantList]="participantList"
        (updatedManagedUserEvent)="updateManagedUser($event)"
        *ngIf="renderContactComponent"
      ></app-message-contacts>
    </mat-dialog-content>
  </div>
</div>
