import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { FilterComponent } from 'src/app/modules/shared/component/filter/filter.component';
import { FilterColumnOption, FilterOptions } from 'src/app/modules/shared/interfaces/common.entities';
import { PaginationOptions, ParamsOptions } from 'src/app/modules/shared/interfaces/pagination-options';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { Utils } from 'src/app/modules/shared/utils/utils';
import { UsersService } from 'src/app/modules/user-management/users.service';
import { IdNameLabel, StatusFilterOptions, User, UserGroup } from '../../entities/groups';
import { GroupsService } from '../../groups.service';
/**
 * Component for adding users to a group.
 */
@Component({
  selector: 'app-group-useradd',
  templateUrl: './group-useradd.component.html',
  styleUrls: ['./group-useradd.component.scss'],
})
export class GroupUseraddComponent implements OnInit {
  /** Group ID */
  gId = '';
  /** Client ID */
  clientId = '';
  /** Selected group name */
  selectedGroup = '';
  /** User type */
  userType = '';
  /** Flag to show/hide checkbox */
  showCheckBox = true;
  /** Array of users */
  users: Array<User> = [];
  /** Array of selected user IDs */
  selectedUserIds: string[] = [];
  /** Array of displayed columns */
  displayedColumns: Array<string> = [];

  /** Column definitions for the table. */
  columns = [
    {
      key: 'name',
      title: 'Name',
      displayKey: 'name',
      filterKey: 'name',
      searchKey: 'name',
      sort: true,
      search: { icon: true, active: false },
      filter: {
        icon: false,
        active: false,
      },
      active: true,
      order: 'DESC',
    },
    {
      key: 'userType',
      title: 'User Type',
      displayKey: 'userType',
      sort: true,
      search: { icon: false, active: false },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'affiliationCode',
      title: 'Affiliation Code',
      displayKey: 'affiliationCode',
      sort: true,
      search: { icon: false, active: false },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'userGroups',
      title: 'User Group',
      displayKey: 'userGroup',
      checkbox: false,
      filterKey: 'userGroups',
      searchKey: 'userGroups',
      sort: true,
      search: { icon: false, active: false },
      filter: {
        icon: true,
        active: false,
      },
    },
    {
      key: 'role',
      title: 'Roles',
      displayKey: 'roles',
      sort: true,
      search: { icon: false, active: false },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'contentGroups',
      title: 'Content Groups',
      displayKey: 'contentGroups',
      filterKey: 'contentGroups',
      searchKey: 'contentGroups',
      sort: true,
      search: { icon: false, active: false },
      filter: {
        icon: false,
        active: false,
      },
    },
  ];

  /** Filter options for user status */
  filterOptions: StatusFilterOptions[] = [
    {
      key: 'Active',
      label: 'Active users',
      displayKey: 'activeUsers',
    },
    {
      key: 'InActive',
      label: 'Inactive users',
      displayKey: 'inActiveUsers',
    },
  ];

  /** Parameters for API pagination */
  params: ParamsOptions = { limit: 20, page: 1, sort: 'name|DESC', filter: '' };
  /** Pagination options object */
  paginationOptions!: PaginationOptions;
  /** Selected filter by option */
  selectedFilterByOption: StatusFilterOptions = this.filterOptions[0];

  /**
   * Constructs the GroupUseraddComponent.
   * @param router Angular router service
   * @param route Angular activated route service
   * @param toastrService Toastr service for displaying notifications
   * @param usersService Users service for fetching users data
   * @param filterService Filter service for managing filters
   * @param groupsService Groups service for managing groups data
   * @param permissionsService Permissions service for user permissions
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private usersService: UsersService,
    private filterService: FilterService,
    private groupsService: GroupsService,
    private permissionsService: PermissionsService,
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      if (params) {
        this.setQueryParams(params);
        this.setDefaultParams();
      }
    });
    this.addClinicalTrialsColumn();
  }

  /**
   * Initializes displayed columns, checks group ID, and applies default filter option.
   */
  ngOnInit(): void {
    this.displayedColumns = this.columns.map(
      (column: { key: string; title: string; displayKey: string; sort: boolean }) => {
        return column.title;
      },
    );
    this.getUsers();
  }

  /**
   * Sets query parameters from activated route.
   * @param params Query parameters object
   */
  setQueryParams(params: Params) {
    if (params) {
      this.clientId = params.clientId;
      this.gId = params.gid;
      this.selectedGroup = params.selectedGroup;
      this.userType = params.userLevel;
    }
  }

  /**
   * Sets default parameters based on user type and selected filter option.
   */
  setDefaultParams() {
    if (this.getUserType()) {
      this.filterService.setAppliedFilters('userType', { id: this.getUserType(), name: this.getUserType() });
    }
    if (this.selectedFilterByOption) {
      this.filterService.setAppliedFilters('status', {
        id: this.selectedFilterByOption?.key,
        name: this.selectedFilterByOption?.label,
      });
    }
  }

  /**
   * Adds clinical trials column to the table columns array based on client type.
   */
  addClinicalTrialsColumn() {
    const clinicalTrialsColumn = {
      key: 'clinicalTrials',
      title: 'Clinical Trial',
      displayKey: 'clinicalTrials',
      filterKey: 'clinicalTrials',
      searchKey: 'clinicalTrials',
      sort: true,
      search: { icon: false, active: false },
      filter: {
        icon: false,
        active: false,
      },
    };
    if (this.getClientType()?.toLowerCase() === 'clinicaltrial') {
      this.columns.push(clinicalTrialsColumn);
    }
  }

  /**
   * Retrieves user configurations using permissions service.
   * @returns User configurations or null if not available.
   */
  getUserConfigurations() {
    return this.permissionsService.getUserConfigurations() || null;
  }

  /**
   * Retrieves the client type from user configurations.
   * @returns Client type or empty string if not available.
   */
  getClientType() {
    return this.getUserConfigurations()?.clientType || '';
  }

  /**
   * Determines the user type based on client and user configurations.
   * @returns User type ('Manager', '', or original user type).
   */
  getUserType() {
    if (this.getClientType()?.toLowerCase() === 'reachtrial' && this.userType?.toLowerCase() === 'managed') {
      return '';
    }
    if (this.userType?.toLowerCase() === 'observer') {
      return 'Manager';
    }
    return this.userType;
  }

  /**
   * Determines the user level based on user type.
   * @returns User level ('coOwner', 'member', or 'observer').
   */
  getUserLevel() {
    let userLevel = '';
    if (this.userType?.toLowerCase() === 'manager') {
      userLevel = 'coOwner';
    }
    if (this.userType?.toLowerCase() === 'managed') {
      userLevel = 'member';
    }
    if (this.userType?.toLowerCase() === 'observer') {
      userLevel = 'observer';
    }
    return userLevel;
  }

  /**
   * Determines the tab index based on user level.
   * @returns Tab index (0 for 'coOwner', 1 for 'observer', 2 for 'member', or 0 by default).
   */
  getTabIndex() {
    if (this.getUserLevel() === 'coOwner') {
      return 0;
    }
    if (this.getUserLevel() === 'observer') {
      return 1;
    }
    if (this.getUserLevel() === 'member') {
      return 2;
    }
    return 0;
  }

  /**
   * Retrieves users based on applied filters.
   * Updates pagination options and user list.
   * @returns void
   */
  getUsers() {
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.usersService.getUsers(this.params).subscribe((response) => {
      if (response?.data) {
        this.paginationOptions = response.pagination;
        this.setPaginationOptions(response.pagination);
        this.users = response?.data
          ?.filter(
            (user: User) =>
              user?.userGroups?.findIndex(
                (group) => group.group === this.gId && group.relationToGroup?.toLowerCase() === 'owner',
              ) === -1,
          )
          ?.map((user: User) => {
            this.checkedUserByGroups(user);
            return this.createTableViewClients(user);
          });
      }
    });
  }

  /**
   * Retrieves content group names from an array of IdNameLabel objects.
   * @param contentGroups Array of IdNameLabel objects representing content groups.
   * @returns Comma-separated string of content group names or empty string if no groups.
   */
  getContentGroup(contentGroups: IdNameLabel[]) {
    if (contentGroups) {
      return contentGroups
        ?.map((group: IdNameLabel) => group.name)
        ?.join(', ')
        ?.trim();
    }
    return '';
  }

  /**
   * Retrieves user group names from an array of UserGroup objects.
   * @param userGroups Array of UserGroup objects representing user groups.
   * @returns Comma-separated string of user group names or empty string if no groups.
   */
  getUserGroups(userGroups: UserGroup[] | undefined) {
    if (userGroups) {
      return userGroups
        ?.map((group: UserGroup) => group.groupName)
        ?.join(', ')
        ?.trim();
    }
    return '';
  }

  /**
   * Retrieves role names from an array of IdNameLabel objects.
   * @param roles Array of IdNameLabel objects representing roles.
   * @returns Comma-separated string of role names or empty string if no roles.
   */
  getRoles(roles: IdNameLabel[] | undefined) {
    if (roles) {
      return roles
        ?.map((role: IdNameLabel) => role.name)
        ?.join(', ')
        ?.trim();
    }
    return '';
  }

  /**
   * Retrieves clinical trial names from an array of IdNameLabel objects.
   * @param clinicalTrials Array of IdNameLabel objects representing clinical trials.
   * @returns Comma-separated string of clinical trial names or empty string if no trials.
   */
  getClinicalTrials(clinicalTrials: IdNameLabel[]) {
    if (clinicalTrials) {
      return clinicalTrials
        ?.map((trial: IdNameLabel) => trial.name)
        ?.join(', ')
        ?.trim();
    }
    return '';
  }

  /**
   * Retrieves default pagination options.
   * @returns Default pagination options object.
   */
  getDefaultPaginationOptions() {
    const paginationO = {
      limit: 20,
      currentPage: 1,
      totalCount: 0,
    };
    return paginationO;
  }

  /**
   * Handles change in filter options.
   * Resets pagination options, sets selected filter, applies filter, and retrieves users.
   * @param filterOption Selected filter option.
   * @returns void
   */
  onChangeFilterOptions(filterOption: StatusFilterOptions) {
    if (filterOption) {
      this.resetPaginationOptions();
      this.selectedFilterByOption = filterOption;
      this.filterService.setAppliedFilters('status', { id: filterOption?.key, name: filterOption?.label });
      //this.params.filter = this.filterService.getAppliedFiltersParamString();
      this.getUsers();
    }
  }
  /**
   * Retrieves options based on keyword search and updates filterService.
   * @param $event Event data containing column and search keyword
   * @param dialog Optional dialog component for displaying search results
   * @param keyword Search keyword
   * @returns void
   */
  getOptionsByKeyword($event: FilterColumnOption, dialog: MatDialogRef<FilterComponent>, keyword: string) {
    this.params.search = `${$event.column.searchKey}|${keyword}`;
    this.getOptionsByColumn($event, dialog);
  }
  /**
   * Retrieves options based on column filter and updates filterService.
   * @param $event Event data containing column details
   * @param dialog Optional dialog component for displaying filter options
   * @returns void
   */
  getOptionsByColumn($event: FilterColumnOption, dialog?: MatDialogRef<FilterComponent>) {
    const columnName = $event?.type?.toLowerCase() === 'search' ? $event.column.searchKey : $event.column.filterKey;
    if (columnName) {
      this.filterService.getOptionsByColumn('user', columnName, this.params, '', dialog, true);
    }
  }
  /**
   * Opens search dialog for applying search filters.
   * @param $event Event data containing filter details
   * @returns MatDialogRef for the opened search dialog
   */
  openSearchDialog($event: FilterColumnOption) {
    // this is to open search dialog
    $event.search = this.filterService.getAppliedFiltersByKey($event?.column?.filterKey)?.name || '';
    return this.filterService.openSearchFilterDialog($event);
  }
  /**
   * Opens filters dialog for applying column filters.
   * @param $event Event data containing filter details
   * @returns MatDialogRef for the opened filters dialog
   */
  openFiltersDialog($event: FilterColumnOption) {
    // this is to open filter dialog
    this.params.search = '';
    this.getOptionsByColumn($event);
    return this.filterService.openSearchFilterDialog($event);
  }
  /**
   * Handles callback after closing search or filters dialog.
   * Updates applied filters and refreshes user list.
   * @param $event Event data containing filter details
   * @param response Response data from closed dialog
   * @returns void
   */
  dialogAfterClosedCallBack($event: FilterColumnOption, response: FilterOptions) {
    //this method gets called when dialog is closed
    this.filterService.setAppliedFilters($event.column.filterKey, response);
    this.filterService.toggleColumnActiveClass(this.columns, $event.column.key);
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.params.search = '';
    this.setPaginationOptions({ page: 1, limit: 20, currentPage: 1 });
    this.getUsers();
  }
  /**
   * Opens search or filters dialog based on event type.
   * Handles search and filter processes including keyword search.
   * @param $event FilterColumnOption object containing dialog type and details
   * @returns void
   */
  openSearchAndFiltersDialog($event: FilterColumnOption) {
    // main method in  the filter and search process
    let dialog!: MatDialogRef<FilterComponent>;
    if ($event && $event.type) {
      if ($event.type.toLowerCase() === 'search') {
        dialog = this.openSearchDialog($event);
      }
      if ($event.type.toLowerCase() === 'filter') {
        dialog = this.openFiltersDialog($event);
      }
      if (dialog) {
        dialog.componentInstance.searchKeyUp.subscribe((keyword: string) => {
          this.getOptionsByKeyword($event, dialog, keyword);
        });
        dialog.componentInstance.clear.subscribe({
          next: (flag: boolean) => {
            if (flag) {
              this.clear($event);
            }
          },
        });
        dialog.afterClosed().subscribe({
          next: (response: FilterOptions) => {
            if (response) {
              this.dialogAfterClosedCallBack($event, response);
            }
          },
        });
      }
    }
  }

  /**
   * Checks user by groups and updates selection state.
   * @param user - The user object to check against groups.
   */
  checkedUserByGroups(user: User) {
    if (user?.userGroups?.length) {
      user.userGroups?.forEach((userGroup: UserGroup) => {
        if (userGroup && userGroup?.group === this.gId) {
          if (
            (this.userType?.toLowerCase() === 'manager' && userGroup.relationToGroup === 'coOwner') ||
            (this.userType?.toLowerCase() === 'managed' && userGroup.relationToGroup === 'member') ||
            (this.userType?.toLowerCase() === 'observer' && userGroup.relationToGroup === 'observer')
          ) {
            if (user && user?.id && this.selectedUserIds.indexOf(user.id) === -1) {
              this.selectedUserIds.push(user.id);
            }
            user.checked = true;
          } else {
            user.checked = false;
          }
        }
      });
    }
  }

  /**
   * Creates table view data for a user.
   * @param user - The user object to generate table data for.
   * @returns The table data object.
   */
  createTableViewClients(user: User) {
    const tableData = {
      id: Utils.createGenericTableKeysData(user.user, '/userManagment/profile', {
        clientId: this.clientId,
        uid: user.id,
      }),
      name: Utils.createGenericTableKeysData(user?.name || '', '', {}),
      userType: Utils.createGenericTableKeysData(user?.userType, '', {}),
      affiliationCode: Utils.createGenericTableKeysData(user?.affiliationCode, '', {}),
      role: Utils.createGenericTableKeysData(this.getRoles(user?.roles), '', {}),
      contentGroups: Utils.createGenericTableKeysData(this.getContentGroup(user?.contentGroups), '', {}),
      userGroups: Utils.createGenericTableKeysData(this.getUserGroups(user?.userGroups), '', {}),
      status: Utils.createGenericTableKeysData(user?.status, '', {}),
      clinicalTrials: Utils.createGenericTableKeysData(this.getClinicalTrials(user?.clinicalTrials), '', {}),
      checked: user.checked || false,
      isCheckBoxDisabled: user.checked || false,
    };
    return tableData;
  }

  /**
   * Sets selected user IDs.
   * @param userIds - An array of user IDs to set as selected.
   */
  setSelectedUserIds(userIds: string[]) {
    this.selectedUserIds = userIds;
  }

  /**
   * Sorts users based on a specified column and order.
   * @param event - The event containing column and order information.
   */
  sortByColumn(event: { column: string; order: string }) {
    this.params.sort = `${event.column}|${event.order}`;
    this.getUsers();
  }

  /**
   * Sets pagination options for fetching users.
   * @param paginationO - The pagination options to set.
   */
  setPaginationOptions(paginationO: PaginationOptions) {
    this.paginationOptions = paginationO;
    this.params.limit = this.paginationOptions.limit;
    this.params.page = this.paginationOptions.currentPage;
  }

  /**
   * Resets pagination options to default values.
   * @returns The default pagination options.
   */
  resetPaginationOptions() {
    return this.getDefaultPaginationOptions();
  }

  /**
   * Refreshes the list of users based on new pagination options.
   * @param event - The pagination options containing limit and offset information.
   */
  refreshUsers(event: PaginationOptions) {
    this.params.limit = event.limit;
    this.params.page = event.offset !== undefined ? event.offset + 1 : 1;
    this.setPaginationOptions(event);
    this.getUsers();
  }

  /**
   * Clears applied filters and searches for users.
   * @param $event - The event containing column information for clearing filters.
   */
  clear($event: any) {
    this.params.search = '';
    this.filterService.removeFiltersByKey($event.column.filterKey);
    this.filterService.toggleColumnActiveClass(this.columns, $event.column.key);
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.getUsers();
  }

  /**
   * Submits selected users to add them into a group.
   * It calls `addUsersIntoGroup` if users are selected.
   */
  submit() {
    if (this.selectedUserIds?.length) {
      this.addUsersIntoGroup();
    }
  }

  /**
   * Adds selected users into a group and navigates back to the group view.
   * It displays a success message upon successful addition.
   */
  addUsersIntoGroup() {
    if (this.gId && this.getUserLevel()) {
      this.groupsService.addGroupUser({ users: this.selectedUserIds }, this.gId, this.getUserLevel()).subscribe({
        next: (response) => {
          if (response) {
            this.toastrService.success('User added in group Successfully');
            this.backToViewGroup();
          }
        },
      });
    }
  }

  /**
   * Navigates back to the group view page with appropriate query parameters.
   */
  backToViewGroup() {
    this.router.navigate(['/groupManagment/view'], {
      queryParams: { clientId: this.clientId, gid: this.gId, selectedIndex: this.getTabIndex() },
    });
  }
}
