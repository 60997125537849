import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HealthSummaryTabs, PatientTabsItem, ViewMode } from '../entities/tabs.entities';

/**
 * Service for managing Health Summary Tabs
 */
@Injectable({
  providedIn: 'root',
})
export class TabsService {
  /**
   * Tracks changes to Health Summary tabs
   */
  public patientTabs$!: BehaviorSubject<HealthSummaryTabs>;
  /**
   * List of Health Summary Tabs
   */
  private patientTabs: PatientTabsItem[] = [];

  /**
   * Initialize or load tabs
   */
  constructor() {
    this.loadTabs();
  }

  /**
   * Add and open new Patient Tab
   * @param patientId - Patient ID corresponding to tab being opened
   * @param name - Patient Name corresponding to tab being opened
   */
  public addNewTab(patientTabItem: PatientTabsItem): void {
    const tabAlreadyExists: PatientTabsItem | undefined = this.patientTabs.find(
      (tab: PatientTabsItem): boolean => tab.patientId === patientTabItem.patientId,
    );
    if (tabAlreadyExists === undefined) {
      this.patientTabs.push(patientTabItem);
    }
    this.patientTabs$.next({
      activeTabId: patientTabItem.patientId,
      tabItems: this.patientTabs,
      activeTabMode: ViewMode.Maximized,
    });
  }

  /**
   * Close a patient tab
   * @param patientId - Patient ID corresponding to the closing tab
   */
  public removeTab(patientId: string): void {
    this.patientTabs = this.patientTabs.filter((tab) => patientId !== tab.patientId);
    this.patientTabs$.next({
      activeTabId: this.patientTabs.length ? this.patientTabs[0].patientId : null,
      activeTabMode: null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Remove all tabs
   */
  public removeAllTabs(): void {
    this.patientTabs = [];
    this.patientTabs$.next({
      activeTabId: null,
      activeTabMode: null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Activate or Deactivate patient
   * @param patientId - Patient ID
   * @param state - Active or Inactive
   */
  public changePatientActivity(patientId: string, state: 'Active' | 'Inactive'): void {
    for (let i = 0; i < this.patientTabs.length; i++) {
      if (this.patientTabs[i].patientId === patientId) {
        this.patientTabs[i].patientActive = state === 'Active' ? true : false;
        break;
      }
    }
    this.patientTabs$.next({
      activeTabId: patientId,
      activeTabMode: null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Save tabs to localStorage, so that it can be maintained through multiple sessions or browser tabs
   * @param tabs - Health Summary tabs data
   */
  public saveTabs(tabs: HealthSummaryTabs): void {
    try {
      localStorage.setItem('healthSummaryTabs', JSON.stringify(tabs));
    } catch {}
  }

  /**
   * Load tabs from localStorage, so that it can be continued from previous sessions
   */
  private loadTabs(): void {
    let tabs: HealthSummaryTabs = {
      activeTabId: null,
      activeTabMode: null,
      tabItems: this.patientTabs,
    };
    const storedTabsString: string | null = localStorage.getItem('healthSummaryTabs');
    if (storedTabsString) {
      tabs = JSON.parse(storedTabsString);
      this.patientTabs = tabs.tabItems;
    }
    if (tabs.activeTabMode === ViewMode.Minimized) tabs.activeTabId = null;
    this.patientTabs$ = new BehaviorSubject(tabs);
  }
}
