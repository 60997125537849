import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarModule } from './modules/calendar/calendar.module';
import { GroupsModule } from './modules/groups-management/groups.module';
import { MessageModule } from './modules/message/message.module';
import { NotesModule } from './modules/notes/notes.module';
import { FooterComponent } from './modules/shared/component/footer/footer.component';
import { FullComponentComponent } from './modules/shared/component/full-component/full-component.component';
import { DashboardSelectComponent } from './modules/shared/component/header/header-dialogs/dashboard/dashboard-select.component';
import { HeaderComponent } from './modules/shared/component/header/header.component';
import { LoaderComponent } from './modules/shared/component/loader/loader.component';
import { NotFoundComponent } from './modules/shared/component/not-found/not-found.component';
import { DirectivesModule } from './modules/shared/directives/directive.module';
import { RequestInterceptor } from './modules/shared/interceptors/request.interceptor';
import { ResponseInterceptor } from './modules/shared/interceptors/response.interceptor';
import { ClientLabelPipe } from './modules/shared/pipes/client-label.pipe';
import { TranslateService } from './modules/shared/services/translate.service';
import { SharedModule } from './modules/shared/shared.module';
import { UsersModule } from './modules/user-management/users.module';

/**
 * Function to set up translation service with default language.
 * @param service TranslateService instance.
 * @returns A function to initialize translation service.
 */
export function setupTranslateFactory(service: TranslateService): unknown {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    FullComponentComponent,
    FooterComponent,
    HeaderComponent,
    DashboardSelectComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    AppRoutingModule,
    DirectivesModule,
    NotesModule,
    NgIdleModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
    MessageModule,
    CalendarModule,
    UsersModule,
    GroupsModule,
    LoaderComponent,
  ],
  providers: [
    TranslateService,
    ClientLabelPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
