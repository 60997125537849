import { Component, EventEmitter, Output } from '@angular/core';
/**
 * Component for meeting accessibility options.
 */
@Component({
  selector: 'app-meeting-accessibility-options',
  templateUrl: './meeting-accessibility-options.component.html',
  styleUrls: ['./meeting-accessibility-options.component.scss'],
})
export class MeetingAccessibilityOptionsComponent {
  /**
   * Event emitted when the side panel should be closed.
   */
  @Output() public closeSidePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
}
