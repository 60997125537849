import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HIDE_LOADER } from '../interfaces/common.entities';
/**
 * @description
 * Injectable service that provides HTTP request methods and manages loading indicators.
 * Registered at the root injector level.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  /**
   * @description
   * BehaviorSubject to manage the loading state of HTTP requests.
   */
  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @description
   * Constructor injecting the HttpClient service.
   * @param http - Angular HttpClient for HTTP requests.
   */
  constructor(private http: HttpClient) {}
  /**
   * @description
   * Performs an HTTP GET request.
   * @param url - The endpoint URL.
   * @param header - Optional headers for the request.
   * @param hideLoader - Flag to hide the loading indicator.
   * @param responseType - Expected response type ('json' by default).
   * @returns Observable<any> representing the HTTP response.
   */
  public get(url: string, header?: any, hideLoader = false, responseType = 'json'): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
      responseType,
    };

    return this.http.get(url, options);
  }
  /**
   * @description
   * Performs an HTTP POST request.
   * @param url - The endpoint URL.
   * @param body - Request body.
   * @param header - Optional headers for the request.
   * @param hideLoader - Flag to hide the loading indicator.
   * @returns Observable<any> representing the HTTP response.
   */
  public post(url: string, body?: object, header?: any, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.post(url, body, options);
  }
  /**
   * @description
   * Performs an HTTP PUT request.
   * @param url - The endpoint URL.
   * @param body - Request body.
   * @param header - Optional headers for the request.
   * @param hideLoader - Flag to hide the loading indicator.
   * @returns Observable<any> representing the HTTP response.
   */
  public put(url: string, body: object, header?: any, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.put(url, body, options);
  }
  /**
   * @description
   * Performs an HTTP DELETE request.
   * @param url - The endpoint URL.
   * @param header - Optional headers for the request.
   * @param hideLoader - Flag to hide the loading indicator.
   * @returns Observable<any> representing the HTTP response.
   */
  public delete(url: string, header?: any, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.delete(url, options);
  }
  /**
   * @description
   * Performs an HTTP PATCH request.
   * @param url - The endpoint URL.
   * @param body - Request body.
   * @param header - Optional headers for the request.
   * @param hideLoader - Flag to hide the loading indicator.
   * @returns Observable<any> representing the HTTP response.
   */
  public patch(url: string, body?: any, header?: object, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.patch(url, body, options).pipe();
  }
  /**
   * @description
   * Establishes a server-sent events (SSE) connection.
   * @param url - The SSE endpoint URL.
   * @param stopKey - Key indicating when to stop listening.
   * @returns Observable<string> emitting SSE messages.
   */
  public subscribeToSSE(url: string, stopKey: string): Observable<string> {
    return new Observable((subscriber) => {
      const eventSource = new EventSource(url);
      eventSource.onmessage = (event: MessageEvent<string>) => {
        subscriber.next(event.data);
        if (event.data === stopKey) {
          eventSource.close();
          subscriber.complete();
        }
      };

      eventSource.onerror = () => {
        subscriber.error();
        if (eventSource) {
          eventSource.close();
        }
      };
    });
  }
  /**
   * @description
   * Shows the loader if hideLoader is false.
   * @param hideLoader - Flag to hide the loader.
   */
  private showLoader(hideLoader: boolean): void {
    if (!hideLoader) {
      this.loader.next(true);
    }
  }
}
