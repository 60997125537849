import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';

/**
 * Component to display a skeleton loader.
 * This component is used to show a loading animation while content is being loaded.
 */
@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent {
  public lines: InputSignal<number> = input(2);
}
