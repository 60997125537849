<div class="patient-filter">
    <div class="hs-tab-card-title flex-between mb-3">
      <h2>checked</h2>
      <div class="dialog-header-icons">
        <i-feather name="rotate-ccw" class="pointer"></i-feather>
        <i-feather name="x" class="pointer" (click)="closeDialog()" ></i-feather>
      </div>
    </div>
  
    <div class="form-group search-input w-100">
      <input type="text" class="form-control" />
      <!-- <i-feather name="search" class="feather-20 text-disabled" *ngIf="!patient.value"></i-feather> -->
      <i-feather name="x" class="feather-20 text-disabled pointer" 
        ></i-feather>
    </div>
    <!-- <div class="form-check-flex pointer" *ngFor="let patient of patientList" (click)="clickedPatient(patient)">
      <img src="{{ patient.imageUrl }}" alt="Patient Profile Picture" />
      <label class="check-flex-label">{{ patient.name }}</label>
    </div> -->
  </div>
