<div class="title-bar page-info">
  <h1>{{ 'addGroup' | translate }}</h1>
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/groupManagment/list']">{{ 'userGroupManagement' | translate }}</a>
      </li>
      <li class="breadcrumb-item active">{{ 'addGroup' | translate }}</li>
    </ol>
  </nav>
</div>
<div class="row">
  <div class="col-lg-8">
    <div class="card-base">
      <div class="card-titlebar">
        <div class="card-header-icons mb-0">
          <i-feather name="check" (click)="onSubmit()"></i-feather>
          <i-feather
            name="x"
            [routerLink]="['/groupManagment/list']"
            [queryParams]="{ clientId: clientID }"
          ></i-feather>
        </div>
      </div>
      <form [formGroup]="groupForm">
        <div class="form-group">
          <label for="" class="form-label">{{ 'groupName' | translate }}*</label>
          <input
            formControlName="gName"
            type="text"
            minLength="4"
            maxlength="50"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.gName.errors }"
          />
          <div *ngIf="submitted && f.gName.errors" class="invalid-feedback">
            <div *ngIf="f.gName.errors.required" class="danger">{{ 'groupNameRequired' | translate }}</div>
            <div *ngIf="f.gName.errors?.['minlength']" class="danger">
              {{ 'groupNameMinCharacterValidation' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="">{{ 'groupOwner' | translate }}*</label>
          <div class="custom-select-arrow">
            <select
              class="form-control"
              formControlName="manager"
              [ngClass]="{ 'is-invalid': submitted && f.manager.errors }"
            >
              <option value="#" selected disabled>–Select–</option>
              <option *ngFor="let manager of managersList" [value]="manager.id">
                {{ manager.name }}
              </option>
            </select>
          </div>
          <div *ngIf="submitted && f.manager.errors" class="invalid-feedback">
            <div *ngIf="f.manager.errors.required" class="danger">{{ 'groupOwnerRequired' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <label for="area1">{{ 'description' | translate }}</label>
          <textarea
            formControlName="description"
            minlength="4"
            maxlength="500"
            class="form-control lg"
            id="area1"
            rows="5"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors?.['minlength'] }"
          ></textarea>
          <div *ngIf="submitted && f.description.errors?.['minlength']" class="invalid-feedback">
            <div *ngIf="f.description.errors?.['minlength']" class="danger">
              {{ 'descriptionMinCharacterValidation' | translate }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
