<div class="dialog-lg">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ data && data.title ? data.title : ('attachments' | translate) }}</h2>
    <!-- <h2 *ngIf="data.selectedAttachment.type !=='videoLobby'">{{ data && data.title ? data.title : ('attachments' | translate) }}</h2> -->
    <div class="dialog-header-icons">
      <i-feather name="x" mat-dialog-close class="pointer"></i-feather>
    </div>
  </div>

  <mat-dialog-content>
    <a style="float: right" [href]="selectedAttachment.safeUrl" target="_blank">
      <i-feather name="download" class="feather-16"></i-feather> {{ 'download' | translate }}
    </a>
    <div class="attachments-caraousel">
      <div
        class="previous-attachment carousel-control"
        (click)="prev()"
        [ngClass]="{ disabled: index <= 0 }"
        *ngIf="data && data.attachments && data.attachments.length > 1"
      >
        <i-feather name="chevron-left" class="pointer"></i-feather>
      </div>
      <div class="attachments-item">
        <object
          *ngIf="selectedAttachment?.safeUrl && !loadingAttachmentError; else noPreview"
          width="800"
          height="400"
          [type]="getMimeType(selectedAttachment.url)"
          [data]="selectedAttachment.safeUrl"
          (error)="onErrorAttachment()"
          class="attachments-object"
        ></object>
      </div>
      <div
        class="next-attachment carousel-control"
        (click)="next()"
        *ngIf="data && data.attachments && data.attachments.length > 1"
        [ngClass]="{ disabled: index >= data.attachments.length - 1 }"
      >
        <i-feather name="chevron-right" class="pointer"></i-feather>
      </div>
    </div>
  </mat-dialog-content>
  <div class="attachment-icons" *ngIf="data && data.attachments && data.attachments.length > 1">
    <div
      class="attachment-icons-item"
      [ngClass]="{ active: selectedAttachment && selectedAttachment.url === attachment?.url }"
      *ngFor="let attachment of data.attachments; index as i"
      (click)="onClickThumbnails(attachment)"
    >
      <img [src]="attachment.icon" alt="img" onErrorImg />
    </div>
  </div>
</div>
<ng-template #noPreview>
  <div class="no-preview-attachments" *ngIf="selectedAttachment?.icon && selectedAttachment.safeUrl">
    <div class="no-preview-icon">
      <img [src]="selectedAttachment.icon" onErrorImg />
    </div>
    <div class="no-preview-text">
      <p>{{ 'couldntPreviewFile' | translate }}</p>
      <p>
        {{ 'click' | translate }}
        <a [href]="selectedAttachment.safeUrl" target="_blank">
          <i-feather name="download" class="feather-16"></i-feather> {{ 'download' | translate }}
        </a>
        {{ 'andViewOnYourDevice' | translate }}
      </p>
    </div>
  </div>
</ng-template>
