<div class="dialog-lg">
  <div class="hs-tab-card-title flex-between">
    <h2>
      {{ data && data.eventDetails && data.eventDetails.title ? data.eventDetails.title : ('addEvent' | translate) }}
    </h2>

    <div class="dialog-header-icons">
      <!-- <button (click)="recurrenceCustomOption()" class="mr-3">Custom option</button> -->
      <i-feather name="check" class="pointer" (click)="submit()"></i-feather>
      <span *ngIf="data && data.eventDetails && data.eventDetails.id">
        <span *appPermissions="'self.Calendar.Delete'">
          <i-feather
            name="trash-2"
            class="pointer"
            *ngIf="data && data.eventDetails && data.eventDetails.id"
            (click)="delete()"
          ></i-feather>
        </span>
      </span>
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content">
    <form class="add-event-form" [formGroup]="addEditEventForm">
      <div class="add-event-col">
        <div class="form-check-flex video-visit align-items-center" *appPermissions="'self.VideoLobby.Write'">
          <input
            type="checkbox"
            class="form-check-input"
            id="video-call"
            formControlName="isVirtualVisit"
            (change)="onChangeVideoVisit()"
          />
          <label class="check-flex-label clr-primary mb-0" for="video-call">{{ 'videoVisit' | translate }}</label>
          <!-- <i-feather name="video" class="event-video-icon feather-20"></i-feather> -->
        </div>
        <!-- <div class="video-visit-link">
          <a href="https://medocity.siteportal.com/pvk-dvoy-fkd">https://medocity.siteportal.com/pvk-dvoy-fkd</a>
        </div> -->
        <div class="form-group">
          <label required>{{ 'Title' | translate }}</label>
          <input type="text" placeholder="{{ 'title' | translate }}" class="form-control" formControlName="title" />
          <div *ngIf="addEditEventForm.invalid && submitted && f.title.errors" class="invalid-feedback">
            <div *ngIf="f.title.errors.required">{{ 'thisIsRequiredField' | translate }}</div>
          </div>
        </div>
        <div
          class="form-group form-check-flex all-day-check"
          *ngIf="!addEditEventForm?.controls?.isVirtualVisit?.value"
        >
          <input
            type="checkbox"
            class="form-check-input"
            id="all-day"
            formControlName="allDay"
            (change)="onChangeAllDay()"
          />
          <label class="check-flex-label clr-primary mb-0" for="all-day">{{ 'allDay' | translate }}</label>
        </div>
        <div class="form-group date-block mb-1">
          <div class="date-col">
            <div class="date-picker">
              <input
                class="form-control"
                matInput
                placeholder="{{ 'startDate' | translate }}"
                [matDatepicker]="eventStartPicker"
                formControlName="eventStart"
                [min]="now"
                (dateChange)="onChangeEventStart($event)"
                readonly
              />
              <mat-datepicker-toggle matIconSuffix [for]="eventStartPicker"></mat-datepicker-toggle>
              <mat-datepicker #eventStartPicker></mat-datepicker>
            </div>
            <div class="form-control time-picker-input">
              <div class="time-value">
                {{ f.eventStart.value | date: 'hh:mm a' }}
              </div>
              <app-timepicker
                [timeControlValue]="f.eventStart.value"
                [timeControl]="f.eventStart"
                [minuteStep]="minuteStep"
                (changeTime)="onChangeTime($event, 'start')"
              ></app-timepicker>
            </div>
            <span>{{ 'to' | translate }}</span>
          </div>
          <div class="date-col">
            <div class="date-picker">
              <input
                class="form-control"
                matInput
                [matDatepicker]="eventEndPicker"
                formControlName="eventEnd"
                placeholder="{{ 'endDate' | translate }}"
                [min]="f.eventStart.value"
                (dateChange)="onChangeEventEnd($event)"
                readonly
              />
              <mat-datepicker-toggle matIconSuffix [for]="eventEndPicker"></mat-datepicker-toggle>
              <mat-datepicker #eventEndPicker></mat-datepicker>
            </div>
            <div class="form-control time-picker-input">
              <div class="time-value">
                {{ f.eventEnd.value | date: 'hh:mm a' }}
              </div>
              <app-timepicker
                [timeControlValue]="f.eventEnd.value"
                [timeControl]="f.eventEnd"
                [minuteStep]="minuteStep"
                (changeTime)="onChangeTime($event, 'end')"
              ></app-timepicker>
            </div>
          </div>
        </div>
        <div *ngIf="addEditEventForm.invalid && submitted && f.eventStart.errors" class="invalid-feedback mb-2">
          <div *ngIf="f.eventStart.errors.startGreaterThanEnd">{{ 'startNotGreaterThanEnd' | translate }}</div>
          <div *ngIf="f.eventStart.errors.startGreaterThanCurrentTime">
            {{ 'startGreaterThanCurrentTime' | translate }}
          </div>
        </div>
        <div class="form-group" *ngIf="!addEditEventForm?.controls?.isVirtualVisit?.value">
          <label for="recurrence">{{ 'recurrence' | translate }}</label>
          <div class="custom-select-arrow">
            <mat-select
              name="recurrenceOption"
              class="form-control"
              formControlName="rOption"
              (selectionChange)="onChangeRecurrenceOptions()"
            >
              <mat-option class="option-style" *ngFor="let item of recurrenceOptions" [value]="item.value">
                {{ item.key | translate }}
              </mat-option>
            </mat-select>
          </div>
          <!-- <span class="ff-medium bottom-label">Every 1 week(s) on Sunday, Tuesday and Thursday</span> -->
        </div>
        <div class="form-group ff-bold rrule-text" *ngIf="addEditEventForm?.controls?.custom?.value">
          {{ rRuleString }}
        </div>
        <div
          class="form-group"
          *ngIf="
            addEditEventForm?.value?.rOption !== 'doesNotRepeat' && !addEditEventForm?.controls?.isVirtualVisit?.value
          "
        >
          <label for="end">End</label>
          <div class="date-block">
            <div class="custom-select-arrow sm-50">
              <mat-select
                name="recurrenceOption"
                class="form-control"
                formControlName="untilOption"
                (selectionChange)="onChangeUntilOptions()"
              >
                <mat-option class="option-style" *ngFor="let option of untilOptions" [value]="option.value">
                  {{ option.key | translate }}
                </mat-option>
              </mat-select>
            </div>
            <div>
              <div class="d-flex align-items-center">
                <input
                  type="text"
                  class="form-control num-field me-1"
                  [min]="1"
                  [value]="1"
                  (input)="onChangeNumberOfTimes($event)"
                  *ngIf="addEditEventForm?.value?.untilOption === 'afterNumberOfTimes'"
                />
                <span *ngIf="addEditEventForm?.value?.untilOption === 'afterNumberOfTimes'">{{
                  'times' | translate
                }}</span>
              </div>
              <div
                *ngIf="addEditEventForm.invalid && submitted && rRuleSettingFormGroup.get('count')?.errors"
                class="invalid-feedback"
              >
                <ng-container *ngIf="rRuleSettingFormGroup.get('count')?.errors?.required">
                  {{ 'thisIsRequiredField' | translate }}
                </ng-container>
              </div>
            </div>
            <div>
              <div class="date-picker" *ngIf="addEditEventForm?.value?.untilOption === 'onDate'">
                <input
                  matInput
                  class="form-control"
                  [matDatepicker]="untilOnDatePicker"
                  (dateChange)="onChangeUntilDate($event)"
                  [min]="f.eventStart.value"
                  formControlName="untilDate"
                  placeholder="{{ 'until' | translate }}"
                  readonly
                />
                <mat-datepicker-toggle matIconSuffix [for]="untilOnDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #untilOnDatePicker></mat-datepicker>
              </div>
              <div
                *ngIf="addEditEventForm.invalid && submitted && rRuleSettingFormGroup.get('until')?.errors"
                class="invalid-feedback"
              >
                <div *ngIf="rRuleSettingFormGroup.get('until')?.errors?.required">
                  {{ 'thisIsRequiredField' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="!addEditEventForm?.controls?.isVirtualVisit?.value">
          <label for="Location">{{ 'location' | translate }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'location' | translate }}"
            formControlName="location"
          />
        </div>
        <div class="form-group">
          <span>
            <label for="area1" class="form-label">{{ 'notes' | translate }}</label> ({{
              'thisWillBeVisibleToAllAttendees' | translate
            }})</span
          >
          <textarea
            class="form-control event-notes"
            id="area1"
            placeholder="{{ 'notes' | translate }}"
            rows="5"
            formControlName="notes"
          ></textarea>
        </div>
      </div>
      <!-- <div class="add-event-col-sep"></div> -->
      <div class="add-event-col">
        <div class="form-group" *ngIf="!toggleOrganizerView">
          <label class="clr-disabled">{{ 'organizer' | translate }}</label>
          <div class="block-chips bg-lightGreen">
            <span class="item"
              >{{ addEditEventForm.controls.organizer.value?.fullName }}
              <i-feather
                name="plus"
                class="feather-20 remove-chips-item"
                (click)="toggleOrganizerView = !toggleOrganizerView"
              ></i-feather
            ></span>
          </div>
        </div>
        <div class="form-group">
          <label>{{ 'attendees' | translate }}*</label>
          <input
            type="text"
            matInput
            required
            [matAutocomplete]="autoGroup"
            formControlName="keywords"
            placeholder="{{ 'typeHereToSearch' | translate }}"
            class="form-control"
          />
          <div
            *ngIf="
              addEditEventForm.invalid &&
              submitted &&
              f.keywords.errors &&
              addEditEventForm.controls.attendees?.value?.length <= 0
            "
            class="invalid-feedback"
          >
            <div *ngIf="f.keywords.errors.required">{{ 'attendeesIsRequired' | translate }}</div>
          </div>
          <mat-autocomplete #autoGroup="matAutocomplete" class="calendar-autoComplete">
            <mat-optgroup *ngFor="let group of data.attendees" label="{{ group.label | clientLabel }}">
              <mat-option
                class="option-style"
                *ngFor="let user of group.users | filter: addEditEventForm?.controls?.keywords?.value : 'userFullName'"
              >
                <div (click)="onClickAttendant(user)">
                  <img [src]="user.userImageUrl" />
                  <span>{{ user.userFullName }}</span>
                </div>
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </div>
        <!-- <div class="form-group">
          <label>{{ 'attendees' | translate }}*</label>
          <input type="text" class="form-control" />
          <div *ngIf="submitted && f.attendees.errors" class="invalid-feedback">
            <div *ngIf="f.attendees.errors.required">{{ 'attendeesIsRequired' | translate }}</div>
          </div>
        </div> -->
        <div class="form-group" *ngIf="toggleOrganizerView">
          <label class="clr-disabled">{{ 'organizer' | translate }}</label>
          <div class="block-chips bg-lightGreen">
            <span class="item"
              >{{ addEditEventForm.controls.organizer.value?.fullName }}
              <i-feather
                name="x"
                class="feather-20 remove-chips-item"
                (click)="toggleOrganizerView = !toggleOrganizerView"
              ></i-feather
            ></span>
          </div>
        </div>

        <div class="form-group" *ngIf="selectedAttendees?.member?.length">
          <label class="clr-disabled">{{ 'managed' | clientLabel }}</label>
          <div class="block-chips bg-lightOrange">
            <div *ngFor="let member of selectedAttendees.member">
              <div class="item">
                <span>{{ member.fullName }}</span>
                <i-feather
                  name="x"
                  class="feather-20 remove-chips-item"
                  (click)="removeAttendant(member.user, 'member')"
                ></i-feather>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="selectedAttendees?.coOwner?.length">
          <label class="clr-disabled">{{ 'manager' | clientLabel }}</label>
          <div class="block-chips bg-lightBlue">
            <div *ngFor="let coOwner of selectedAttendees.coOwner">
              <div class="item">
                <span>{{ coOwner.fullName }}</span>
                <i-feather
                  name="x"
                  class="feather-20 remove-chips-item"
                  (click)="removeAttendant(coOwner.user, 'coOwner')"
                ></i-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
