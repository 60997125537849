import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';
import { FilterColumnOption, FilterOptions } from 'src/app/modules/shared/interfaces/common.entities';
import { ClientLabelPipe } from 'src/app/modules/shared/pipes/client-label.pipe';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
/**
 * Component that provides filtering and search functionalities.
 */
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Input data for the search list */
  @Input() searchList: Array<FilterOptions> = [];
  /** Event emitted on search key up */
  @Output() searchKeyUp = new EventEmitter();
  /** Event emitted to clear the filter */
  @Output() clear: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  /** Reference to the search input element */
  @ViewChild('searchEvent') searchEvent!: ElementRef;
  /** Form group for checkbox options */
  checkBoxForm!: FormGroup;
  /** Array of filter options */
  filterOptions: Array<FilterOptions> = [];
  /** Search key input */
  searchKey = '';
  /** Title for the component */
  title = '';
  /** Flag indicating if the search component is shown */
  showSearch = false;
  /** Flag indicating if the filter component is shown */
  showFilter = false;
  /** Flag indicating if all options are selected */
  allSelected = false;
  /** Flag indicating if the selection is indeterminate */
  indeterminate = false;

  /**
   * Constructor of the component.
   * @param data Injected data for the component
   * @param matDialogRef Reference to the MatDialog component
   * @param fb FormBuilder service for form initialization
   * @param clientLabelPipe Custom pipe for client label transformation
   * @param filterService Service providing filter functionalities
   * @param cdk ChangeDetectorRef for detecting changes
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FilterColumnOption,
    private matDialogRef: MatDialogRef<FilterComponent>,
    private fb: FormBuilder,
    private clientLabelPipe: ClientLabelPipe,
    private filterService: FilterService,
    private cdk: ChangeDetectorRef,
  ) {}

  /**
   * Initializes component based on input data type ('search' or 'filter').
   */
  ngOnInit(): void {
    if (this.data.type === 'search') {
      this.showSearch = true;
      this.title = this.data?.title || '';
      if (this.data.search) {
        this.searchKey = this.data.search;
      }
    }
    if (this.data.type === 'filter') {
      this.checkBoxForm = this.fb.group({
        filterOptions: new FormArray([]),
      });
      this.showFilter = true;
      this.filterService.filterOptions.subscribe((response: Array<FilterOptions>) => {
        this.filterOptions = response.map((o: FilterOptions) => {
          let filterO;
          if (typeof o === 'string') {
            if (this.data?.checkLabel == undefined || this.data.checkLabel == true) {
              filterO = { id: o, name: this.getClientLabelByKey(o), checked: false };
            } else {
              filterO = { id: o, name: o, checked: false };
            }
          } else {
            filterO = { id: o.id, name: o.name, checked: false };
          }
          return filterO;
        });
        if (this.filterOptions?.length) {
          this.createFilterControl(this.filterOptions);
        }
      });
    }
  }
  /**
   * Lifecycle hook called after component's view has been initialized.
   * Initializes search key up handling.
   */
  ngAfterViewInit(): void {
    this.searchKeyUpMethod();
  }
  /**
   * Getter for accessing the FormArray of filter options.
   * @returns FormArray of filter options
   */
  get options(): FormArray {
    return this.checkBoxForm.get('filterOptions') as FormArray;
  }
  /**
   * Method called to trigger search functionality.
   * Closes dialog with current search key.
   */
  search() {
    this.matDialogRef.close(this.searchKey);
  }
  /**
   * Method called to emit clear event.
   * Emits clear event with provided data.
   */
  remove() {
    this.clear.emit(true);
  }
  /**
   * Method called to close the dialog.
   * Closes the MatDialogRef.
   */

  close() {
    this.matDialogRef.close();
  }
  /**
   * Method to handle search key up events.
   * Emits search key if length is greater than or equal to 3, otherwise clears search list.
   */
  searchKeyUpMethod() {
    fromEvent(this.searchEvent.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        map((event: KeyboardEvent | any) => (<HTMLInputElement>event.target).value),
        filter((keywords: string) => keywords.length >= 3 || keywords.length === 0),
        distinctUntilChanged(),
      )
      .subscribe((keyword) => {
        if (this.data.type === 'search') {
          if (keyword) {
            this.searchKeyUp.emit(keyword);
          } else {
            this.searchList = [];
          }
        }
      });
  }
  /**
   * Method called when an option is selected.
   * Closes the dialog with the selected item.
   * @param item Selected option
   */
  selectedOption(item: string) {
    this.matDialogRef.close(item);
  }
  /**
   * Method to create a new form group for a filter option.
   * @param option Filter option to create form group for
   * @returns Form group for the filter option
   */
  getFormGroupByFilterOption(option: FilterOptions): FormGroup {
    return this.fb.group({
      checked: new FormControl(option.checked),
      id: new FormControl(option.id),
      name: new FormControl(option.name),
    });
  }
  /**
   * Method to create form controls for filter options.
   * Initializes filter options with applied filters if available.
   * @param options Array of filter options
   */
  createFilterControl(options: Array<FilterOptions>) {
    if (options?.length) {
      (<FormArray>this.checkBoxForm.get('filterOptions')).controls = [];
      const appliedFilters = this.filterService.getAppliedFiltersByKey(this.data.column.filterKey);

      options.filter((option: FilterOptions) => {
        if (appliedFilters && appliedFilters?.length) {
          if (appliedFilters.findIndex((o: FilterOptions) => o.id === option.id) !== -1) {
            option.checked = true;
          } else {
            option.checked = false;
          }
        }
        (<FormArray>this.checkBoxForm.get('filterOptions')).push(this.getFormGroupByFilterOption(option));
      });
      if (appliedFilters && appliedFilters?.length === options?.length) {
        this.allSelected = true;
        this.indeterminate = false;
      } else {
        this.allSelected = false;
        if (appliedFilters && appliedFilters.filter((o: FilterOptions) => o.checked).length) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
        }
      }
    }
  }
  /**
   * Method called when checkbox value changes.
   * Updates allSelected and indeterminate flags accordingly.
   * @param eve Event object containing checkbox change details
   */
  onChangeCheckBox(eve: Event) {
    if (
      this.checkBoxForm.value.filterOptions.filter((fOption: FilterOptions) => fOption.checked === true).length ===
      this.filterOptions.length
    ) {
      this.allSelected = true;
      if ((eve.target as HTMLInputElement)?.checked) {
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
      this.cdk.detectChanges();
    } else {
      if ((eve.target as HTMLInputElement)?.checked) {
        this.indeterminate = true;
      } else {
        if (
          this.checkBoxForm.value.filterOptions.filter((fOption: FilterOptions) => fOption.checked === true).length ===
          0
        ) {
          this.indeterminate = false;
        } else {
          this.indeterminate = true;
        }
      }
      this.allSelected = false;
      this.cdk.detectChanges();
    }
  }
  /**
   * Method to select all filter options.
   * @param eve Event object containing checkbox change details
   */
  selectAllOptions(eve: MatCheckboxChange) {
    const control = <FormArray>this.checkBoxForm.controls['filterOptions'];
    control.controls.map((value) => value.get('checked')?.patchValue(eve.checked));
    this.allSelected = true;
  }
  /**
   * Method to get client label by key using clientLabelPipe.
   * @param label Key for fetching client label
   * @returns Client label corresponding to the key
   */
  getClientLabelByKey(label: string): string {
    return this.clientLabelPipe.transform(label);
  }
  /**
   * Method to get API processing flag from filter service.
   * @returns Boolean value indicating if API is processing
   */
  getAPIProcessingFlag() {
    return this.filterService.apiIsProcessing;
  }
  /**
   * Method called to save selected filter options.
   * Closes the dialog with selected filter options.
   */
  save() {
    const selectedOptions = this.checkBoxForm.value.filterOptions.filter((ele: FilterOptions) => ele.checked === true);
    this.matDialogRef.close(selectedOptions);
  }
  /**
   * Clears the `filterOptions` BehaviorSubject in `filterService`.
   * This ensures no memory leaks and prepares for the next use of filter options.
   */
  ngOnDestroy() {
    this.filterService.filterOptions.next([]);
  }
}
