import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { TooltipService } from './tooltip.service';

@Component({
  selector: 'app-custom-tooltip',
  standalone: true,
  imports: [CommonModule, FeatherModule],
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tooltipContent', { static: true }) public tooltipContentRef!: ElementRef;

  public isContentScrollable = false;
  private mouseLeaveListener?: () => void;

  constructor(
    @Inject('tooltipContent') public content: string | TemplateRef<unknown>,
    private tooltipService: TooltipService,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit(): void {
    this.checkIfScrollable();
  }

  public ngOnDestroy(): void {
    this.removeMouseLeaveListener();
  }

  public closeTooltip(): void {
    this.tooltipService.hideTooltip();
  }

  public isTemplate(content: unknown): content is TemplateRef<unknown> {
    return content instanceof TemplateRef;
  }

  private checkIfScrollable(): void {
    setTimeout(() => {
      const tooltipElement = this.tooltipContentRef.nativeElement as HTMLElement;
      const contentText = tooltipElement.textContent || '';
      const contentHeight = tooltipElement.scrollHeight;

      if (contentHeight > 200 || contentText.length > 600) {
        this.isContentScrollable = true;
      } else {
        this.attachMouseLeaveListener();
      }
    }, 0); // Ensure DOM has updated
  }

  private attachMouseLeaveListener(): void {
    const triggerElement = this.tooltipService.getTriggerElement();
    if (triggerElement) {
      // Attach the mouseleave listener using Renderer2
      this.mouseLeaveListener = this.renderer.listen(triggerElement, 'mouseleave', () => {
        this.tooltipService.hideTooltip();
      });
    }
  }

  private removeMouseLeaveListener(): void {
    if (this.mouseLeaveListener) {
      this.mouseLeaveListener(); // Remove the listener
      this.mouseLeaveListener = undefined;
    }
  }
}
