import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import ChronicConditions from 'src/assets/lookup/chronicConditions.json';
import { environment } from 'src/environments/environment';
import Suffix from '../../../assets/lookup/suffix.json';
import { ParamsOptions } from '../shared/interfaces/pagination-options';

/**
 * Service for managing user-related operations, including HTTP requests to backend APIs.
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  /**
   * Represents the URL of the API host.
   */
  apiUrl: string = environment.url.apiHost;

  /**
   * Represents the version of the API.
   */
  apiVersion: string = environment.url.version;

  /**
   * Initializes the UsersService with HttpService and Router.
   * @param _httpService The HTTP service for making API requests.
   * @param router The Angular router service for navigation.
   */
  constructor(
    private _httpService: HttpService,
    private router: Router,
  ) {}

  /**
   * Return an array of chronic conditions.
   */
  chronicConditions() {
    return ChronicConditions;
  }

  /**
   * Return an array of suffixes.
   */
  getSuffix() {
    return Suffix;
  }

  /**
   * Retrieves users based on provided parameters.
   * @param params Options for filtering, pagination, and sorting users.
   * @returns An observable of HTTP response containing users data.
   */
  getUsers(params?: ParamsOptions) {
    let url = `${this.apiUrl}${this.apiVersion}/user/client?`;
    if (params?.filter != '') {
      url = url + `filter=${params?.filter}`;
    }
    if (params?.page != 0) {
      url = url + `&page=${params?.page}`;
    }
    if (params?.limit != 0) {
      url = url + `&limit=${params?.limit}`;
    }
    if (params?.sort != '') {
      url = url + `&sort=${params?.sort}`;
    }
    return this._httpService.get(url, false);
  }

  /**
   * Retrieves options for a specific column used in user listing.
   * @param columnName The name of the column for which options are requested.
   * @param params Options for filtering.
   * @returns An observable of HTTP response containing column options data.
   */
  getUsersOption(columnName: string, parmas: ParamsOptions) {
    const url = `${this.apiUrl}${this.apiVersion}/user/column/${columnName}?`;
    delete parmas.page;
    delete parmas.limit;
    if (parmas.search == '' || parmas.search == undefined) {
      delete parmas.search;
    }
    if (parmas.filter == '' || parmas.filter == undefined) {
      delete parmas.filter;
    }

    return this._httpService.get(url, parmas, false);
  }

  /**
   * Retrieves affiliation codes based on provided parameters.
   * @param params Options for filtering, pagination, and sorting affiliation codes.
   * @returns An observable of HTTP response containing affiliation codes data.
   */
  getAffiliationCodes(params: ParamsOptions) {
    let url = `${this.apiUrl}${this.apiVersion}/affiliation/client?limit=${params.limit}&page=${params.page}`;
    if (!params.filter) {
      delete params.filter;
    } else {
      url = url + `&filter=${params.filter}`;
    }
    if (!params.sort) {
      delete params.sort;
    } else {
      url = url + `&sort=${params.sort}`;
    }
    if (!params.search) {
      delete params.search;
    } else {
      url = url + `&search=${params.search}`;
    }

    return this._httpService.get(url, params);
  }

  /**
   * Retrieves a user by ID.
   * @param id The ID of the user to retrieve.
   * @returns An observable of HTTP response containing user data.
   */
  getUserById(id: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/${id}`;
    return this._httpService.get(url);
  }

  /**
   * Retrieves users belonging to a specified group and user type.
   * @param gid The ID of the group to retrieve users from.
   * @param userType The type of user to filter by.
   * @returns An observable of HTTP response containing users data.
   */
  getGroupUsers(gid: string, userType: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/${gid}/users/${userType}`;
    // let url = `${this.apiUrl}${this.apiVersion}/group/client`;
    return this._httpService.get(url);
  }

  /**
   * Retrieves external IDs based on user type.
   * @param userType The type of user to retrieve external IDs for.
   * @returns An observable of HTTP response containing external IDs data.
   */
  getExternalIds(userType: string) {
    const url = `${this.apiUrl}${this.apiVersion}/client/lookup/external-ids?userType=` + userType;
    return this._httpService.get(url);
  }

  /**
   * Retrieves user data for editing based on parameters.
   * @param params Options for retrieving user data.
   * @returns An observable of HTTP response containing user data.
   */
  editUsers(params: object) {
    const url = `${this.apiUrl}${this.apiVersion}/user/` + params;
    return this._httpService.get(url);
  }

  /**
   * Retrieves managers based on type and status.
   * @param type The type of manager to retrieve (e.g., 'Manager').
   * @param active The status of managers to retrieve (e.g., 'Active').
   * @returns An observable of HTTP response containing managers data.
   */
  getManagers(type = 'Manager', active = 'Active') {
    const url = `${this.apiUrl}${this.apiVersion}/user/client?limit=100&filter=userType|` + type + `,status|${active}`;
    return this._httpService.get(url);
  }

  /**
   * Retrieves groups list based on user type with old implementation.
   * @param params The type of user for which groups are retrieved.
   * @returns An observable of HTTP response containing groups data.
   */
  getGroupsListold(params: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/client?limit=20&page=1&sort=name|DESC&filter=userType|${params}`;
    return this._httpService.get(url);
  }

  /**
   * Retrieves groups list based on user type.
   * @param userType The type of user for which groups are retrieved.
   * @param gid The ID of the group to retrieve users from.
   * @param limit The maximum number of results to return (optional).
   * @param offset The offset for pagination (optional).
   * @param sort The field to sort results by (optional).
   * @returns An observable of HTTP response containing groups data.
   */
  getGroupsList(userType: string, gid: string, limit?: number, offset?: number, sort?: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/${gid}/users/${userType}?limit=${limit}&page=${offset}&sort=${sort}`;
    return this._httpService.get(url);
  }

  /**
   * Retrieves user group data based on user ID.
   * @param uId The ID of the user for which group data is retrieved.
   * @returns An observable of HTTP response containing user group data.
   */
  getUserGroupData(uId: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/user/` + uId;
    return this._httpService.get(url);
  }

  /**
   * Adds a new user.
   * @param body The user data to be added.
   * @returns An observable of HTTP response containing the result of the addition.
   */
  addUsers(body: object) {
    const url = `${this.apiUrl}${this.apiVersion}/user`;
    return this._httpService.post(url, body);
  }

  /**
   * Updates demographics data for a user.
   * @param body The demographics data to be updated.
   * @param uid The ID of the user whose demographics are to be updated.
   * @returns An observable of HTTP response containing the result of the update.
   */
  updateDemographics(body: object, uid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/demographic/user/` + uid;
    return this._httpService.patch(url, body);
  }

  /**
   * Updates user data.
   * @param body The user data to be updated.
   * @param uid The ID of the user to be updated.
   * @returns An observable of HTTP response containing the result of the update.
   */
  updateUser(body: object, uid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/` + uid;
    return this._httpService.patch(url, body);
  }

  /**
   * Updates user role data.
   * @param body The user role data to be updated.
   * @param uid The ID of the user whose role is to be updated.
   * @returns An observable of HTTP response containing the result of the update.
   */
  updateUserRole(body: object, uid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/` + uid;
    return this._httpService.patch(url, body);
  }

  /**
   * Deletes a user role.
   * @param uid The ID of the user from whom the role is to be deleted.
   * @param roleId The ID of the role to be deleted.
   * @returns An observable of HTTP response containing the result of the deletion.
   */
  deleteUserRole(uid: string, roleId: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/` + uid + '/role/' + roleId;
    return this._httpService.delete(url);
  }

  /**
   * Updates or adds a user role.
   * @param body The user role data to be updated or added.
   * @param uid The ID of the user whose role is to be updated or added.
   * @returns An observable of HTTP response containing the result of the update or addition.
   */
  updateAddUserRole(body: object, uid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/` + uid + '/role';
    return this._httpService.post(url, body);
  }

  /**
   * Updates user dashboard settings.
   * @param body The dashboard settings to be updated.
   * @param uid The ID of the user whose dashboard settings are to be updated.
   * @returns An observable of HTTP response containing the result of the update.
   */
  updateDashboard(body: object, uid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/user/settings/user/` + uid;
    return this._httpService.patch(url, body);
  }

  /**
   * Updates user profile settings.
   * @param body The profile settings to be updated.
   * @returns An observable of HTTP response containing the result of the update.
   */
  updateProfile(body: object) {
    const url = `${this.apiUrl}${this.apiVersion}/user/settings`;
    return this._httpService.patch(url, body);
  }

  /**
   * Adds a user to a group.
   * @param body The user data to be added to the group.
   * @param gid The ID of the group to add the user to.
   * @param userLevel The level of the user within the group (optional).
   * @returns An observable of HTTP response containing the result of the addition.
   */
  addUserGroup(body: object, gid: string, userLevel?: string) {
    if (userLevel === 'Member') userLevel = 'member';
    if (userLevel === 'CoOwner') userLevel = 'coOwner';
    if (userLevel === 'Observer') userLevel = 'observer';
    const url = `${this.apiUrl}${this.apiVersion}/group/` + gid + '/' + userLevel;
    return this._httpService.post(url, body);
  }

  /**
   * Deletes a user from a group.
   * @param userLevel The level of the user within the group.
   * @param gid The ID of the group from which the user is to be deleted.
   * @param uid The ID of the user to be deleted from the group.
   * @returns An observable of HTTP response containing the result of the deletion.
   */
  deleteUserGroup(ownerType: string, gid: string, uid?: string) {
    let url = '';
    if (ownerType?.toLocaleLowerCase() === 'member') {
      url = `${this.apiUrl}/${this.apiVersion}/group/` + gid + '/' + uid;
    } else {
      if (ownerType === 'Member') ownerType = 'member';
      if (ownerType === 'CoOwner') ownerType = 'coOwner';
      if (ownerType === 'Observer') ownerType = 'observer';
      url = `${this.apiUrl}/${this.apiVersion}/group/` + gid + '/' + ownerType + '/' + uid;
    }
    return this._httpService.delete(url);
  }

  /**
   * Retrieves groups list based on user type.
   * @param params Options for retrieving groups list.
   * @returns An observable of HTTP response containing groups data.
   */
  // getGroups(params: object) {
  //   const url = `${this.apiUrl}${this.apiVersion}/group/client`;
  //   return this._httpService.get(url, params, false);
  // }

  getGroups(search?: string, filter?: Array<string>) {
    const searchText = search == undefined || search == '' ? '' : `search=name|${search}`;
    const filterText =
      filter && filter?.length > 1
        ? `${searchText == '' ? '' : '&'}filter=groupType|${filter.join(',')}`
        : `&filter=groupType|${filter ? filter[0] : ''}`;
    const url = `${this.apiUrl}/${this.apiVersion}/group/client/column/name?${searchText}${filter && filter.length > 0 ? filterText : ''}`;

    return this._httpService.get(url, false);
  }

  /**
   * Retrieves all roles based on parameters.
   * @param params Options for filtering and pagination.
   * @returns An observable of HTTP response containing roles data.
   */
  getAllRoles(parmas: ParamsOptions) {
    const url = `${this.apiUrl}${this.apiVersion}/role/client?`;
    if (parmas.filter == '' || parmas.filter == undefined) {
      delete parmas.filter;
    }
    delete parmas.search;
    return this._httpService.get(url, parmas);
  }
}
