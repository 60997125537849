import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MeetingInfoComponent } from './components/meeting-info/meeting-info.component';
import { MeetingOptionsComponent } from './components/meeting-options/meeting-options.component';
import { MeetingAccessibilityOptionsComponent } from './components/meeting-accessibility-options/meeting-accessibility-options.component';
import { VideoLobbyCallRecordingsComponent } from './components/video-lobby-call-recordings/video-lobby-call-recordings.component';
import { FeatherModule } from 'angular-feather';
import { VideoLobbyFilterComponent } from './components/video-lobby-filter/video-lobby-filter.component';
@NgModule({
  declarations: [
    MeetingInfoComponent,
    MeetingOptionsComponent,
    MeetingAccessibilityOptionsComponent,
    VideoLobbyCallRecordingsComponent,
    VideoLobbyFilterComponent,
  ],
  imports: [CommonModule, SharedModule, FeatherModule],
  exports: [MeetingInfoComponent, MeetingOptionsComponent, MeetingAccessibilityOptionsComponent],
})
export class VideoLobbyModule {}
