import { AsyncPipe, DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import {
  RemoteParticipant,
  RemoteVideoStream,
  VideoStreamRenderer,
  VideoStreamRendererView,
} from '@azure/communication-calling';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PatientTabsItem } from 'src/app/modules/dashboard/health-summary-tabs/entities/tabs.entities';
import { TabsService } from 'src/app/modules/dashboard/health-summary-tabs/services/tabs.service';
import { PermissionsModule } from 'src/app/modules/permissions/permissions.module';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { CssService } from 'src/app/modules/shared/services/css.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { Attendee, CallParticipant } from '../../entities/video-lobby.entities';
import { VideoLobbyService } from '../../services/video-lobby.service';
import { MeetingAccessibilityOptionsComponent } from '../meeting-accessibility-options/meeting-accessibility-options.component';
import { MeetingDeviceSettingsComponent } from '../meeting-device-settings/meeting-device-settings.component';
import { MeetingInfoComponent } from '../meeting-info/meeting-info.component';
import { MeetingOptionsComponent } from '../meeting-options/meeting-options.component';
import { MeetingParticipantsComponent } from '../meeting-participants/meeting-participants.component';
/**
 * Component for managing video calls, including participant management, video rendering, and call controls.
 *
 * This component integrates with Azure Communication Services (ACS) to facilitate video calls,
 * manage participants, handle video rendering, and provide call controls such as muting/unmuting video.
 */
@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    FeatherModule,
    NgIf,
    NgbDropdownModule,
    NgbTooltipModule,
    TranslatePipe,
    AsyncPipe,
    NgFor,
    MeetingParticipantsComponent,
    MeetingDeviceSettingsComponent,
    PermissionsModule,
    MeetingOptionsComponent,
    MeetingInfoComponent,
    MeetingAccessibilityOptionsComponent,
  ],
})
export class VideoCallComponent implements AfterViewInit, OnDestroy {
  /** Flag indicating whether the more dropdown menu is open. */
  moreDropdown = false;
  /** Flag indicating whether the more settings dropdown menu is open. */
  moreSettings = false;
  /** Flag indicating whether the organizer dropdown menu is open. */
  organizerDropdown = false;
  /** Flag indicating whether the meeting info panel is open. */
  meetingInfo = false;
  /** Flag indicating whether the meeting options panel is open. */
  meetingOptions = false;
  /** Flag indicating whether the accessibility options panel is open. */
  accessibilty = false;
  /** Instance of VideoLobbyService for managing video call functionality. */
  public videoLobbyService = inject(VideoLobbyService);
  /** Instance of CssService for managing CSS-related functionalities. */
  public cssService = inject(CssService);
  /** Signal for tracking the currently focused participant or screen share. */
  public focusedParticipant: WritableSignal<string | 'screenShare'> = signal('');
  /** Signal for controlling the visibility of the video panel in mobile mode. */
  public showVideoPanelInMobileMode: WritableSignal<boolean> = signal(false);
  /** Injected HTTP service for making HTTP requests. */
  #http = inject(HttpService);
  /** Subscription to manage the call in progress. */
  #callInProgressSub$!: Subscription;
  /** Renderer for local video streams. */
  #localStreamRenderer!: VideoStreamRenderer;
  /** Injected service for managing tabs. */
  #tabsService = inject(TabsService);
  /** Injected service for displaying toast notifications. */
  #toast = inject(ToastrService);
  /** Injected DOCUMENT token for accessing the DOM document object. */
  #document = inject(DOCUMENT);
  /** Reference to the local HTML div element for video rendering. */
  #localStream!: HTMLDivElement;
  /** Event handler for changes in screen sharing availability. */
  #screenSharingChangedEvent!: () => Promise<void>;
  /** Renderer for screen sharing video streams. */
  #shareScreenStreamRenderer!: VideoStreamRenderer;
  /**
   * Mutes the local video stream.
   */
  public async muteVideo(): Promise<void> {
    this.#localStreamRenderer.dispose();
    await this.videoLobbyService.muteVideo();
    setTimeout(() => this.videoLobbyService.cameraStatus.set('Off'), 1000);
  }
  /**
   * Unmutes the local video stream.
   */
  public async unmuteVideo(): Promise<void> {
    await this.#startVideo();
    await this.videoLobbyService.unmuteVideo();
  }
  /**
   * Ends the ongoing call.
   *
   * @param forEveryone - Boolean indicating whether to end the call for everyone.
   * @public
   */
  public async endCall(forEveryone: boolean): Promise<void> {
    this.#http.loader.next(true);
    await this.videoLobbyService.stopCallRecording();
    await this.videoLobbyService.endCall(forEveryone);
    this.#localStreamRenderer.dispose();
    this.videoLobbyService
      .callParticipants()
      .forEach((participant: CallParticipant): void => this.#disposeStream(participant));
    this.videoLobbyService.callInProgress$.next(false);
    this.videoLobbyService.cameraStatus.set('Off');
    this.videoLobbyService.microphoneStatus.set('Off');
    this.#http.loader.next(false);
  }
  /**
   * Lifecycle hook called after component view initialization.
   */
  public async ngAfterViewInit(): Promise<void> {
    await this.#startVideo();
    this.videoLobbyService.microphoneStatus.set('On');

    this.#callInProgressSub$ = this.videoLobbyService.callInProgress$.subscribe(async (res: boolean): Promise<void> => {
      if (res) {
        await this.#startCall();
      }
    });
    this.videoLobbyService.dialogLoadingComplete.next(true);
  }
  /**
   * Lifecycle hook called before component destruction.
   */
  public ngOnDestroy(): void {
    this.#callInProgressSub$.unsubscribe();
    try {
      this.#localStreamRenderer.dispose();
    } catch {}
    this.videoLobbyService
      .callParticipants()
      .forEach((participant: CallParticipant): void => this.#disposeStream(participant));
  }
  /**
   * Opens the patient dashboard for the managed patient in the call.
   */
  public openPatientDashboard(): void {
    const patient: Attendee | undefined = this.videoLobbyService
      .activeCall()
      ?.attendees.find((attendee: Attendee): boolean => attendee.userType === 'Managed');
    if (!patient) {
      this.#toast.warning('No patient available.');
      return;
    }

    this.videoLobbyService.enterPip();
    const patientTabsItem: PatientTabsItem = {
      patientId: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      patientActive: true,
      profileImage: patient.imageUrl,
    };
    this.#tabsService.addNewTab(patientTabsItem);
  }
  /**
   * Toggles the visibility of the video panel in mobile mode.
   */
  public toggleVideoPanel(): void {
    this.showVideoPanelInMobileMode.update((x) => !x);
  }
  /**
   * Initializes the local video stream.
   *
   * @private
   */
  async #startVideo(): Promise<void> {
    if (!(await this.videoLobbyService.checkCameraMicrophonePermission())) return;
    this.videoLobbyService.cameraStatus.set('On');

    if (!this.videoLobbyService.localVideoStream()) {
      await this.videoLobbyService.createLocalVideoStream();
    }

    this.#localStream = this.#document.getElementById('local-stream') as HTMLDivElement;
    this.#localStreamRenderer = new VideoStreamRenderer(this.videoLobbyService.localVideoStream()!);
    const view: VideoStreamRendererView = await this.#localStreamRenderer.createView();
    this.#localStream.appendChild(view.target);
  }
  /**
   * Starts the call and initializes remote participants.
   *
   * @private
   */
  async #startCall(): Promise<void> {
    if (this.videoLobbyService.cameraStatus() === 'Off') await this.#startVideo();

    this.videoLobbyService.callParticipants.set([]);
    this.videoLobbyService.activeCall()?.attendees.forEach((attendee: Attendee): void => {
      if (attendee.acsId === this.videoLobbyService.acsId) return;

      this.videoLobbyService.callParticipants.update((data) => [
        ...data,
        {
          ...attendee,
          remoteData: signal(null),
          videoContainer: signal(null),
          streamRenderer: signal(null),
          isAvailableChangedEvent: async () => {},
          videoStreamsUpdatedEvent: () => {},
        },
      ]);
    });

    this.#checkParticipants();

    this.videoLobbyService.call!.on('remoteParticipantsUpdated', (res): void => {
      res.added.forEach((remoteParticipant: RemoteParticipant): void => {
        const participant: CallParticipant | undefined = this.videoLobbyService
          .callParticipants()
          .find(
            (participant: CallParticipant): boolean =>
              participant.acsId === (remoteParticipant.identifier as CommunicationUserIdentifier).communicationUserId,
          );

        if (participant) {
          participant.remoteData.set(remoteParticipant);
          this.#checkParticipantVideoStream(participant);
        }
      });
      res.removed.forEach((remoteParticipant: RemoteParticipant): void => {
        const participant: CallParticipant | undefined = this.videoLobbyService
          .callParticipants()
          .find(
            (participant: CallParticipant): boolean =>
              participant.acsId === (remoteParticipant.identifier as CommunicationUserIdentifier).communicationUserId,
          );
        if (participant) {
          this.#disposeStream(participant);
          this.#disposeParticipant(participant);
        }
      });
    });
  }
  /**
   * Checks participants and initializes video streams.
   */
  #checkParticipants(): void {
    this.videoLobbyService.callParticipants().forEach((participant: CallParticipant): void => {
      const remote: RemoteParticipant | undefined = this.videoLobbyService.call!.remoteParticipants.find(
        (attendee: RemoteParticipant): boolean =>
          (attendee.identifier as CommunicationUserIdentifier).communicationUserId === participant.acsId,
      );
      if (remote) {
        participant.remoteData.set(remote);
        this.#checkParticipantVideoStream(participant);
      }
    });
  }
  /**
   * Checks the video stream for a participant.
   * @param participant - The participant to check.
   */
  #checkParticipantVideoStream(participant: CallParticipant): void {
    participant.remoteData()!.videoStreams.forEach(async (remoteVideoStream: RemoteVideoStream): Promise<void> => {
      await this.#processParticipantVideoStream(remoteVideoStream, participant);
    });

    participant.videoStreamsUpdatedEvent = (res) => {
      res.added.forEach(async (remoteVideoStream: RemoteVideoStream): Promise<void> => {
        await this.#processParticipantVideoStream(remoteVideoStream, participant);
      });
      res.removed.forEach(() => {
        this.#disposeStream(participant);
      });
    };

    participant.remoteData()!.on('videoStreamsUpdated', participant.videoStreamsUpdatedEvent);
  }
  /**
   * Processes a remote video stream for a participant.
   * @param remoteVideoStream - The remote video stream to process.
   * @param participant - The participant associated with the stream.
   */
  async #processParticipantVideoStream(
    remoteVideoStream: RemoteVideoStream,
    participant: CallParticipant,
  ): Promise<void> {
    if (remoteVideoStream.mediaStreamType === 'ScreenSharing') {
      if (remoteVideoStream.isAvailable) {
        await this.#renderScreenShare(remoteVideoStream);
      }

      this.#screenSharingChangedEvent = async () => {
        if (remoteVideoStream.isAvailable) {
          await this.#renderScreenShare(remoteVideoStream);
        } else {
          this.#disposeScreenShare();
        }
      };
      remoteVideoStream.on('isAvailableChanged', this.#screenSharingChangedEvent);
    }

    if (remoteVideoStream.mediaStreamType === 'Video') {
      if (remoteVideoStream.isAvailable) {
        this.#renderParticipantVideoStream(remoteVideoStream, participant);
      }

      participant.isAvailableChangedEvent = async () => {
        if (remoteVideoStream.isAvailable) {
          await this.#renderParticipantVideoStream(remoteVideoStream, participant);
        } else {
          this.#disposeStream(participant);
        }
      };
      remoteVideoStream.on('isAvailableChanged', participant.isAvailableChangedEvent);
    }
  }
  /**
   * Renders a participant's video stream.
   * @param remoteVideoStream - The remote video stream to render.
   * @param participant - The participant associated with the stream.
   */
  async #renderParticipantVideoStream(
    remoteVideoStream: RemoteVideoStream,
    participant: CallParticipant,
  ): Promise<void> {
    participant.streamRenderer.set(new VideoStreamRenderer(remoteVideoStream));
    const view: VideoStreamRendererView = await participant.streamRenderer()!.createView();

    participant.videoContainer.set(this.#document.getElementById(participant.id) as HTMLDivElement);
    participant.videoContainer()!.appendChild(view.target);
    this.focusedParticipant.set(participant.acsId);
  }
  /**
   * Renders a screen sharing video stream.
   * @param remoteVideoStream - The remote video stream for screen sharing.
   */
  async #renderScreenShare(remoteVideoStream: RemoteVideoStream): Promise<void> {
    this.videoLobbyService.isScreenSharingInProgress.set('Others');

    this.#shareScreenStreamRenderer = new VideoStreamRenderer(remoteVideoStream);
    const view: VideoStreamRendererView = await this.#shareScreenStreamRenderer.createView();

    setTimeout(() => {
      const element: HTMLDivElement = this.#document.getElementById('screenShareStream') as HTMLDivElement;
      element.appendChild(view.target);
    }, 1000);
  }
  /**
   * Disposes of a participant's resources.
   * @param participant - The participant to dispose of.
   */
  #disposeStream(participant: CallParticipant): void {
    try {
      if (this.focusedParticipant() === participant.acsId) {
        this.focusedParticipant.set(
          this.videoLobbyService.callParticipants().find((participant) => participant.remoteData())?.acsId || '',
        );
      }
      participant.streamRenderer()?.dispose();
      participant.streamRenderer.set(null);
      participant.videoContainer.set(null);
    } catch {}
  }
  /**
   * Disposes of a participant's remote data.
   * @param participant - The participant to dispose of.
   */
  #disposeParticipant(participant: CallParticipant): void {
    participant.remoteData()?.videoStreams.forEach((stream: RemoteVideoStream) => {
      if (stream.mediaStreamType === 'Video') {
        stream.off('isAvailableChanged', participant.isAvailableChangedEvent);
      } else if (stream.mediaStreamType === 'ScreenSharing') {
        stream.off('isAvailableChanged', this.#screenSharingChangedEvent);
      }
    });
    participant.remoteData()?.off('videoStreamsUpdated', participant.videoStreamsUpdatedEvent);
    participant.remoteData.set(null);
  }
  /**
   * Disposes of a screen sharing stream.
   */
  #disposeScreenShare(): void {
    try {
      if (this.focusedParticipant() === 'screenShare') {
        this.focusedParticipant.set(
          this.videoLobbyService.callParticipants().find((participant) => participant.remoteData())?.acsId || '',
        );
      }
      this.#shareScreenStreamRenderer.dispose();
      setTimeout(() => {
        this.videoLobbyService.isScreenSharingInProgress.set('None');
      }, 1000);
    } catch {}
  }
}
