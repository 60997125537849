import { Component, OnInit } from '@angular/core';
/**
 * Component representing the not found page.
 */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  /**
   * Represents a simple constructor.
   */
  constructor() {}
  /**
   * Method to navigate back in browser history.
   */
  goBack() {
    history.back();
  }
  /**
   * Lifecycle hook called after component initialization.
   */
  ngOnInit(): void {}
}
