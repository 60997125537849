import { Component, EventEmitter, Output } from '@angular/core';
/**
 * Component representing meeting options.
 *
 * This component provides UI controls for various meeting options.
 */
@Component({
  selector: 'app-meeting-options',
  templateUrl: './meeting-options.component.html',
  styleUrls: ['./meeting-options.component.scss'],
})
export class MeetingOptionsComponent {
  /**
   * Event emitter for closing the side panel.
   * Emits a boolean value to indicate whether to close the panel.
   * @public
   */
  @Output() public closeSidePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
}
