import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  inject,
  OnDestroy,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import {
  ChatMessage,
  ChatMessageEditedEvent,
  ChatMessageReceivedEvent,
  ChatParticipant,
  ChatThreadCreatedEvent,
  TypingIndicatorReceivedEvent,
} from '@azure/communication-chat';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, sampleTime, Subject, Subscription } from 'rxjs';
import { ObservationSummarizationComponent } from 'src/app/modules/ai-metrics/components/observation-summarization/observation-summarization.component';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { CameraViewerComponent } from 'src/app/modules/shared/component/camera-viewer/camera-viewer.component';
import { FileUploadSuccess } from 'src/app/modules/shared/interfaces/common.entities';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import {
  Attachment,
  ChatFilter,
  ChatThreadMetaData,
  MessageListInputData,
  MessageTemplate,
  PaginatedChatThreadMetaData,
  SuggestedMessage,
  UserMetaData,
} from '../../entities/message.entities';
import { MessageAPIsService } from '../../services/message-apis.service';
import { MessageService } from '../../services/message.service';
import { CreateMessageThreadComponent } from '../create-message-thread/create-message-thread.component';
import { MessageConfirmationComponent } from '../message-confirmation/message-confirmation.component';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MessageFilterDialogComponent } from '../message-filter-dialog/message-filter-dialog.component';
import { MessageTemplateComponent } from '../message-template/message-template.component';

/**
 * Component responsible for handling messaging functionality.
 */
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements AfterContentInit, OnDestroy {
  /** List of threads displayed in the component. */
  public threadList: ChatThreadMetaData[] = [];
  /** Currently selected thread in the component. */
  public selectedThread: ChatThreadMetaData | undefined = undefined;
  /** Signal for managing the participant list string. */
  public participantListString: WritableSignal<string> = signal('');
  /** List of chat messages in the selected thread. */
  public chatMessages: ChatMessage[] = [];
  /** List of attachments associated with the messages. */
  public attachments: Attachment[] = [];
  /** Form control for searching messages. */
  public messageSearch: FormControl = new FormControl<string>('', Validators.maxLength(500));
  /** Number of file uploads in progress. */
  public fileUploadsInProgress = 0;
  /** Typing indicator received event. */
  public typingEvent: TypingIndicatorReceivedEvent | null = null;
  /** Temporary storage for thread metadata. */
  public tempThreadMetaData: ChatThreadMetaData[] = [];
  /** Subject for triggering message refresh events. */
  public refreshMessage$: Subject<string> = new Subject();
  /** Editor instance for CKEditor integration. */
  public ckEditor = ClassicEditor;
  /** Configuration for CKEditor instance. */
  public ckEditorConfig;
  /** Data bound to CKEditor instance. */
  public ckEditorData = '';
  /** Subject for managing message events. */
  public message$: Subject<boolean> = new Subject();
  /** Flag indicating write permission status. */
  public hasWritePermission = true;
  /** Height of the thread list component. */
  public threadListHeight = '455px';
  /** Subject for managing suggested message state. */
  public suggestedMessage$: BehaviorSubject<SuggestedMessage> = new BehaviorSubject({
    reply: '',
  });
  /** Flag indicating whether to show suggested messages. */
  public showSuggestedMessage = false;
  /** Flag indicating mobile patient summary visibility. */
  public mobilePatientSummary = false;
  /** Flag indicating patient summary visibility. */
  public showPatientSummary = false;
  /** Flag indicating whether to show the reset button. */
  public showResetButton = false;
  /** Flag indicating mobile message detail view. */
  public messageDetailMobile = false;
  /** Mode of the component ('MyMessages' or 'MyTeamMessages'). */
  public mode: 'MyMessages' | 'MyTeamMessages' = 'MyMessages';
  /** User Id of patient */
  public patientUserId: WritableSignal<string> = signal('');
  /** Filter object for controlling thread list filtering. */
  public chatThreadFilter: ChatFilter = {
    subject: '',
    message: '',
    unread: false,
    startDate: null,
    endDate: null,
    user: '',
    to: null,
    from: null,
    page: 1,
    unhandled: false,
  };
  /**
   * Reference to Observation Summarization Component
   */
  @ViewChild(ObservationSummarizationComponent)
  private observationSummaryComponent!: ObservationSummarizationComponent;
  /** Reference to the thread wrapper element. */
  @ViewChild('threadWrapper')
  private threadWrapper!: ElementRef<HTMLDivElement>;
  /** Reference to the thread loader element. */
  @ViewChild('threadLoader')
  private threadLoader!: ElementRef<HTMLDivElement>;
  /** Reference to the message filters element. */
  @ViewChild('messageFilters')
  private messageFilters!: ElementRef<HTMLDivElement>;
  /** Total number of pages in thread metadata. */
  private totalPagesInThreadMetaData = 1;
  /** Suggested message content. */
  private suggestedMessage = '';
  /** List of removed thread IDs. */
  private removedThreadIds: string[] = [];
  /** Subscription for typing events. */
  private typingSub$!: Subscription;
  /** Subscription for new message events. */
  private newMessageSub$!: Subscription;
  /** Subscription for message events. */
  private messageSub$!: Subscription;
  /** Subscription for new thread events. */
  private newThreadSub$!: Subscription;
  /** Injected instance of ContactsService. */
  #contactsService = inject(ContactsService);
  /**
   * Constructor of the MessageComponent.
   * @param acsService Instance of ACSService for communication with ACS.
   * @param change Instance of ChangeDetectorRef for manual change detection.
   * @param dialog Instance of MatDialog for opening dialogs.
   * @param http Instance of HttpService for HTTP requests.
   * @param messageService Instance of MessageService for managing messages.
   * @param messageApiService Instance of MessageAPIsService for message API interactions.
   * @param permissions Instance of PermissionsService for managing user permissions.
   * @param selfRef Instance of MatDialogRef for self reference in dialogs.
   * @param toast Instance of ToastrService for displaying toast messages.
   * @param data Data injected into the component through MatDialog.
   */
  public constructor(
    private acsService: ACSService,
    private change: ChangeDetectorRef,
    private dialog: MatDialog,
    private http: HttpService,
    private messageService: MessageService,
    private messageApiService: MessageAPIsService,
    private permissions: PermissionsService,
    private selfRef: MatDialogRef<MessageComponent>,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: MessageListInputData,
  ) {
    this.ckEditorConfig = this.messageService.ckEditorConfig;
  }
  /**
   * Performs initialization tasks including data loading and event subscriptions.
   */
  public async ngAfterContentInit() {
    this.showLoader();
    this.hasWritePermission = this.permissions.hasPermission('self.Chat.Write');
    this.showSuggestedMessage = this.permissions.hasPermission('ai.SuggestedHcpReplies.Read');
    await this.#contactsService.updateContacts();
    await this.messageService.initChat();
    if (this.data.patientId) {
      this.chatThreadFilter.user = this.data.patientId;
    }
    if (this.data.unhandled) {
      this.chatThreadFilter.unread = true;
      this.chatThreadFilter.unhandled = true;
    }
    await this.refreshThreadsMetaData('MyMessages', false, 'ForceRefresh');
    if (this.data.thread) {
      this.openMessageThread(this.data.thread);
    }
    if (this.threadList.length) {
      setTimeout(() => this.infiniteScrolling(), 0);
    }
    this.eventSubscriptions();
    this.http.loader.next(false);
  }
  /**
   * Lifecycle hook called on component destruction.
   * Unsubscribes from all active subscriptions to prevent memory leaks.
   */
  public ngOnDestroy() {
    this.typingSub$.unsubscribe();
    this.messageSub$.unsubscribe();
    this.newMessageSub$.unsubscribe();
    this.newThreadSub$.unsubscribe();
  }
  /**
   * Toggles the visibility of the mobile patient summary and message detail.
   */
  public toggleMobilePatientSummary() {
    this.mobilePatientSummary = !this.mobilePatientSummary;
    this.messageDetailMobile = !this.messageDetailMobile;
  }
  /**
   * Opens a dialog for creating a new message thread.
   */
  public openNewMessageThreadDialog(): void {
    const dialogRef: MatDialogRef<CreateMessageThreadComponent> = this.dialog.open(CreateMessageThreadComponent, {
      disableClose: true,
      panelClass: ['full-screen-dialog', 'create-message-dialog'],
      autoFocus: false,
      data: {
        headerTitle: 'newMessage',
        initACS: false,
      },
    });
    dialogRef.afterClosed().subscribe((res: ChatThreadMetaData): void => {
      this.addNewThread(res);
    });
  }

  /**
   * Adds a new thread to the list of temporary thread metadata.
   * @param thread The thread metadata to add.
   */
  public addNewThread(thread: ChatThreadMetaData): void {
    if (thread) {
      this.tempThreadMetaData.splice(0, 0, thread);
      this.verifyTempThreadMetaData();
    }
    this.http.loader.next(false);
  }

  /**
   * Opens a message thread with loader display until thread is fully opened.
   * @param thread The thread metadata to open.
   */
  public async openThreadWithLoader(thread: ChatThreadMetaData): Promise<void> {
    this.showLoader();
    await this.openMessageThread(thread);
    this.http.loader.next(false);
  }

  /**
   * Opens the contacts dialog if not in 'MyTeamMessages' mode.
   */
  public openContacts(): void {
    if (this.mode === 'MyTeamMessages') return;
    const dialogRef: MatDialogRef<MessageDialogComponent> = this.dialog.open(MessageDialogComponent, {
      data: {
        header: 'Contacts',
        threadId: this.selectedThread?.threadId,
      },
      panelClass: 'full-screen-dialog',
    });
    dialogRef.afterClosed().subscribe(async (res: boolean): Promise<void> => {
      if (res) {
        this.ckEditorData = await this.messageService.addTemplateData(
          this.ckEditorData,
          this.selectedThread?.threadId || '',
        );
        this.#updateParticipantList(this.selectedThread!);
        this.refreshThreadsMetaData(this.mode, true, 'ForceRefresh');
      }
    });
  }

  /**
   * Opens the message template dialog.
   */
  public openTemplates(): void {
    const dialogRef: MatDialogRef<MessageTemplateComponent> = this.dialog.open(MessageTemplateComponent, {
      autoFocus: false,
      panelClass: 'full-screen-dialog',
    });
    dialogRef.afterClosed().subscribe(async (res: MessageTemplate | undefined): Promise<void> => {
      if (res) {
        this.ckEditorData = await this.messageService.addTemplateData(
          `<p>${res.message.replaceAll('\n', '<br>')}<\p>`,
          this.selectedThread?.threadId || '',
        );
        this.change.markForCheck();
      }
    });
  }
  /**
   * Opens the message filter dialog.
   */
  public openFilter(): void {
    const dialogRef: MatDialogRef<MessageFilterDialogComponent> = this.dialog.open(MessageFilterDialogComponent, {
      data: {
        chatThreadFilter: this.chatThreadFilter,
        mode: this.mode,
      },
      autoFocus: false,
      panelClass: 'full-screen-dialog',
    });
    dialogRef.afterClosed().subscribe(async (res: ChatFilter | undefined): Promise<void> => {
      if (res) {
        this.showOrHideSuggestedMessage(null);
        this.showOrHidePatientSummary(null);
        this.chatThreadFilter = {
          to: res.to,
          from: res.from,
          subject: res.subject,
          message: res.message,
          unread: res.unread,
          startDate: res.startDate,
          endDate: res.endDate,
          user: this.data.patientId || '',
          page: 1,
          unhandled: res.unhandled,
        };

        await this.refreshThreadsMetaData(this.mode, true, 'ForceRefresh');
        this.selectedThread = undefined;
        this.threadListHeight = `${455 - this.messageFilters.nativeElement.offsetHeight}px`;
      }
    });
  }
  /**
   * Searches threads based on the message input.
   * @param ev The keyboard event triggering the search.
   */
  public async searchThreads(ev: KeyboardEvent): Promise<void> {
    if (ev.key === 'Enter' && this.messageSearch.value) {
      if (this.messageSearch.invalid) {
        this.toast.error('Maximum number of characters allowed - 500', 'Error');
        return;
      }

      this.chatThreadFilter.message = this.messageSearch.value;
      this.showOrHideSuggestedMessage(null);
      this.showOrHidePatientSummary(null);
      await this.refreshThreadsMetaData(this.mode, true, 'ForceRefresh');
      this.selectedThread = undefined;
    }
  }
  /**
   * Removes a filter from the chat thread filter.
   * @param filter The filter key to remove.
   */
  public async removeFilter(filter: keyof typeof this.chatThreadFilter): Promise<void> {
    this.http.loader.next(true);
    if (filter === 'unread' || filter === 'unhandled') {
      (this.chatThreadFilter[filter] as boolean) = false;
    } else if (filter === 'startDate' && this.chatThreadFilter.endDate) {
      this.chatThreadFilter.startDate = null;
      this.chatThreadFilter.endDate = null;
    } else {
      (this.chatThreadFilter[filter] as null) = null;
    }

    this.showOrHideSuggestedMessage(null);
    this.showOrHidePatientSummary(null);
    await this.refreshThreadsMetaData(this.mode, true, 'ForceRefresh');
    this.selectedThread = undefined;
    this.threadListHeight = `${455 - this.messageFilters.nativeElement.offsetHeight}px`;
    this.http.loader.next(false);
  }
  /**
   * Handles file selection event.
   * @param ev The file selection event.
   */
  public onFileSelected(ev: Event): void {
    const files: FileList | null = (ev.target as HTMLInputElement)?.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.previewFile(files[i]);
      }
      (ev.target as HTMLInputElement).value = '';
    }
  }
  /**
   * Opens the camera viewer dialog.
   */
  public openCamera(): void {
    if (this.attachments.length >= this.messageService.maxNumberOfFilesAllowed) {
      this.toast.error('Only 10 files are allowed per message.', 'Error');
      return;
    }

    const dialogRef: MatDialogRef<CameraViewerComponent> = this.dialog.open(CameraViewerComponent, {
      panelClass: 'full-screen-dialog',
    });
    dialogRef.afterClosed().subscribe((res: File): void => {
      if (res) {
        this.previewFile(res);
      }
    });
  }
  /**
   * Previews a file before uploading.
   * @param file The file to preview.
   */
  public previewFile(file: File): void {
    if (file) {
      if (this.attachments.length >= this.messageService.maxNumberOfFilesAllowed) {
        this.toast.error('Only 10 files are allowed per message.', `Error File - ${file.name}`);
        return;
      }

      if (file.size > this.messageService.maxFileSizeAllowed) {
        this.toast.error('Maximum 25MB allowed per file', `Error File - ${file.name}`);
        return;
      }

      const localFileUrl: string = URL.createObjectURL(file);
      const fileIcon: string | SafeUrl | null = this.messageService.getFileIcon(file.type, localFileUrl);

      if (!fileIcon) {
        this.toast.error('File format not supported', `Error File - ${file.name}`);
        return;
      }

      const length: number = this.attachments.push({
        url: '',
        fileIcon: fileIcon,
        file,
        mimeType: '',
        uploadProgress: 0,
        documentId: '',
      });

      this.messageService
        .uploadFile(file, this.selectedThread?.threadId || '')
        .subscribe((res: HttpEvent<FileUploadSuccess>) => {
          if (res.type === HttpEventType.Response) {
            this.attachments[length - 1].uploadProgress = null;
            this.attachments[length - 1].url = res.body ? res.body.url : '';
            this.attachments[length - 1].mimeType = res.body ? res.body.mimetype : '';
            this.attachments[length - 1].documentId = res.body ? res.body?.documentId : '';
            this.fileUploadsInProgress--;
            this.change.markForCheck();
          }
          if (res.type === HttpEventType.UploadProgress) {
            this.attachments[length - 1].uploadProgress = res.total ? Math.round((100 * res.loaded) / res.total) : 0;
            this.change.markForCheck();
          }
          if (res.type === HttpEventType.Sent) {
            this.fileUploadsInProgress++;
          }
        });
    }
  }

  /**
   * Remove File from Attachment list
   * @param index - Index of file
   * @param documentId - Document Id of file
   */
  public removeFile(index: number, documentId: string): void {
    this.attachments.splice(index, 1);
    this.messageService.deleteAttachmentFromServer(documentId, this.selectedThread?.threadId || '');
  }
  /**
   * Sends the composed message and any attachments to the selected thread.
   */
  public async sendMessage(): Promise<void> {
    this.ckEditorData = this.ckEditorData.trim();
    if (!this.ckEditorData) return;
    try {
      await this.messageService.sendMessage(
        this.selectedThread?.threadId || '',
        this.messageService.ckEditorHtml2Text(this.ckEditorData),
        this.attachments,
      );
      this.trackSuggestedMessage();
      this.ckEditorData = '';
      this.attachments = [];
      this.showResetButton = false;
      this.change.detectChanges();
    } catch {
      this.toast.error('There was some problem in sending message. Please try again.', 'Error');
    }
  }
  /**
   * Removes the current user from the selected thread.
   */
  public async removeParticipant(): Promise<void> {
    if (!this.selectedThread) return;
    const participants: ChatParticipant[] = await this.messageService.getParticipantList(this.selectedThread.threadId);
    if (participants.length <= 2) {
      const error = `Please add another ${this.permissions.getUserConfigurations().clientLabel.managerUser} before leaving this conversation.`;
      this.toast.error(error);
      this.#updateParticipantList(this.selectedThread);
      return;
    }

    const dialogRef: MatDialogRef<MessageConfirmationComponent> = this.dialog.open(MessageConfirmationComponent, {
      data: {
        header: 'confirmLeaveConversation',
        message: 'leaveConversationMessage',
        function: 'remove',
        threadId: this.selectedThread.threadId,
      },
    });
    dialogRef.afterClosed().subscribe(async (res: boolean): Promise<void> => {
      if (res) {
        this.removedThreadIds.push(this.selectedThread?.threadId || '');
        this.removeThreads();
        this.selectedThread = undefined;
        this.showOrHidePatientSummary(null);
      }
    });
  }

  /**
   * Add Suggested Message to Message Input
   * @param message - suggested message
   */
  public async useSuggestion(message: string): Promise<void> {
    if (!message) {
      this.showSuggestedMessage = false;
      return;
    }

    this.suggestedMessage = message;
    this.ckEditorData = await this.messageService.addTemplateData(
      `<p>${message.replaceAll('\n', '<br>')}<\p>`,
      this.selectedThread?.threadId || '',
    );

    this.showSuggestedMessage = false;
    this.showResetButton = true;
    this.change.markForCheck();
  }

  /**
   * Reset Message Input
   */
  public resetMessage(): void {
    this.ckEditorData = '';
    this.showSuggestedMessage = true;
    this.showResetButton = false;
    setTimeout(() => {
      this.messageApiService.suggestedMessageId$.next(this.selectedThread?.threadId || '');
    }, 0);
  }

  /**
   * Show or Hide Patient Observation Summary
   * @param thread - Selected Thread
   */
  public showOrHidePatientSummary(thread: ChatThreadMetaData | null): void {
    if (!thread) {
      this.showPatientSummary = false;
      return;
    }

    if (!this.permissions.hasPermission('ai.PatientSummarization.Read')) return;

    const recipient: UserMetaData | undefined = this.messageService.findManagedUser(thread.recipients);

    if (recipient) {
      this.showPatientSummary = true;
      this.patientUserId.set(recipient.user);
      return;
    }

    this.showPatientSummary = false;
  }

  /**
   * Show or Hide Suggested Message Section
   * @param threadId - Thread Id of selected thread
   */
  public showOrHideSuggestedMessage(thread: ChatThreadMetaData | null): void {
    if (!thread) {
      this.showSuggestedMessage = false;
      return;
    }

    if (!this.permissions.hasPermission('ai.SuggestedHcpReplies.Read')) return;

    const recipient: UserMetaData | undefined = this.messageService.findManagedUser(thread.recipients);

    if (recipient) {
      this.showSuggestedMessage = true;
      setTimeout(() => {
        this.patientUserId.set(recipient.user);
        this.messageApiService.suggestedMessageId$.next(thread.threadId);
      }, 0);
      return;
    }

    this.showSuggestedMessage = false;
  }
  /**
   * Closes the current dialog.
   */
  public closeDialog(): void {
    this.messageService.closedMessageDialog$.next(true);
    this.selfRef.close();
  }
  /**
   * Refreshes the metadata for message threads based on the specified mode and options.
   * @param mode The mode indicating 'MyMessages' or 'MyTeamMessages'.
   * @param showLoader Whether to display the loader during the refresh.
   * @param addOn Additional option to reset filters or force refresh.
   */
  public async refreshThreadsMetaData(
    mode: 'MyMessages' | 'MyTeamMessages',
    showLoader = false,
    addOn: 'ResetFilter' | 'ForceRefresh' | 'None' = 'None',
  ): Promise<void> {
    if (this.mode === mode && addOn !== 'ForceRefresh') return;
    if (showLoader) this.showLoader();
    this.mode = mode;
    if (this.mode === 'MyTeamMessages') {
      this.showOrHideSuggestedMessage(null);
      this.showOrHidePatientSummary(null);
    }
    if (addOn === 'ResetFilter') {
      this.selectedThread = undefined;
      this.chatThreadFilter = {
        subject: '',
        message: '',
        unread: this.data.unhandled ? this.chatThreadFilter.unread : false,
        startDate: null,
        endDate: null,
        user: this.data.patientId || '',
        to: null,
        from: null,
        page: 1,
        unhandled: this.data.unhandled ? this.chatThreadFilter.unhandled : false,
      };
      this.messageSearch.reset();
    }
    this.chatThreadFilter.page = 1;
    const threadMetaData: PaginatedChatThreadMetaData =
      mode === 'MyMessages'
        ? await this.messageService.getMyMessageThreads(this.chatThreadFilter)
        : await this.messageService.getMyTeamMessageThreads(this.chatThreadFilter);
    this.threadList = threadMetaData.data;
    this.verifyTempThreadMetaData();
    this.removeThreads();
    this.totalPagesInThreadMetaData = threadMetaData.pagination.totalPage;
    this.change.markForCheck();

    if (showLoader) this.http.loader.next(false);
  }
  /**
   * Joins the current user to the selected conversation thread.
   */
  public async joinConversation(): Promise<void> {
    try {
      await this.messageApiService.joinConversation(
        this.selectedThread?.threadId || '',
        this.messageService.communicationUserId,
        this.messageService.userDemographicData.userID,
      );
      this.markAsHandled(this.selectedThread || ({} as ChatThreadMetaData));
      this.removedThreadIds.push(this.selectedThread?.threadId || '');
      this.removeThreads();
      this.selectedThread = undefined;
    } catch {
      this.toast.error('There was an error while joining the conversation. Please try again after some time.', 'Error');
    }
  }

  /**
   * Reloads the Observation Summary
   */
  public reloadObservationSummary(): void {
    this.observationSummaryComponent.reload();
  }

  /**
   * Loads more chat metadata when the component intersects with the viewport.
   * @param entries The entries observed by the IntersectionObserver.
   */
  private loadMoreChatMetaData = async (entries: IntersectionObserverEntry[]): Promise<void> => {
    if (!entries[0].isIntersecting) return;

    if (this.chatThreadFilter.page >= this.totalPagesInThreadMetaData) return;

    this.chatThreadFilter.page++;
    const threadMetaData: PaginatedChatThreadMetaData =
      this.mode === 'MyTeamMessages'
        ? await this.messageService.getMyTeamMessageThreads(this.chatThreadFilter)
        : await this.messageService.getMyMessageThreads(this.chatThreadFilter);
    this.threadList.push(...threadMetaData.data);
    this.totalPagesInThreadMetaData = threadMetaData.pagination.totalPage;
    this.change.markForCheck();
  };

  /**
   * Subscribe to message and typing events
   */
  private eventSubscriptions(): void {
    this.newMessageSub$ = this.acsService.refreshData$.subscribe(
      async (res: ChatMessageReceivedEvent | ChatMessageEditedEvent): Promise<void> => {
        if (res && res.threadId === this.selectedThread?.threadId) {
          const index: number = this.chatMessages.findIndex((message) => message.id === res.id);
          if (index > -1) {
            this.chatMessages[index].metadata = res.metadata;
            this.refreshMessage$.next(res.id);
            return;
          }
          this.updateMessageList(res);
          this.updateThreadList(res, false);
          if (
            (res.sender as CommunicationUserIdentifier).communicationUserId !== this.messageService.communicationUserId
          )
            this.showOrHideSuggestedMessage(this.selectedThread);
          return;
        }
        this.updateThreadList(res, (res as ChatMessageEditedEvent).editedOn ? false : true);
      },
    );

    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      // do nothing
    }, 0);
    this.typingSub$ = this.messageService.typing.subscribe((res: TypingIndicatorReceivedEvent): void => {
      if (res.threadId === this.selectedThread?.threadId) {
        this.typingEvent = res;
        this.change.markForCheck();
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.typingEvent = null;
          this.change.markForCheck();
        }, 2000);
      }
    });

    this.messageSub$ = this.message$.pipe(sampleTime(2000)).subscribe((): void => {
      if (this.ckEditorData) {
        this.messageService.sendTypingNotification(this.selectedThread?.threadId || '');
      }
    });

    this.newThreadSub$ = this.acsService.newThread$.subscribe((res: ChatThreadCreatedEvent): void => {
      if (res) {
        setTimeout(() => {
          this.refreshThreadsMetaData(this.mode, false, 'ForceRefresh');
        }, 10000);
      }
    });
  }
  /**
   * Updates the thread list based on received chat message data.
   * @param chatMessageData The received chat message data.
   * @param increaseUnreadCount Flag indicating whether to increase unread message count.
   */
  private updateThreadList(chatMessageData: ChatMessageReceivedEvent, increaseUnreadCount = true): void {
    const index: number = this.threadList.findIndex(
      (thread: ChatThreadMetaData): boolean => thread.threadId === chatMessageData.threadId,
    );
    if (index > -1) {
      if (increaseUnreadCount) this.threadList[index].unreadCount++;
      this.threadList[index].lastMessage.date = chatMessageData.createdOn.toISOString();
      this.threadList[index].lastMessage.displayName = chatMessageData.senderDisplayName;
      this.threadList[index].lastMessage.message = chatMessageData.message;
      this.threadList[index].unreadMessages.push(chatMessageData.id);
    }
    this.change.markForCheck();
  }
  /**
   * Updates the message list based on received chat message data.
   * @param chatMessageData The received chat message data.
   */
  private updateMessageList(chatMessageData: ChatMessageReceivedEvent): void {
    this.chatMessages.push({
      id: chatMessageData.id,
      type: 'text',
      sequenceId: '',
      version: '',
      createdOn: chatMessageData.createdOn,
      content: {
        message: chatMessageData.message,
      },
      metadata: chatMessageData.metadata,
      sender: chatMessageData.sender,
      senderDisplayName: chatMessageData.senderDisplayName,
    });
    this.messageService.markAsRead(chatMessageData.threadId, [chatMessageData.id]);
    this.change.markForCheck();
  }

  /**
   * Open Selected Thread and load messages
   * @param thread - Selected Thread
   */
  private async openMessageThread(thread: ChatThreadMetaData): Promise<void> {
    if (!thread) {
      this.selectedThread = undefined;
      return;
    }

    this.ckEditorData = '';
    this.attachments.forEach((attachment: Attachment): void =>
      this.messageService.deleteAttachmentFromServer(attachment.documentId, this.selectedThread?.threadId || ''),
    );
    this.attachments = [];
    this.#updateParticipantList(thread);
    this.showResetButton = false;
    await this.getMessages(thread);
    this.selectedThread = thread;
    if (this.mode === 'MyMessages') {
      this.showOrHideSuggestedMessage(thread);
      this.showOrHidePatientSummary(thread);
    }
  }

  /**
   * Track Suggested Message, when it is used without modification
   */
  private trackSuggestedMessage(): void {
    if (
      this.suggestedMessage &&
      this.messageService.ckEditorHtml2Text(this.ckEditorData).trim() === this.suggestedMessage
    ) {
      this.messageApiService.trackSuggestedMessage(this.selectedThread?.threadId || '', {
        reply: this.suggestedMessage,
      });
    }
  }

  /**
   * Get Messages of a given thread Id
   * @param thread - Selected thread
   */
  private async getMessages(thread: ChatThreadMetaData): Promise<void> {
    try {
      if (this.mode === 'MyMessages') {
        this.chatMessages = await this.messageService.getAllMessagesForMyThreads(thread.threadId);
        this.markAsRead(thread);
        this.chatMessages.sort((a, b) => parseInt(a.sequenceId) - parseInt(b.sequenceId));
      } else {
        this.chatMessages = await this.messageService.getAllMessagesForMyTeamsThreads(thread.threadId);
        this.chatMessages.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
      }
      this.change.markForCheck();
    } catch {}
  }
  /**
   * Marks the thread as handled if it requires attention.
   * @param thread The thread to mark as handled.
   */
  private async markAsHandled(thread: ChatThreadMetaData): Promise<void> {
    if (!thread.requiresAttention) return;

    const patient: UserMetaData | undefined = thread.recipients.find(
      (recipient: UserMetaData): boolean => recipient.userType === 'Managed',
    );
    if (!patient) return;
    try {
      await this.messageApiService.handleMessageByThreadId(patient.user, thread.threadId);
      thread.requiresAttention = false;
      this.change.markForCheck();
    } catch {}
  }
  /**
   * Marks the thread as read based on unread messages.
   * @param thread The thread to mark as read.
   */
  private async markAsRead(thread: ChatThreadMetaData): Promise<void> {
    if (!thread.unreadMessages.length && !thread.unreadCount) return;

    if (!thread.unreadMessages.length && thread.unreadCount) {
      await this.messageApiService.markAllMessagesAsRead(thread.threadId);
      thread.unreadCount = 0;
      this.change.markForCheck();
      return;
    }

    const readMessageIds: string[] = [];
    thread.unreadMessages.forEach((id: string): void => {
      const message: ChatMessage | undefined = this.chatMessages.find(
        (message: ChatMessage): boolean => message.id === id,
      );
      if (message) {
        readMessageIds.push(message.id);
      }
    });
    if (!readMessageIds.length) return;

    try {
      await this.messageService.markAsRead(thread.threadId, readMessageIds);
      thread.unreadCount = thread.unreadCount - readMessageIds.length;
      thread.unreadCount = thread.unreadCount >= 0 ? thread.unreadCount : 0;
      this.change.markForCheck();
    } catch {}
  }

  /**
   * Start showing full page loader
   */
  private showLoader(): void {
    setTimeout(() => {
      this.http.loader.next(true);
    }, 0);
  }

  /**
   * Verify if New Thread has been fetched in MetaData
   * If MetaData is not updated, add newly created thread to Thread List
   */
  private verifyTempThreadMetaData(): void {
    if (this.mode === 'MyTeamMessages') return;
    if (!this.tempThreadMetaData.length) return;

    let i = this.tempThreadMetaData.length - 1;
    while (i >= 0) {
      const threadIndex: number = this.threadList.findIndex(
        (thread: ChatThreadMetaData): boolean => thread.threadId === this.tempThreadMetaData[i].threadId,
      );
      if (threadIndex >= 0) {
        this.tempThreadMetaData.splice(threadIndex, 1);
      } else {
        this.threadList.splice(0, 0, this.tempThreadMetaData[i]);
        this.change.markForCheck();
      }
      i--;
    }
  }

  /**
   * Setup infinite scrolling for Threads and Messages
   */
  private infiniteScrolling(): void {
    const options: IntersectionObserverInit = {
      root: this.threadWrapper.nativeElement,
      rootMargin: '0px',
      threshold: 0.9,
    };
    const observer = new IntersectionObserver(this.loadMoreChatMetaData, options);
    observer.observe(this.threadLoader.nativeElement);
  }

  /**
   * Remove Threads which the user has left but have not been updated on server
   */
  private removeThreads(): void {
    if (!this.removedThreadIds.length) return;

    let i = this.removedThreadIds.length - 1;
    while (i >= 0) {
      const threadIndex: number = this.threadList.findIndex(
        (thread: ChatThreadMetaData): boolean => thread.threadId === this.removedThreadIds[i],
      );
      if (threadIndex >= 0) {
        this.threadList.splice(threadIndex, 1);
        this.change.markForCheck();
      } else {
        this.removedThreadIds.splice(i, 1);
      }
      i--;
    }
  }

  /**
   * Updates the participant list string for a given thread ID.
   * @param threadId The ID of the thread for which participant list is updated.
   */
  async #updateParticipantList(thread: ChatThreadMetaData): Promise<void> {
    this.participantListString.set('');
    try {
      (await this.messageService.getParticipantList(thread.threadId)).forEach((participant: ChatParticipant): void => {
        const userName: string | undefined = this.#contactsService.getUserFromACSId(
          (participant.id as CommunicationUserIdentifier).communicationUserId,
        )?.userFullName;
        this.participantListString.update((x) => x + (userName ? `${userName}, ` : ''));
      });
    } catch {
      thread.recipients.forEach((participant: UserMetaData): void => {
        const userName: string | undefined = this.#contactsService.getUserFromACSId(
          participant.communicationUserId,
        )?.userFullName;
        this.participantListString.update((x) => x + (userName ? `${userName}, ` : ''));
      });
    }

    this.participantListString.update((x) => x.slice(0, -2));
  }
}
