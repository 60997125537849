import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OnDestroy,
  WritableSignal,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { Subject, Subscription, debounceTime, firstValueFrom } from 'rxjs';
import {
  FinalObservationSummary,
  ObservationSummary,
  StreamUrls,
} from 'src/app/modules/ai-metrics/entities/observation-summary.entities';
import { ObservationSummaryService } from 'src/app/modules/ai-metrics/services/observation-summary.service';
import { DotLoaderComponent } from 'src/app/modules/shared/components/dot-loader/dot-loader.component';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { SkeletonLoaderComponent } from 'src/app/modules/shared/skeleton-loader/skeleton-loader.component';
import { AIMetricFormattedData, SummaryAIMetricTableInfo, SummaryAIMetrics } from '../../entities/ai-metrics.entities';
import { AiMetricsService } from '../../services/ai-metrics.service';
import { SummaryDetailedAnalysisComponent } from '../summary-detailed-analysis/summary-detailed-analysis.component';

/**
 * Component to display patient summarization information.
 * Retrieves patient summary data and manages streaming updates.
 */
@Component({
  selector: 'app-observation-summarization',
  templateUrl: './observation-summarization.component.html',
  styleUrls: ['./observation-summarization.component.scss'],
  standalone: true,
  imports: [FeatherModule, SkeletonLoaderComponent, NgClass, TranslatePipe, DotLoaderComponent, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObservationSummarizationComponent implements OnDestroy {
  /** Input Signal for Patient ID */
  public patientId: InputSignal<string> = input.required();
  /** User ID of the patient. */
  public userId = '';
  /** Array to store patient summaries. */
  public summary: FinalObservationSummary[] = [];
  /** Thank you message string. */
  public thankYouMessage = '';
  /** Feedback message string. */
  public feedback = '';
  /** Boolean flag indicating helpful feedback. */
  public helpful = false;
  /** Signal for creation message updates. */
  public creationMessage: WritableSignal<string> = signal('');
  /** Signal for vital non-streaming summary updates. */
  public vitalNonStreamingSummary: WritableSignal<string> = signal('');
  /** Signal for vital streaming summary updates. */
  public vitalStreamingSummary: WritableSignal<string> = signal('');
  /** Signal for health tracker summary updates. */
  public healthTrackerSummary: WritableSignal<string> = signal('');
  /** Signal for message summary updates. */
  public messageSummary: WritableSignal<string> = signal('');
  /** Signal for streaming updates. */
  public streaming: WritableSignal<string> = signal('1234');
  /** Signal for response status updates. */
  public responseStatus: WritableSignal<'Loading' | 'No Summary' | 'Summary Received' | 'Stream'> = signal('Loading');
  /** Signal for feedback mode updates. */
  public feedbackMode: WritableSignal<'No Feedback' | 'Helpful' | 'Comment'> = signal('No Feedback');
  /** Subscription for vital non-streaming summary stream. */
  public vitalNonStreamingSummaryStreamSub$!: Subscription;
  /** Subscription for vital streaming summary stream. */
  public vitalStreamingSummaryStreamSub$!: Subscription;
  /** Subscription for health tracker summary stream. */
  public healthTrackerSummaryStreamSub$!: Subscription;
  /** Subscription for message summary stream. */
  public messageSummaryStreamSub$!: Subscription;

  public aIMetricsState: WritableSignal<'Fetching' | 'Fetched' | 'No Data'> = signal('No Data');
  public aiMetricsFormattedData: WritableSignal<AIMetricFormattedData[]> = signal([]);

  /** Private subscription for patient summary updates. */
  private patientSummarySub$!: Subscription;
  /** Private subscription for patient summary user ID updates. */
  private patientSummaryUserIdSub$: Subscription;
  /** Subject for notifying observers about changes in patient summary user ID. */
  #patientSummaryUserId$: Subject<string> = new Subject();
  /** Injected HTTP service for API calls. */
  #http = inject(HttpService);
  /** Injected service for Observation Summary */
  #observationSummaryService = inject(ObservationSummaryService);
  /** Injected service for AI Metrics */
  #aIMetricsService = inject(AiMetricsService);
  /** Injected service for Mat Dialog */
  #dialog = inject(MatDialog);
  #aIMetricsSub$!: Subscription;
  #streamUrls: StreamUrls | null = null;

  /**
   * Constructs the patient summary component.
   */
  public constructor() {
    effect(() => {
      if (!this.patientId()) return;

      untracked(() => {
        this.#patientSummaryUserId$.next(this.patientId());
      });
    });

    this.patientSummaryUserIdSub$ = this.#patientSummaryUserId$
      .pipe(debounceTime(1000))
      .subscribe((id: string): void => {
        this.responseStatus.set('Loading');
        this.feedbackMode.set('No Feedback');
        this.aIMetricsState.set('No Data');
        this.aiMetricsFormattedData.set([]);
        this.#streamUrls = null;

        this.thankYouMessage = '';
        this.feedback = '';
        this.patientSummarySub$ = this.#observationSummaryService.getPatientSummary(id).subscribe({
          next: (res: ObservationSummary): void => {
            this.userId = id;
            this.#mapData(res);
            this.patientSummarySub$.unsubscribe();
          },
          error: () => this.responseStatus.set('No Summary'),
        });
      });
  }

  /**
   * Sends feedback on the patient summary.
   */
  public async sendFeedback(): Promise<void> {
    try {
      this.thankYouMessage = (
        await this.#observationSummaryService.sendFeedbackOnPatientSummary(this.userId, this.helpful, this.feedback)
      ).msg;
    } catch {
      this.thankYouMessage = '';
    }
    this.feedbackMode.set('No Feedback');
    this.feedback = '';
  }

  /**
   * Cleans up subscriptions on component destruction.
   */
  public ngOnDestroy(): void {
    if (this.patientSummarySub$) this.patientSummarySub$.unsubscribe();
    this.patientSummaryUserIdSub$.unsubscribe();
    this.#unsubscribeToAllStreams();
  }

  /**
   * Reloads patient summary data.
   */
  public async reload(): Promise<void> {
    this.responseStatus.set('Loading');
    this.feedbackMode.set('No Feedback');
    this.aIMetricsState.set('No Data');
    this.aiMetricsFormattedData.set([]);
    this.#streamUrls = null;

    this.thankYouMessage = '';
    this.feedback = '';

    const summaryData: ObservationSummary = await firstValueFrom(
      this.#observationSummaryService.getPatientSummary(this.userId, true),
    );
    this.#mapData(summaryData);
  }

  /**
   * Opens a dialog for displaying detailed analysis of message summaries.
   */
  public openSummaryDetailAnalysis(): void {
    this.#dialog.open(SummaryDetailedAnalysisComponent, {
      panelClass: ['full-screen-dialog'],
      autoFocus: false,
      disableClose: true,
      data: this.aiMetricsFormattedData(),
    });
  }

  /**
   * Maps patient summary data to component properties.
   *
   * @param summaryData - The patient summary data to map.
   */
  #mapData(summaryData: ObservationSummary): void {
    this.streaming.set('1234');
    this.creationMessage.set('');

    if (!summaryData || ((!summaryData.finalSummary || !summaryData.finalSummary.length) && !summaryData.streamUrls)) {
      this.responseStatus.set('No Summary');
      return;
    }

    const endDate = new Date(summaryData.timeWindow.end);
    const validity = Math.ceil(
      (endDate.getTime() - new Date(summaryData.timeWindow.start).getTime()) / 1000 / 60 / 60 / 24,
    );
    this.creationMessage.set(
      `This ${validity} day summary was last created on ${endDate.toLocaleDateString()}, ${endDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`,
    );
    this.feedbackMode.set(summaryData.requestFeedback ? 'Helpful' : 'No Feedback');

    if (summaryData.finalSummary && summaryData.finalSummary.length) {
      this.summary = summaryData.finalSummary;
      this.responseStatus.set('Summary Received');

      if (summaryData.metrics && summaryData.metrics.length) {
        this.#processAIMetrics(summaryData.metrics);
        this.aIMetricsState.set('Fetched');
      }
    } else if (summaryData.streamUrls) {
      this.streaming.set('');
      this.messageSummary.set('');
      this.healthTrackerSummary.set('');
      this.vitalNonStreamingSummary.set('');
      this.vitalStreamingSummary.set('');
      this.#streamUrls = summaryData.streamUrls;
      this.#getStreamData(summaryData.streamUrls);
      this.responseStatus.set('Stream');
    }
  }
  /**
   * Initializes streaming subscriptions for various streams.
   * @param streamUrls Object containing URLs for different streams.
   */
  #getStreamData(streamUrls: StreamUrls): void {
    this.vitalNonStreamingSummaryStreamSub$ = this.#http
      .subscribeToSSE(streamUrls.vitalNonStreamingSummary, '![DONE]')
      .subscribe({
        next: (res) => {
          if (res === '![DONE]') {
            this.streaming.update((x) => x + '3');
            this.#getAIMetrics();
            this.vitalNonStreamingSummaryStreamSub$.unsubscribe();
            return;
          }
          this.vitalNonStreamingSummary.update((x) => x + res);
        },
        error: () => {
          this.streaming.update((x) => x + '3');
          this.#getAIMetrics();
          this.vitalNonStreamingSummaryStreamSub$.unsubscribe();
        },
      });

    this.vitalStreamingSummaryStreamSub$ = this.#http
      .subscribeToSSE(streamUrls.vitalStreamingSummary, '![DONE]')
      .subscribe({
        next: (res) => {
          if (res === '![DONE]') {
            this.streaming.update((x) => x + '4');
            this.#getAIMetrics();
            this.vitalStreamingSummaryStreamSub$.unsubscribe();
            return;
          }
          this.vitalStreamingSummary.update((x) => x + res);
        },
        error: () => {
          this.streaming.update((x) => x + '4');
          this.#getAIMetrics();
          this.vitalStreamingSummaryStreamSub$.unsubscribe();
        },
      });

    this.healthTrackerSummaryStreamSub$ = this.#http
      .subscribeToSSE(streamUrls.healthTrackerSummary, '![DONE]')
      .subscribe({
        next: (res) => {
          if (res === '![DONE]') {
            this.streaming.update((x) => x + '1');
            this.#getAIMetrics();
            this.healthTrackerSummaryStreamSub$.unsubscribe();
            return;
          }
          this.healthTrackerSummary.update((x) => x + res);
        },
        error: () => {
          this.streaming.update((x) => x + '1');
          this.#getAIMetrics();
          this.healthTrackerSummaryStreamSub$.unsubscribe();
        },
      });

    this.messageSummaryStreamSub$ = this.#http.subscribeToSSE(streamUrls.messageSummary, '![DONE]').subscribe({
      next: (res) => {
        if (res === '![DONE]') {
          this.streaming.update((x) => x + '2');
          this.#getAIMetrics();
          this.messageSummaryStreamSub$.unsubscribe();
          return;
        }
        this.messageSummary.update((x) => x + res);
      },
      error: () => {
        this.streaming.update((x) => x + '2');
        this.#getAIMetrics();
        this.messageSummaryStreamSub$.unsubscribe();
      },
    });
  }
  /**
   * Unsubscribes from all active stream subscriptions.
   */
  #unsubscribeToAllStreams(): void {
    if (this.vitalNonStreamingSummaryStreamSub$ && !this.vitalNonStreamingSummaryStreamSub$.closed)
      this.vitalNonStreamingSummaryStreamSub$.unsubscribe();
    if (this.vitalStreamingSummaryStreamSub$ && this.vitalStreamingSummaryStreamSub$.closed)
      this.vitalStreamingSummaryStreamSub$.unsubscribe();
    if (this.healthTrackerSummaryStreamSub$ && this.healthTrackerSummaryStreamSub$.closed)
      this.healthTrackerSummaryStreamSub$.unsubscribe();
    if (this.messageSummaryStreamSub$ && this.messageSummaryStreamSub$.closed)
      this.messageSummaryStreamSub$.unsubscribe();
  }

  #getAIMetrics(): void {
    if (!this.patientId()) return;
    if (!this.#streamUrls) return;
    if (
      !(
        this.streaming().includes('1') &&
        this.streaming().includes('2') &&
        this.streaming().includes('3') &&
        this.streaming().includes('4')
      )
    )
      return;

    this.aIMetricsState.set('Fetching');

    const streamIds: string[] = [];

    for (const [, value] of Object.entries(this.#streamUrls)) {
      const index = value.indexOf('token=');
      streamIds.push(value.substring(index + 6));
    }

    setTimeout(() => {
      this.#aIMetricsSub$ = this.#aIMetricsService.getAIMetrics(this.patientId()!, streamIds, 2).subscribe({
        next: (res) => {
          if (res.length) {
            this.#processAIMetrics(res as SummaryAIMetrics[]);
          }
          this.aIMetricsState.set('Fetched');
          this.#aIMetricsSub$.unsubscribe();
        },
        error: () => {
          this.aIMetricsState.set('No Data');
          this.#aIMetricsSub$.unsubscribe();
        },
      });
    }, 5000);
  }

  #processAIMetrics(metrics: SummaryAIMetrics[]): void {
    let countAccuracy = 0;
    let countConsistency = 0;
    let countQuality = 0;

    let totalAccuracy = 0;
    let totalConsistency = 0;
    let totalQuality = 0;

    const table: AIMetricFormattedData = {
      labelKey: 'tableInfo',
      descriptionKey: 'tableInfoDescription',
      score: -1,
      colorClass: 'servity-0',
      tableInfo: [],
    };

    metrics.forEach((metric: SummaryAIMetrics) => {
      const info: SummaryAIMetricTableInfo = {
        labelKey:
          metric.type === 'message_summary'
            ? 'messages'
            : metric.type === 'ht_summary'
              ? 'guidedSession'
              : metric.type === 'vital_non_streaming'
                ? 'vitals'
                : 'vitalsStreaming',
        accuracy: -1,
        quality: -1,
        consistency: -1,
      };

      if (metric.scores.med_accuracy) {
        const score = metric.scores.med_accuracy.display_score;
        if (score >= 0 && score <= 1) {
          totalAccuracy += score;
          countAccuracy++;
          info.accuracy = score * 100;
        }
      }

      if (metric.scores.consistency) {
        const score = metric.scores.consistency.display_score;
        if (score >= 0 && score <= 5) {
          totalConsistency += score;
          countConsistency++;
          info.consistency = (score / 5) * 100;
        }
      }

      if (metric.scores.quality) {
        const score = metric.scores.quality.display_score;
        if (score >= 1 && score <= 5) {
          totalQuality += score;
          countQuality++;
          info.quality = (score / 5) * 100;
        }
      }

      table.tableInfo!.push(info);
    });

    this.aiMetricsFormattedData.update((data) => [
      ...data,
      this.#aIMetricsService.processMedicalAIMetrics(
        countAccuracy ? totalAccuracy / countAccuracy : -1,
        'medicalAccuracy',
      ),
    ]);

    this.aiMetricsFormattedData.update((data) => [
      ...data,
      this.#aIMetricsService.processMedicalAIMetrics(countQuality ? totalQuality / countQuality / 5 : -1, 'quality'),
    ]);

    this.aiMetricsFormattedData.update((data) => [
      ...data,
      this.#aIMetricsService.processMedicalAIMetrics(
        countConsistency ? totalConsistency / countConsistency / 5 : -1,
        'consistency',
      ),
    ]);

    this.aiMetricsFormattedData.update((data) => [...data, table]);
  }
}
