import { Pipe, PipeTransform } from '@angular/core';
/**
 * A pipe that transforms a string by capitalizing its first letter.
 *
 * Example usage:
 * ```
 * {{ 'hello' | capitalizeFirstLetter }}
 * ```
 */

@Pipe({
  name: 'capitalizeFirstLetter',
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  /**
   * Transforms a string by capitalizing its first letter.
   *
   * @param value The input string to be transformed.
   * @returns The transformed string with the first letter capitalized.
   */
  transform(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
