/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DocumentPreviewCarouselComponent } from 'src/app/modules/shared/component/document-preview-carousel/document-preview-carousel.component';
import { Attachment, TableSort } from 'src/app/modules/shared/interfaces/common.entities';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { VideoLobbyService } from '../../services/video-lobby.service';
/**
 * Interface representing a periodic element in the context of video lobby call recordings.
 * It describes the properties associated with each recording entry.
 */
export interface PeriodicElement {
  /**
   * Specifies whether the call was incoming or outgoing.
   */
  incomingOutgoing: string;
  /**
   * The name of the patient associated with the call recording.
   */
  patientName: string;
  /**
   * The date and time of the call recording.
   * Expected format: 'YYYY-MM-DD HH:mm:ss'
   */
  dateTime: string;
  /**
   * URL or path to the image associated with the patient or recording.
   */
  image: string;
  /**
   * URL or path to the actual recording file.
   */
  recording: string;
}
/**
 * Component for managing video lobby call recordings.
 *
 * This component displays a list of video call recordings with pagination,
 * sorting, and filtering options.
 */
@Component({
  selector: 'app-video-lobby-call-recordings',
  templateUrl: './video-lobby-call-recordings.component.html',
  styleUrl: './video-lobby-call-recordings.component.scss',
})
export class VideoLobbyCallRecordingsComponent implements OnInit {
  /**
   * List of columns to display in the table.
   */
  displayedColumns: string[] = ['patientName', 'dateTime', 'recording'];
  /**
   * Array to store the fetched video lobby call recordings.
   */
  videoLobbyData: RecordingData[] = [];
  /**
   * Array of column configurations including sorting and filtering options.
   */
  columns = [
    {
      /**
       * Unique identifier key for the column.
       */
      key: 'patientName',
      /**
       * Title displayed for the column in the UI.
       */
      title: 'Patient Name',
      /**
       * Key used to display the column's value from the data.
       */
      displayKey: 'patientName',
      /**
       * Indicates if the column can be sorted.
       */
      sort: true,
      /**
       * Key used for filtering data based on this column.
       */
      filterKey: 'patientName',
      /**
       * Configuration object for filtering options.
       */
      filter: {
        /**
         * Indicates if the filter icon should be displayed.
         */
        icon: false,
        /**
         * Indicates if the filter is currently active.
         */
        active: false,
      },
    },
    {
      /**
       * Unique identifier key for the column.
       */
      key: 'dateTime',
      /**
       * Title displayed for the column in the UI.
       */
      title: 'Date Time',
      /**
       * Key used to display the column's value from the data.
       */
      displayKey: 'dateTime',
      /**
       * Key used for filtering data based on this column.
       */
      filterKey: 'dateTime',
      /**
       * Key used for searching data based on this column.
       */
      searchKey: 'dateTime',
      /**
       * Indicates if the column can be sorted.
       */
      sort: true,
      /**
       * Configuration object for filtering options.
       */
      filter: {
        /**
         * Indicates if the filter icon should be displayed.
         */
        icon: false,
        /**
         * Indicates if the filter is currently active.
         */
        active: false,
      },
    },
    {
      /**
       * Unique identifier key for the column.
       */
      key: 'recording',
      /**
       * Title displayed for the column in the UI.
       */
      title: 'Recording',
      /**
       * Key used to display the column's value from the data.
       */
      displayKey: 'recording',
      /**
       * Key used for filtering data based on this column.
       */
      filterKey: 'name',
      /**
       * Key used for searching data based on this column.
       */
      searchKey: 'recording',
      /**
       * Indicates if the column can be sorted.
       */
      sort: true,
      /**
       * Configuration object for filtering options.
       */
      filter: {
        /**
         * Indicates if the filter icon should be displayed.
         */
        icon: false,
        /**
         * Indicates if the filter is currently active.
         */
        active: false,
      },
    },
  ];
  /**
   * Pagination options for controlling pagination state.
   */
  paginationOptions: PaginationOptions = {
    /**
     * The maximum number of items to display per page.
     */
    limit: 20,
    /**
     * The current page number.
     */
    currentPage: 1,
    /**
     * The total count of items available (optional).
     */
    totalCount: 0,
    /**
     * The filter criteria (optional).
     */
    filter: '',
    /**
     * The search criteria (optional).
     */
    search: '',
    /**
     * The sorting criteria, formatted as 'columnName|order' (e.g., 'patientName|DESC').
     */
    sort: 'patientName|DESC',
  };
  /**
   * Sorting criteria for data.
   */
  sorting = 'patientName|DESC';
  /**
   * Array to hold details of video lobby calls.
   */
  videoLobbyCallDetails = [];
  /**
   * Separator string used in data manipulation.
   */
  separator: string | undefined = ':';
  /**
   * Sorting object for specifying column and order.
   */
  public sort: TableSort | null = null;
  /**
   * Flag indicating if filtering is active.
   */
  filterStatus = false;
  /**
   * Constructor to inject services.
   * @param _VideoLobbyService Service for video lobby operations.
   * @param dialog MatDialog service for opening dialogs.
   * @param filterService Service for managing filters.
   */
  constructor(
    private _VideoLobbyService: VideoLobbyService,
    private dialog: MatDialog,
    private filterService: FilterService,
  ) {}

  /**
   * Retrieves the initial list of video call recordings.
   */
  ngOnInit(): void {
    this.getVideoCallList();
  }

  /**
   * Retrieves the list of video call recordings based on current pagination and sorting criteria.
   * Updates the component's `videoLobbyData` and `paginationOptions`.
   */
  getVideoCallList() {
    this._VideoLobbyService
      .getCallDetailsList(this.paginationOptions.limit, this.paginationOptions.currentPage, this.sort)
      .subscribe((res): void => {
        this.paginationOptions = res.pagination.pagination;
        this.videoLobbyData = res.data;
      });
  }

  /**
   * Retrieves the list of video call recordings based on pagination and sorting criteria.
   * Updates the component's `videoLobbyData` and `paginationOptions`.
   * @param id - Optional ID to filter call recordings by a specific identifier.
   */
  getVideoCallListById(id: string) {
    this._VideoLobbyService.getCallDetailsListId(id).subscribe((res): void => {
      this.paginationOptions = res.pagination.pagination;
      this.videoLobbyData = res.data;
    });
  }

  /**
   * Sets pagination options based on the provided event.
   * @param $event The event containing updated pagination options.
   */
  setPaginationOptions($event: PaginationOptions) {
    this.paginationOptions = $event;
    this.paginationOptions.limit = $event.limit;
    this.paginationOptions.currentPage = $event.currentPage;
  }

  /**
   * Converts seconds into a formatted time string (HH:mm:ss).
   * @param seconds The total number of seconds to convert.
   * @returns A formatted time string in the format HH:mm:ss.
   */
  convertTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60) - hours * 60;
    const remainingSeconds = seconds % 60;

    const formatted =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      remainingSeconds.toString().padStart(2, '0');

    return this.transform(formatted);
  }

  /**
   * Transforms a value into a valid time format (HH:mm:ss).
   * @param value The input value to transform.
   * @returns A valid time string in the format HH:mm:ss, or 'Invalid duration format' if the value is invalid.
   */
  transform(value: string): string {
    if (!value) return value;

    const match = value.match(/(\d+):(\d+):(\d+)/);
    if (match) {
      const [_, hours, minutes, seconds] = match;
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return 'Invalid duration format';
    }
  }

  /**
   * Opens a dialog to filter video lobby recordings based on search criteria.
   * @param $event The mouse event triggering the filter opening.
   */
  public openVideoLobbyFilter($event: MouseEvent): void {
    /**
     * Configuration object defining column settings for video lobby filtering.
     */
    const columnData = {
      /**
       * Key used for searching recordings.
       */
      searchKey: 'recordings',
      /**
       * Name associated with the search key.
       */
      searchKeyName: 'name',
      /**
       * Specifies if sorting is enabled.
       */
      sort: false,
      /**
       * Configuration for search functionality.
       */
      search: {
        /**
         * Specifies if the search icon is displayed.
         */
        icon: true,
        /**
         * Specifies if search functionality is active.
         */
        active: false,
      },

      /**
       * Configuration for filter functionality.
       */
      filter: {
        /**
         * Specifies if the filter icon is displayed.
         */
        icon: false,
        /**
         * Specifies if filtering is active.
         */
        active: false,
      },
    };

    /**
     * Body object containing configuration for opening search dialog.
     */
    const body = {
      /**
       * Configuration data for columns used in the search dialog.
       */
      column: columnData,
      /**
       * X-coordinate of the mouse event triggering the dialog.
       */
      pageX: $event.pageX,
      /**
       * Y-coordinate of the mouse event triggering the dialog.
       */
      pageY: $event.pageY,
      /**
       * Type of dialog operation, indicating search functionality.
       */
      type: 'search',
      /**
       * Flag indicating whether to check a label (not used in this context).
       */
      checkLabel: false,
    };
    /**
     * Opens a search dialog based on the provided configuration and handles search and filter events.
     * @param body Configuration object for opening the search dialog.
     */
    let dialog: any;
    if (body && body.type) {
      dialog = this.openSearchDialog(body);
      dialog.componentInstance.searchKeyUp.subscribe((keyword: string) => {
        this.getOptionsByKeyword(body, dialog, keyword);
      });
      dialog.componentInstance.clear.subscribe({
        next: (flag: boolean) => {
          if (flag) {
            // this.clear(flag);
          }
        },
      });
      dialog.afterClosed().subscribe({
        next: (response: unknown) => {
          if (response) {
            this.filterStatus = true;
            this._VideoLobbyService
              .getCallDetailsListFilter(
                this.paginationOptions.limit,
                this.paginationOptions.currentPage,
                this.sort,
                response,
              )
              .subscribe((res): void => {
                this.paginationOptions = res.pagination.pagination;
                this.videoLobbyData = res.data;
              });
          }
        },
      });
    }
    //   this.dialog.open(VideoLobbyFilterComponent, {
    //     data: {
    //       urlPath: ``,
    //       selectedPatient: '',
    //       filter: '',
    //     },
    //     disableClose: true,
    //   });
    // dialog.afterClosed().subscribe((): void => {
    // });
  }

  /**
   * Opens a search dialog for video lobby filtering.
   * @param $event Event data containing configuration for opening the dialog.
   * @returns Dialog instance returned by the filter service.
   */
  openSearchDialog($event: any) {
    // this is to open search dialog
    $event.search = this.filterService.getAppliedFiltersByKey($event.column.filterKey)?.name || '';
    return this.filterService.openSearchFilterDialog($event, 'videoLobby');
  }

  /**
   * Retrieves options based on keyword input for filtering.
   * @param $event Event data containing configuration for retrieving options.
   * @param dialog Dialog instance for interaction, optional.
   */
  getOptionsByKeyword($event: any, dialog: any, keyword: string) {
    this.paginationOptions.search = `${$event.column.searchKeyName}|${keyword}`;
    this.getOptionsByColumn($event, dialog);
  }

  /**
   * Retrieves options based on column configuration.
   * @param $event Event data containing configuration for retrieving options.
   * @param dialog Dialog instance for interaction, optional.
   */
  getOptionsByColumn($event: any, dialog?: any) {
    const tableName = `videoLobby/recordings`;
    const columnName =
      $event.type && $event.type.toLowerCase() === 'search' ? $event.column.searchKey : $event.column.filterKey;
    this.filterService.getOptionsByColumn(tableName, columnName, this.paginationOptions, this.separator, dialog, true);
  }

  /**
   * Sorts the video call list based on the specified column and order.
   * @param column Column name to sort by.
   * @param order Sorting order ('ASC' for ascending, 'DESC' for descending).
   */
  public sortColumn(column: string, order: 'ASC' | 'DESC'): void {
    this.sort = {
      column,
      order,
    };
    this.getVideoCallList();
  }

  /**
   * Opens a dialog to preview a document attachment.
   * @param document Attachment object representing the document to preview.
   */
  openDocumentPreviewDialog(document: Attachment) {
    const selectedAttachment = {
      type: 'videoLobby',
      url: document,
      icon: 'assets/images/icons/video.svg',
      title: 'video Lobby',
      mimeType: '',
    };
    this.dialog.open(DocumentPreviewCarouselComponent, {
      data: {
        attachments: [],
        selectedAttachment: selectedAttachment,
      },
    });
  }

  /**
   * Handles pagination events to update pagination options and fetch video call list accordingly.
   * @param event Page event containing pagination information (page size, current page index, etc.).
   */
  public paginationEvent(event: PageEvent): void {
    this.paginationOptions.limit = event.pageSize;
    this.paginationOptions.currentPage = event.pageIndex + 1;
    this.getVideoCallList();
  }
}

/**
 * Interface defining pagination options for managing data pagination.
 */
interface PaginationOptions {
  /**
   * Maximum number of items to display per page.
   */
  limit: number;
  /**
   * Total number of items across all pages (optional).
   */
  totalCount?: number;
  /**
   * Total number of pages (optional).
   */
  totalPage?: number;
  /**
   * Current page number.
   */
  currentPage: number;
  /**
   * Offset for pagination (optional).
   */
  offset?: number;
  /**
   * Page index for pagination (optional).
   */
  pageIndex?: number;
  /**
   * Total number of items (optional).
   */
  total?: number;
  /**
   * Sorting criteria (optional).
   */
  sort?: string;
  /**
   * Search criteria (optional).
   */
  search?: string;
  /**
   * Filter criteria (optional).
   */
  filter?: string;
  // query?: any;
}
/**
 * Interface representing data structure for a recording.
 */
interface RecordingData {
  /**
   * URL of the managed user's image associated with the recording.
   */
  managedUserImageUrl: string;
  /**
   * Full name of the managed user associated with the recording.
   */
  managedUserFullName: string;
  /**
   * Date when the recording took place.
   */
  date: Date;
  /**
   * Duration of the recording in seconds.
   */
  duration: number;
  /**
   * Array containing URLs or references to the recording files.
   */
  recording: string[];
  /**
   * Unique identifier of the recording.
   */
  id: string;
}
