import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { SharedModule } from 'src/app/modules/shared/shared.module';

export interface UserGrpList {
  id: string;
  name?: string;
  role?: string;
  defualtUserGrp?: boolean;
  value?: string;
}

export interface userGrpControl {
  default?: boolean;
  id: string;
  name?: string;
  role: string;
  value?: boolean;
}

@Component({
  selector: 'app-add-edit-user-group',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './add-edit-user-group.component.html',
  styleUrl: './add-edit-user-group.component.scss',
})
export class AddEditUserGroupComponent implements OnInit, OnChanges {
  @Input() userGrpList: Array<UserGrpList> = [];

  // @Input('userType') userType !:FormControl

  @Input() selectedUserGrpList?: Array<UserGrpList> = [];

  @Input() userType: string = '';

  @Input() submitted = false;

  search = new FormControl('');

  selectAllCheckbox = new FormControl(false);

  userGrpEditForm!: FormGroup;

  indeterminateSelectAll = false;

  /** Options for user group types */
  userGroupTypes: Array<{ value: string; viewValue: string }> = [
    { value: 'Member', viewValue: 'Member' },
    { value: 'CoOwner', viewValue: 'Co-owner' },
    { value: 'Observer', viewValue: 'Observer' },
    { value: 'owner', viewValue: 'Owner' },
  ];

  @Output() filterUserGrpKey: EventEmitter<string> = new EventEmitter<string>();

  @Output('search') searchKey: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  constructor(private fb: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userGrpList && changes.userGrpList.previousValue != undefined) {
      this.userGrpList = changes.userGrpList.currentValue;
      this.createUserGrpControls();
    }

    if (changes.userType && changes.userType.previousValue != undefined) {
      this.userType = changes.userType.currentValue;
      this.disableInputs();
    }
  }

  get userGroups(): FormArray {
    return this.userGrpEditForm.controls['userGroupListForm'] as FormArray;
  }

  get selectedUserGrp() {
    return this.userGrpEditForm.controls['selectedUserGroupListForm'] as FormArray;
  }
  ngOnInit(): void {
    this.userGrpEditForm = this.fb.group({
      userGroupListForm: new FormArray([]),
      selectedUserGroupListForm: new FormArray([]),
    });

    this.createUserGrpControls();

    this.userGroups.valueChanges.subscribe((res: Array<userGrpControl>) => {
      console.log(res);
      if (res.length == 0) this.selectAllCheckbox.setValue(false);
      if (res.length > 0 && res.every((ele: userGrpControl) => ele.value == true)) {
        this.indeterminateSelectAll = false;
        this.selectAllCheckbox.setValue(true);
      } else if (res.length > 0 && res.every((ele: userGrpControl) => ele.value == false)) {
        this.selectAllCheckbox.setValue(false);
        this.indeterminateSelectAll = false;
      } else if (res.length > 0 && res.some((ele: userGrpControl) => ele.value == true)) {
        this.indeterminateSelectAll = true;
      }
    });

    this.search.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000)).subscribe((res) => {
      if (res !== undefined) {
        this.searchKey.emit(res || '');
      }
    });
  }

  createUserGrpControls() {
    this.userGroups.controls = [];
    this.selectedUserGrp.controls = [];
    this.userGrpList?.forEach((ele) => {
      this.userGroups.push(this.createFormGrp(ele));
    });
    this.selectedUserGrpList?.forEach((ele) => {
      const formGrp = this.createFormGrp(ele);
      if (ele?.role === 'owner') {
        formGrp.get('value')?.disable();
        formGrp.get('role')?.disable();
        formGrp.get('value')?.updateValueAndValidity();
        formGrp.get('role')?.updateValueAndValidity();
      }
      this.selectedUserGrp.push(formGrp);
    });
    this.disableInputs();
  }

  createFormGrp(data: UserGrpList) {
    return this.fb.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name + (data.defualtUserGrp ? ' (Default Group)' : '')),
      role: new FormControl(data?.role ? data.role : ''),
      value: new FormControl(false),
      default: new FormControl(data.defualtUserGrp),
    });
  }

  selectAll(eve: MatCheckboxChange) {
    this.userGroups.controls.forEach((ele) => {
      ele.get('value')?.setValue(eve.checked);
    });
  }

  filterUserGrp(text: string) {
    this.filterUserGrpKey.emit(text);
  }

  anySelectedInLeftList() {
    const filteredControl = this.userGroups.controls.filter((ele) => ele.get('value')?.value === true);
    return filteredControl.length > 0 ? false : true;
  }

  anySelectedInRightList() {
    const filteredControl = this.selectedUserGrp.controls.filter((ele) => ele.get('value')?.value === true);
    return filteredControl.length > 0 ? false : true;
  }

  moveRight() {
    const filteredControl = this.userGroups.controls.filter((ele) => ele.get('value')?.value === true);
    filteredControl.forEach((ele) => {
      const index = this.userGroups.value.findIndex((element: UserGrpList) => element.id === ele.value.id);
      ele.get('value')?.setValue('');
      ele.get('role')?.setValidators(Validators.required);
      this.selectedUserGrp.updateValueAndValidity();
      this.selectedUserGrp.controls.push(ele);

      this.userGroups.controls.splice(index, 1);
      this.userGroups.updateValueAndValidity();
    });
    if (this.userType !== '') {
      this.disableInputs();
    }
  }

  moveLeft() {
    const filteredControl = this.selectedUserGrp.controls.filter((ele) => ele.get('value')?.value === true);
    filteredControl.forEach((ele) => {
      const index = this.selectedUserGrp.value.findIndex((element: UserGrpList) => element.id === ele.value.id);
      this.selectedUserGrp.controls.splice(index, 1);
      this.selectedUserGrp.updateValueAndValidity();
      ele.get('value')?.setValue('');
      this.userGroups.controls.push(ele);
    });
  }

  disableInputs() {
    (<FormArray>this.userGrpEditForm.get('selectedUserGroupListForm')).controls.forEach((control) => {
      if (this.userType.toLowerCase() === 'managed') {
        control.get('role')?.setValue('Member');
        control.get('role')?.disable({ onlySelf: true });
      } else {
        // control.get('role')?.setValue('');
        if (control.get('role')?.value == 'owner') {
          control.get('role')?.disable();
        } else {
          control.get('role')?.enable();
        }
        // control.get('role')?.enable();
        control.get('role')?.setValidators(Validators.required);
      }
      control.updateValueAndValidity();
    });
  }

  returnSelectedUserGrps() {
    this.submitted = true;
    this.selectedUserGrp.enable({ onlySelf: true });
    if (this.selectedUserGrp.valid) {
      const selectedUserGrps = JSON.parse(JSON.stringify(this.selectedUserGrp.value));
      selectedUserGrps.forEach((ele: userGrpControl) => {
        delete ele.name;
        delete ele.value;
        delete ele.default;
      });
      return selectedUserGrps;
    }
  }
}
