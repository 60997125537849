<div class="dialog-xl">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'callDetails' | translate }}</h2>
    <span class="dialog-header-icons">
      <i-feather name="x" mat-dialog-close class="pointer"></i-feather>
    </span>
  </div>
  <mat-dialog-content>
    <div class="table-wrapper">
      <div class="table-responsive">
        <table mat-table [dataSource]="videoLobbyData">
          <ng-container matColumnDef="incomingOutgoing">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ '' }}
              <i-feather name="phone-incoming" class="feather-20"></i-feather></td
          ></ng-container>
          <ng-container matColumnDef="patientName">
            <th
              class="min-w-220"
              mat-header-cell
              *matHeaderCellDef
              [ngClass]="{ 'active-cell': sort?.column === 'patientName' }"
            >
              <span>{{ 'patientFullName' | translate }}</span>
              <span class="position-relative" (click)="openVideoLobbyFilter($event)">
                <i-feather name="search" class="feather-20 pointer"></i-feather>
                <span class="filter-status-badge" [ngClass]="filterStatus ? 'inactive-badge' : ''"></span>
              </span>
              <span class="tbl-sort">
                <i-feather
                  name="chevron-up"
                  class="feather-20"
                  (click)="sortColumn('patientName', 'DESC')"
                  [ngClass]="{ 'active-sort': sort?.order === 'DESC' }"
                ></i-feather>
                <i-feather
                  name="chevron-down"
                  class="feather-20"
                  (click)="sortColumn('patientName', 'ASC')"
                  [ngClass]="{ 'active-sort': sort?.order === 'ASC' }"
                ></i-feather>
              </span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="profile-cell hr-col active-user">
                <div class="position-relative">
                  <img class="user-icon" [src]="element.managedUserImageUrl" alt="Patient Profile Picture" onErrorImg />
                  <span class="status-badge"></span>
                </div>
                <span>{{ element.managedUserFullName || '-' }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="dateTime">
            <th class="min-w-220" mat-header-cell *matHeaderCellDef>{{ 'dateTime' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              {{ (element.date | date: 'MMM d, y, h:mm:ss a') || '-' }}
              <br />
              <span>
                <strong>{{ 'duration' | translate }}</strong> {{ convertTime(element.duration) || '-' }}</span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="recording">
            <th
              class="min-w-220"
              mat-header-cell
              *matHeaderCellDef
              [ngClass]="{ 'active-cell': sort?.column === 'recording' }"
            >
              {{ 'recording' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="action-icons action text-center">
                <ng-container *ngFor="let tag of element.recording">
                  <i-feather
                    *ngIf="tag !== ''"
                    name="play"
                    class="feather-20 pointer"
                    (click)="openDocumentPreviewDialog(tag)"
                  ></i-feather>
                </ng-container>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        class="docs-paginator document-pagination"
        [length]="paginationOptions.totalCount"
        [pageSize]="paginationOptions.limit"
        (page)="paginationEvent($event)"
        [pageSizeOptions]="[5, 10, 15, 20]"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </mat-dialog-content>
</div>

<!-- <app-table [columns]="columns" [displayedColumns]="displayedColumns" [paginationOptions]="paginationOptions"
[data]="videoLobbyCallDetails"   
 ></app-table> -->
