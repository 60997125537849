<div class="page-header">
  <h1>{{ 'addUser' | translate }}</h1>
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/groupManagment/list']">{{ 'userGroupManagement' | translate }}</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groupManagment/view']" [queryParams]="{ clientId: clientId, gid: gId }"
          >{{ selectedGroup }}
        </a>
      </li>
      <li class="breadcrumb-item active">{{ 'addUsers' | translate }}</li>
    </ol>
  </nav>
</div>
<div class="page-content-wrapper">
  <div class="card-base">
    <div class="card-titlebar1 card-titlebar-one">
      <div class="filter-box row">
        <div class="filter-label col-lg-4">
          <span class="pe-1">{{ 'filterBy' | translate }}:</span>
          <span class="pe-0 pointer" [matMenuTriggerFor]="userMatMenu">
            <span> {{ selectedFilterByOption.label || 'All clients' }}</span>
            <i-feather name="chevron-down" class="feather-16"></i-feather>
          </span>
        </div>
        <mat-menu #userMatMenu="matMenu" class="mat-more-dropdown">
          <a mat-menu-item (click)="onChangeFilterOptions(fOption)" *ngFor="let fOption of filterOptions">
            {{ fOption.displayKey ? (fOption.displayKey | translate) : fOption.displayKey }}
          </a>
        </mat-menu>
        <div class="col-lg-8 align-btn">
          <div class="card-header-icons mb-0">
            <i-feather name="check" [ngClass]="{ disabled: !selectedUserIds.length }" (click)="submit()"></i-feather>
            <i-feather
              name="x"
              [routerLink]="['/groupManagment/view']"
              [queryParams]="{ clientId: clientId, gid: gId }"
            ></i-feather>
          </div>
        </div>
      </div>
    </div>
    <app-table
      class="prov-table-size"
      [columns]="columns"
      [showCheckbox]="showCheckBox"
      [displayedColumns]="displayedColumns"
      [paginationOptions]="paginationOptions"
      [data]="users"
      (refetch)="refreshUsers($event)"
      (sort)="sortByColumn($event)"
      (search)="openSearchAndFiltersDialog($event)"
      (filter)="openSearchAndFiltersDialog($event)"
      (clear)="clear($event)"
      (selectedUserIds)="setSelectedUserIds($event)"
    ></app-table>
  </div>
</div>
