<div class="dialog-xl">
  <div
    class="hs-tab-card-title flex-between"
    [ngClass]="{ 'hide-section': messageDetailMobile || mobilePatientSummary }"
  >
    <h2>{{ 'Messages' | translate }}</h2>
    <div class="dialog-header-icons">
      <i-feather
        name="edit"
        class="pointer"
        *appPermissions="'self.Chat.Write'"
        (click)="openNewMessageThreadDialog()"
      ></i-feather>
      <i-feather name="x" class="pointer" (click)="closeDialog()"></i-feather>
    </div>
  </div>

  <div class="message-outer-wrapper" [ngClass]="{ 'mob-h': messageDetailMobile }">
    <div class="message-tab-list-label" [ngClass]="{ 'hide-section': messageDetailMobile || mobilePatientSummary }">
      <h3
        [ngClass]="{ 'active-list': mode === 'MyMessages' }"
        (click)="refreshThreadsMetaData('MyMessages', true, 'ResetFilter')"
      >
        {{ 'myMessages' | translate }}
      </h3>
      <h3
        [ngClass]="{ 'active-list': mode === 'MyTeamMessages' }"
        (click)="refreshThreadsMetaData('MyTeamMessages', true, 'ResetFilter')"
        *appPermissions="'others.Chat.Read'"
      >
        {{ 'myTeamMessages' | translate }}
      </h3>
    </div>

    <div #threadWrapper class="message-wrapper" [ngClass]="{ 'position-relative': !threadList.length }">
      <div class="message-left-col" [ngClass]="{ 'hide-section': messageDetailMobile || mobilePatientSummary }">
        <div class="message-subheader flex-between">
          <div class="form-group search-input search-100 feather-reset">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'search' | translate }}"
              [formControl]="messageSearch"
              (keyup)="searchThreads($event)"
            />
            <i-feather
              name="x-circle"
              class="feather-16 pointer search-close-icon"
              *ngIf="messageSearch.value"
              (click)="messageSearch.setValue('')"
            ></i-feather>
            <i-feather
              name="filter"
              class="feather-20 icon-in-input clr-secondary pointer"
              (click)="openFilter()"
            ></i-feather>
          </div>
        </div>
        <div #messageFilters class="message-result">
          <div class="message-result-item" *ngIf="chatThreadFilter.subject">
            <div class="message-result-text">{{ 'subject' | translate }}: {{ chatThreadFilter.subject }}</div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('subject')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.message">
            <div class="message-result-text">{{ 'body' | translate }}: {{ chatThreadFilter.message }}</div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('message')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.from">
            <div class="message-result-text">{{ 'from' | translate }}: {{ chatThreadFilter.from.userFullName }}</div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('from')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.to">
            <div class="message-result-text">{{ 'to' | translate }}: {{ chatThreadFilter.to.userFullName }}</div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('to')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.startDate && chatThreadFilter.endDate">
            <div class="message-result-text">
              {{ 'date' | translate }}: {{ chatThreadFilter.startDate | date: 'shortDate' }} -
              {{ chatThreadFilter.endDate | date: 'shortDate' }}
            </div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('startDate')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.startDate && !chatThreadFilter.endDate">
            <div class="message-result-text">
              {{ 'startDate' | translate }}: {{ chatThreadFilter.startDate | date: 'shortDate' }}
            </div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('startDate')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="!chatThreadFilter.startDate && chatThreadFilter.endDate">
            <div class="message-result-text">
              {{ 'endDate' | translate }}: {{ chatThreadFilter.endDate | date: 'shortDate' }}
            </div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('endDate')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.unread && mode === 'MyMessages'">
            <div class="message-result-text">{{ 'unread' | translate }}</div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('unread')"></i-feather>
          </div>
          <div class="message-result-item" *ngIf="chatThreadFilter.unhandled && mode === 'MyTeamMessages'">
            <div class="message-result-text">{{ 'notReviewed' | translate }}</div>
            <i-feather name="x-circle" class="feather-16" (click)="removeFilter('unhandled')"></i-feather>
          </div>
        </div>
        <div class="message-list" (click)="messageDetailMobile = true">
          <div
            class="message-item pointer"
            *ngFor="let thread of threadList"
            [ngClass]="{ 'selected-thread': selectedThread && thread.threadId === selectedThread.threadId }"
            (click)="openThreadWithLoader(thread)"
          >
            <div class="message-icon"><img src="assets/images/icons/user.png" alt="message-user" /></div>
            <div class="message-item-text">
              <div class="title-date flex-between">
                <span class="message-item-title">{{ thread.topic }}</span>
                <div class="message-info">
                  <!-- <button class="btn btn-primary broadcast">Broadcast</button> -->
                  <span class="message-item-date">
                    {{ thread.lastMessage.date | dateTime: ' | ' }}
                  </span>
                </div>
              </div>
              <div class="sidebar-msg-body">
                <div class="sidebar-msg-text">
                  {{ thread.lastMessage.displayName }}: {{ thread.lastMessage.message }}
                </div>
                <div class="sidebar-msg-icons">
                  <span class="unread-count" *ngIf="mode === 'MyMessages' && thread.unreadCount">
                    {{ thread.unreadCount }}
                  </span>
                  <span class="unread-count" *ngIf="mode === 'MyTeamMessages' && thread.requiresAttention"></span>
                </div>
              </div>
            </div>
          </div>
          <div #threadLoader *ngIf="threadList.length"></div>
        </div>
      </div>

      <div class="message-details" *ngIf="selectedThread" [ngClass]="{ 'show-section': messageDetailMobile }">
        <div class="back-arrow view-below-tab">
          <i-feather name="arrow-left" class="feather-20 pointer" (click)="messageDetailMobile = false"></i-feather>
        </div>
        <div class="message-detail-area" [ngClass]="{ 'my-team': mode === 'MyTeamMessages' }">
          <div class="message-user">
            <ng-container *ngIf="mode === 'MyMessages' && selectedThread.recipients.length > 2">
              <div class="leave-conversation" *appPermissions="'self.Chat.Delete'" (click)="removeParticipant()">
                <i-feather name="log-out" class="feather-20"></i-feather>
                <span>{{ 'leaveConversation' | translate }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="mode === 'MyTeamMessages'">
              <div class="leave-conversation" *appPermissions="'others.Chat.Update'" (click)="joinConversation()">
                <i-feather name="log-in" class="feather-20"></i-feather>
                <span>{{ 'joinConversation' | translate }}</span>
              </div>
            </ng-container>

            <h3>
              <span ngbTooltip="{{ selectedThread.topic }}">{{ selectedThread.topic }}</span>
            </h3>
            <div class="msg-user-name-icon" [ngClass]="{ pointer: mode === 'MyMessages' }" (click)="openContacts()">
              <i-feather name="users" class="feather-20 msg-user-icon"></i-feather>
              <span class="msg-user-name">{{ participantListString() }}</span>
            </div>
          </div>
          <div
            class="message-history"
            [ngClass]="{
              'with-attachments': attachments.length,
              'no-write-permission': !hasWritePermission,
              'with-suggested-message': showSuggestedMessage,
              'with-reset': showResetButton
            }"
          >
            <div class="message-history-inner" [ngClass]="{ 'message-with-summary': showPatientSummary }">
              <ng-container *ngIf="chatMessages.length">
                <app-message-ui
                  *ngFor="let chatMessage of chatMessages"
                  [chatMessage]="chatMessage"
                  [threadId]="selectedThread.threadId"
                  [refreshMessage$]="refreshMessage$"
                  [mode]="mode"
                  (newThreadCreated)="addNewThread($event)"
                ></app-message-ui>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="mode === 'MyMessages'">
            <div class="message-footer" *appPermissions="'self.Chat.Write'">
              <div class="typing-indicator-text" *ngIf="typingEvent">
                <strong>{{ typingEvent.senderDisplayName }}</strong> {{ 'isTyping' | translate }}...
              </div>
              <div class="message-footer-main">
                <input type="file" #fileInput id="file" class="d-none" multiple (change)="onFileSelected($event)" />
                <i-feather name="paperclip" (click)="fileInput.click()"></i-feather>
                <i-feather name="camera" (click)="openCamera()"></i-feather>
                <i-feather name="file-text" (click)="openTemplates()"></i-feather>
                <i-feather
                  name="suggested-message"
                  class="suggested-message-icon"
                  (click)="showOrHideSuggestedMessage(selectedThread)"
                ></i-feather>
                <div class="message-editor" [ngClass]="{ 'reset-editor': showResetButton }">
                  <ckeditor
                    class="form-control message-ck-editor"
                    [ngClass]="{ 'editor-with-reset': showResetButton }"
                    [editor]="ckEditor"
                    [config]="ckEditorConfig"
                    [(ngModel)]="ckEditorData"
                    (change)="message$.next(true)"
                  >
                  </ckeditor>
                  <ng-container *appPermissions="'ai.SuggestedHcpReplies.Read'">
                    <button class="btn btn-primary" *ngIf="showResetButton" (click)="resetMessage()">
                      {{ 'reset' | translate }}
                    </button>
                  </ng-container>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="fileUploadsInProgress || !ckEditorData.trim()"
                  (click)="sendMessage()"
                >
                  {{ 'send' | translate }}
                </button>
              </div>
              <div class="attachment-icons" *ngIf="attachments.length">
                <div class="attachment-icons-item feather-reset" *ngFor="let attachment of attachments; index as i">
                  <i-feather
                    name="x-circle"
                    class="attachment-cancel"
                    (click)="removeFile(i, attachment.documentId)"
                  ></i-feather>
                  <img [src]="attachment.fileIcon" alt="img" />
                  <mat-progress-spinner
                    class="attachment-icons-spinner"
                    [mode]="'determinate'"
                    [value]="attachment.uploadProgress"
                    strokeWidth="4"
                    diameter="40"
                    *ngIf="attachment.uploadProgress"
                  >
                  </mat-progress-spinner>
                </div>
              </div>
              <ng-container *appPermissions="'ai.SuggestedHcpReplies.Read'">
                <div class="suggested-message-wrapper" *ngIf="showSuggestedMessage">
                  <app-suggested-message
                    [patientId]="patientUserId()"
                    [mode]="'Thread'"
                    (selectedSuggestion)="useSuggestion($event)"
                  ></app-suggested-message>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *appPermissions="'ai.PatientSummarization.Read'">
        <ng-container *appPermissions="'others.ObservationSummary.Read'">
          <div
            class="message-right-col"
            [ngClass]="{
              'right-col-transition': mode === 'MyMessages',
              'summary-animation': showPatientSummary,
              'show-section': messageDetailMobile || mobilePatientSummary
            }"
          >
            <h3 class="patient-summary-title summary-ls">
              <span class="patient-summary-title-text">{{ 'recentObservationSummary' | translate }}</span>
              <img class="beta-badge" src="assets/images/icons/beta-icon.svg" alt="beta" />
              <i-feather class="feather-20 pointer" name="refresh-cw" (click)="reloadObservationSummary()"></i-feather>
            </h3>
            <!-- mi - start mobile and ipad only -->
            <h3
              class="patient-summary-title pointer view-below-tab"
              (click)="toggleMobilePatientSummary()"
              *ngIf="!mobilePatientSummary"
            >
              <span class="patient-summary-title-text">{{ 'recentObservationSummary' | translate }}</span>
              <img class="beta-badge" src="assets/images/icons/beta-icon.svg" alt="beta" />
              <i-feather name="chevron-right"></i-feather>
            </h3>
            <div class="hs-tab-card-title justify-content-end view-below-tab" *ngIf="mobilePatientSummary">
              <i-feather name="x" class="pointer" (click)="toggleMobilePatientSummary()"></i-feather>
            </div>
            <!-- mi - end -->
            <app-observation-summarization
              [patientId]="patientUserId()"
              [ngClass]="{ 'mobile-patient-summary': mobilePatientSummary }"
            ></app-observation-summarization>
          </div>
        </ng-container>
      </ng-container>
      <div class="no-threads" *ngIf="!threadList.length">
        {{ 'noThreads' | translate }}
      </div>
    </div>
  </div>
</div>
