<div class="title-bar page-info">
  <h1 *ngIf="clientId && !uid">{{ 'userManageMent' | translate }}</h1>
  <h1 *ngIf="clientId && uid">{{ 'userManageMent' | translate }}</h1>
  <h1 *ngIf="!clientId">{{ 'addSystemUser' | translate }}</h1>
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item pointer">
        <a (click)="onClickBreadcrumbMenu(clientId ? '/userManagment/list' : 'userManagment/list')">{{
          'userManageMent' | translate
        }}</a>
      </li>
      <li class="breadcrumb-item pointer" *ngIf="clientId && uid">
        <a (click)="onClickBreadcrumbMenu('/userManagment/profile')">{{ 'userProfile' | translate }}</a>
      </li>
      <li *ngIf="clientId && uid" class="breadcrumb-item active">Edit User{{ 'userProfile' | translate }}</li>
      <li *ngIf="clientId && !uid" class="breadcrumb-item active">{{ 'addUSer' | translate }}</li>
      <li *ngIf="!clientId" class="breadcrumb-item active">{{ 'addSystemUser' | translate }}</li>
    </ol>
  </nav>
</div>
<div>
  <form [formGroup]="addUserFormGroup">
    <div class="row">
      <div class="col-md-6">
        <div class="card-base">
          <div class="flex-between mcard-header">
            <h1>{{ 'titleProfile' | translate }}</h1>
            <div class="card-header-icons mb-0">
              <i-feather name="check" (click)="submit()"></i-feather>
              <i-feather name="x" (click)="redirectToUsers()"></i-feather>
            </div>
          </div>
          <fieldset [class.disabled]="uid">
            <div class="form-group">
              <label for="Affiliation Code" required>User Type</label>
              <div class="d-flex">
                <div class="form-check-flex me-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="userType"
                    value="Managed"
                    (click)="addRemovedAffiliationValidation('Managed')"
                  />
                  <label class="check-flex-label" for="Managed"> {{ 'Managed' | clientLabel }} </label>
                </div>
                <div class="form-check-flex">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="userType"
                    value="Manager"
                    (click)="addRemovedAffiliationValidation('Manager')"
                  />
                  <label class="check-flex-label" for="Manager"> {{ 'Manager' | clientLabel }} </label>
                </div>
              </div>
              <div *ngIf="submitted && formControls?.userType?.errors" class="invalid-feedback">
                <div *ngIf="formControls?.userType?.errors?.required" class="danger">
                  {{ 'userTypeIsRequired' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-select-arrow">
                <label for="Affiliation Code" required>{{ 'affiliationCode' | translate }}</label>
                <select
                  class="form-control"
                  formControlName="affiliationCode"
                  (change)="onChangeAffiliationCode()"
                  [ngClass]="{
                    'is-invalid': submitted && formControls.affiliationCode.errors,
                    disabled: formControls.userType.value.toLowerCase() === ''
                  }"
                >
                  <option disabled value="">{{ 'selectOption' | translate }}</option>
                  <option *ngFor="let affiliationCodeO of affiliationCodes" [ngValue]="affiliationCodeO.code">
                    {{ affiliationCodeO.code }}
                  </option>
                </select>
                <div *ngIf="submitted && formControls?.affiliationCode?.errors" class="invalid-feedback">
                  <div *ngIf="formControls?.affiliationCode?.errors?.required" class="danger">
                    {{ 'affliationCodeValidationMessage' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h3 class="pb-1">{{ 'userNameAndPassword' | translate }}</h3>
            <div [class.disabled]="uid">
              <div class="form-group mb-3">
                <label for="" class="form-label">{{ 'username' | translate }}*</label>
                <input
                  type="text"
                  class="form-control"
                  (keydown.space)="$event.preventDefault()"
                  minlength="4"
                  formControlName="userName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.userName.errors }"
                />
                <div *ngIf="submitted && formControls.userName.errors" class="invalid-feedback">
                  <div *ngIf="formControls.userName.errors.required" class="danger">
                    {{ 'usernameRequiredValidationMessage' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group-inline">
                <div class="form-group">
                  <label for="" class="form-label">{{ 'password' | translate }}*</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    [ngClass]="{
                      'is-invalid': (submitted || formControls.password.touched) && formControls.password.errors
                    }"
                  />
                  <div
                    *ngIf="(submitted || formControls.password.touched) && formControls.password.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="formControls.password.errors.required">
                      {{ 'passwordRequiredValidationMessage' | translate }}
                    </div>
                    <div
                      *ngIf="
                        (submitted || formControls.password.touched) &&
                        (formControls.password.errors?.minlength?.actualValue !== 8 ||
                          formControls.password.errors?.['pattern'])
                      "
                      class="danger"
                    >
                      {{ 'enterMinimum8CharsWithAtleastANumberLowerUpperChar' | translate }}.
                    </div>
                  </div>
                </div>
                <div class="form-group mt-0">
                  <label for="" class="form-label">{{ 'confirmPassword' | translate }}*</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="cPassword"
                    [ngClass]="{
                      'is-invalid': (submitted || formControls.cPassword.touched) && formControls.cPassword.errors
                    }"
                  />
                  <div
                    *ngIf="(submitted || formControls.cPassword.touched) && formControls.cPassword.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="formControls.cPassword.errors.required">
                      {{ 'confirmPasswordRequiredValidationMessage' | translate }}
                    </div>
                    <div *ngIf="formControls.cPassword.errors.mustMatch">{{ 'passwordsMismatch' | translate }}</div>
                    <div
                      *ngIf="
                        (submitted || formControls.cPassword.touched) &&
                        (formControls.cPassword.errors?.minlength?.actualValue !== 8 ||
                          formControls.cPassword.errors?.['pattern'])
                      "
                      class="danger"
                    >
                      {{ 'enterMinimum8CharsWithAtleastANumberLowerUpperChar' | translate }}.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check-flex form-group">
                <input type="checkbox" class="form-check-input" id="psdcg" formControlName="requirePasswordChange" />
                <label for="psdcg">{{ 'requirePasswordChange' | translate }}</label>
              </div>
            </div>
            <div class="form-group" *ngIf="this.uid">
              <label for="">{{ 'status' | translate }}*</label>
              <div class="custom-select-arrow">
                <select
                  class="form-control"
                  formControlName="status"
                  [ngClass]="{ 'is-invalid': submitted && formControls.status.errors }"
                >
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  <option value="Active">{{ 'active' | translate }}</option>
                  <option value="InActive">{{ 'inactive' | translate }}</option>
                </select>
              </div>
              <div *ngIf="submitted && formControls.status.errors" class="invalid-feedback">
                <div *ngIf="formControls.status.errors.required">
                  {{ 'statusIsRequired' | translate }}
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h3 class="pb-1">{{ 'personalInformation' | translate }}</h3>
            <div class="form-group-inline">
              <div
                class="form-group suffix"
                *ngIf="formControls?.userType?.value && formControls?.userType?.value.toLowerCase() === 'manager'"
              >
                <label for="">{{ 'suffix' | translate }}</label>
                <select class="form-control" formControlName="suffix">
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  <option *ngFor="let suffix of suffixes" value="{{ suffix }}">
                    {{ suffix }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="" class="form-label">{{ 'firstName' | translate }}*</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  trim
                  [ngClass]="{ 'is-invalid': submitted && formControls.firstName.errors }"
                />
                <div *ngIf="submitted && formControls.firstName.errors" class="invalid-feedback">
                  <div *ngIf="formControls.firstName.errors.required" class="danger">
                    {{ 'firstNameValidationMessage' | translate }}
                  </div>
                  <div
                    *ngIf="
                      submitted &&
                      (formControls.firstName.errors?.['firstName'] || formControls.firstName.errors?.['pattern'])
                    "
                    class="danger"
                  >
                    {{ 'invalidFirstName' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="" class="form-label">{{ 'lastName' | translate }}*</label>
                <input
                  type="text"
                  class="form-control"
                  trim
                  formControlName="lastName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.lastName.errors }"
                />
                <div *ngIf="submitted && formControls.lastName.errors" class="invalid-feedback">
                  <div *ngIf="formControls.lastName.errors.required" class="danger">
                    {{ 'lastNameValidationMessage' | translate }}
                  </div>
                  <div
                    *ngIf="
                      submitted &&
                      (formControls.lastName.errors?.['lastName'] || formControls.lastName.errors?.['minLength'])
                    "
                    class="danger"
                  >
                    {{ 'firstNameMinLength1Required' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-select-arrow">
                <label for="">{{ 'sex' | translate }}*</label>
                <select
                  class="form-control sm-50"
                  formControlName="sex"
                  [ngClass]="{ 'is-invalid': submitted && formControls.sex.errors }"
                >
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  <option value="male">{{ 'male' | translate }}</option>
                  <option value="female">{{ 'female' | translate }}</option>
                  <option value="unknown">{{ 'unknown' | translate }}</option>
                </select>
                <div *ngIf="submitted && formControls.sex.errors" class="invalid-feedback">
                  <div *ngIf="formControls.sex.errors.required" class="danger">
                    {{ 'genderRequiredValidationMessage' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group"
              *ngIf="formControls?.userType?.value && formControls?.userType?.value.toLowerCase() === 'managed'"
            >
              <app-dob
                [dob]="user.demographics?.birthDate"
                (updateDob)="updateDOB($event)"
                (errorCode)="errorCallbackDOB($event)"
              ></app-dob>
            </div>
            <div
              class="form-group"
              *ngIf="formControls?.userType?.value && formControls?.userType?.value.toLowerCase() === 'managed'"
            >
              <label for="">{{ 'Manager' | clientLabel }}*</label>

              <select
                class="form-control"
                formControlName="manager"
                [ngClass]="{ 'is-invalid': submitted && formControls.manager.errors }"
              >
                <option value="" disabled>{{ 'selectOption' | translate }}</option>
                <option *ngFor="let manager of managers" [ngValue]="manager.id">
                  {{ manager.name }}
                </option>
              </select>
              <div *ngIf="submitted && formControls.manager.errors" class="invalid-feedback">
                <div *ngIf="formControls.manager.errors.required" class="danger">
                  {{ 'managerIsRequired' | translate }}
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h3 class="pb-1">{{ 'contactInformation' | translate }}</h3>
            <label for="" class="form-label disabled mb-3">{{ 'oneFieldRequired' | translate }}</label>
            <div class="form-group-inline">
              <div class="form-group">
                <label for="" class="form-label">{{ 'mobilePhone' | translate }}*</label>
                <input
                  type="text"
                  digitOnly
                  trim
                  class="form-control phone sm-50"
                  formControlName="phone"
                  minlength="10"
                  maxlength="10"
                  [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }"
                />
                <div *ngIf="submitted && formControls.phone.errors" class="invalid-feedback">
                  <div *ngIf="formControls.phone.errors.required" class="danger">
                    {{ 'mobilePhoneValidationMessage' | translate }}
                  </div>
                  <div *ngIf="formControls.phone.errors.optionalMatch" class="danger">
                    {{ 'validMobilePhoneIsRequired' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="" class="form-label">{{ 'emailAddress' | translate }}*</label>
                <input
                  type="email"
                  class="form-control"
                  trim
                  formControlName="emailAddress"
                  [ngClass]="{ 'is-invalid': submitted && formControls.emailAddress.errors }"
                />
                <div *ngIf="submitted && formControls.emailAddress.errors" class="invalid-feedback">
                  <div *ngIf="formControls.emailAddress.errors.required" class="danger">
                    {{ 'emailAddressIsRequired' | translate }}
                  </div>
                  <div *ngIf="formControls.emailAddress.errors.optionalMatch" class="danger">
                    {{ 'validEmailAddressIsRequired' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="formControls?.userType?.value && formControls?.userType?.value.toLowerCase() === 'manager'"
            >
              <label for="" class="form-label">{{ 'workPhone' | translate }}</label>
              <input
                type="tel"
                digitOnly
                class="form-control phone sm-50"
                minlength="10"
                maxlength="10"
                formControlName="workPhone"
              />
            </div>
          </fieldset>

          <fieldset>
            <h3 class="pb-1">{{ 'systemSettings' | translate }}</h3>
            <div class="form-group">
              <div class="custom-select-arrow">
                <label for="" class="form-label">{{ 'timeZone' | translate }}</label>
                <select
                  class="form-control"
                  formControlName="timeZoneName"
                  (change)="onChangeTimeZone(formControls.timeZoneName.value)"
                >
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  <option *ngFor="let tz of tzNames" [value]="tz">
                    {{ tz }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <ng-container formArrayName="externalIds">
              <div
                class="select-wrap form-group"
                *ngFor="let externalId of getIdFormArray().controls; let i = index"
                [formGroupName]="i"
              >
                <div class="custom-select-arrow">
                  <label for="" class="form-label"
                    ><span *ngIf="i === 0">{{ 'externalID' | translate }}</span></label
                  >
                  <select class="form-control" formControlName="k">
                    <option value="" disabled>{{ 'selectOption' | translate }}</option>
                    <option *ngFor="let type of types" [ngValue]="type?.name">
                      {{ type?.name }}
                    </option>
                  </select>
                </div>
                <div class="system-setting-value">
                  <div class="form-group">
                    <label for="" class="form-label"
                      ><span *ngIf="i === 0">{{ 'value' | translate }}</span></label
                    >
                    <input type="text" class="form-control" formControlName="v" />
                  </div>
                </div>
                <i-feather *ngIf="i === 0" name="plus-circle" (click)="addItem()" class="pointer"></i-feather>
                <i-feather *ngIf="i !== 0" name="x-circle" (click)="removeItem(i)" class="pointer"></i-feather>
              </div>
            </ng-container> -->

            <ng-container *ngIf="formControls.userType.value !== ''">
              <div formArrayName="externalIds">
                <h3 class="pt-4 pb-1">External ID</h3>
                <ng-container *ngFor="let externalId of getIdFormArray().controls; let i = index">
                  <div class="form-group" [formGroupName]="i">
                    <label for="" class="form-label"
                      ><span
                        >{{ externalIds(externalId.value) }}
                        <span *ngIf="isRequiredShow && externalId.get('k')?.value.toLowerCase() === 'npi'">
                          *
                        </span></span
                      ></label
                    >
                    <span *ngIf="externalId.get('k')?.value.toLowerCase() !== 'npi'">
                      <input type="text" class="form-control" formControlName="v" />
                    </span>
                    <span *ngIf="externalId.get('k')?.value.toLowerCase() === 'npi'">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="v"
                        appOnlyNumber
                        [ngClass]="{
                          'is-invalid':
                            (submitted && externalId.get('v')?.errors?.required) || externalId.get('v')?.errors?.pattern
                        }"
                      />
                    </span>
                    <div
                      *ngIf="submitted || (externalId.get('v')?.invalid && externalId.get('v')?.touched)"
                      class="text-danger"
                    >
                      <ng-container *ngIf="submitted && externalId.get('v')?.errors?.required">
                        {{ externalIds(externalId.value) }} is required.
                      </ng-container>
                      <ng-container *ngIf="externalId.get('v')?.errors?.pattern"
                        >Please enter 10 numeric digits</ng-container
                      >
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </fieldset>
        </div>
      </div>
      <div class="col-md-6" *ngIf="selectedAffiliationCode?.userType">
        <div class="card-base add-client">
          <div class="view-detail-row">
            <div class="view-detail-item">
              <strong class="view-detail-label">{{ 'userType' | translate }}: </strong>
              <div class="view-detail-info" *ngIf="selectedAffiliationCode?.userType; else noDataTpl">
                <span>{{ selectedAffiliationCode.userType || '-' }}</span>
              </div>
            </div>
            <div class="view-detail-item">
              <strong class="view-detail-label">{{ 'roles' | translate }}: </strong>
              <div class="view-detail-info" *ngIf="selectedAffiliationCode?.roles?.length; else noDataTpl">
                <span *ngFor="let role of selectedAffiliationCode.roles">{{ role.label ? role.label : '-' }}</span>
              </div>
            </div>
            <div class="view-detail-item">
              <strong class="view-detail-label">{{ 'dashboards' | translate }}: </strong>
              <div class="view-detail-info" *ngIf="selectedAffiliationCode?.dashboards?.length; else noDataTpl">
                <span *ngFor="let dashboard of selectedAffiliationCode.dashboards">{{
                  dashboard.label ? dashboard.label + (dashboard.default ? ' (default)' : '') : '-'
                }}</span>
              </div>
            </div>
            <div class="view-detail-item">
              <strong class="view-detail-label">{{ 'applications' | translate }}: </strong>
              <div class="view-detail-info" *ngIf="selectedAffiliationCode?.application?.id; else noDataTpl">
                <span>{{
                  selectedAffiliationCode.application.name ? selectedAffiliationCode.application.name : '-'
                }}</span>
              </div>
            </div>
            <ng-template #noDataTpl>
              <div>-</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
