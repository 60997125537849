import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
/**
 * A pipe that transforms a user role string ('manager' or 'managed') into a client-specific label.
 * It fetches the user configurations from PermissionsService to determine the labels.
 * Uses TranslatePipe for translation if available.
 *
 * Example usage:
 * ```
 * {{ 'manager' | clientLabel }}
 * ```
 */
@Pipe({
  name: 'clientLabel',
})
export class ClientLabelPipe implements PipeTransform {
  constructor(
    private permissionsService: PermissionsService,
    private translatePipe: TranslatePipe,
  ) {}
  /**
   * Transforms a user role string into a client-specific label based on user configurations.
   *
   * @param value The input string representing the user role ('manager' or 'managed').
   * @returns The transformed label string.
   */
  transform(value: string): unknown {
    const userConfigurations = this.permissionsService.getUserConfigurations();
    const clientLabel = userConfigurations.clientLabel || { managerUser: '', managedUser: '' };
    if (value && value.toLowerCase() === 'manager') {
      if (clientLabel.managerUser) {
        value = this.translatePipe.transform(clientLabel.managerUser);
      }
    } else {
      if (clientLabel.managedUser) {
        value = this.translatePipe.transform(clientLabel.managedUser);
      }
    }
    return this.translatePipe.transform(value) || value;
  }
}
