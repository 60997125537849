import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * Directive to handle image load errors by replacing the source with a fallback image.
 * Applied using the 'img[onErrorImg]' selector.
 */
@Directive({
  standalone: true,
  selector: 'img[onErrorImg]',
})
export class OnErrorImgDirective {
  /**
   * Input binding to specify the fallback image URL.
   */
  @Input() onErrorImg!: string;

  /**
   * Constructor to inject ElementRef for accessing the host element.
   * @param el The ElementRef injected to access the host element.
   */
  constructor(private el: ElementRef) {}

  /**
   * Host listener for the 'error' event on the host img element.
   * Replaces the img src with the fallback image URL if the original image fails to load.
   */
  @HostListener('error')
  onError() {
    const element = <HTMLImageElement>this.el.nativeElement;
    element.src = this.onErrorImg || 'assets/images/no-preview.png';
  }
}
