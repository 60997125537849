import { Observable } from 'rxjs';

/**
 * Represents a data source for a tab.
 * @template T The type of data returned by the data source.
 */
export interface TabDataSource<T> {
  /** Method to fetch data for the tab. */
  getData: (params: any) => Observable<T>;
}

/**
 * Represents an action that can be performed within a tab.
 */
export interface TabAction {
  /** Label displayed for the action. */
  label: string;
  /** Indicates if the action is disabled. */
  disabled?: boolean;
  /** Handler function called when the action is triggered. */
  handler: (tabId: string | null) => void;
  /** Optional icon for the action. */
  icon?: string;
  /** Indicates if the action requires special permission. */
  hasPermission?: boolean;
}

/**
 * Represents the structure of health summary tabs.
 */
export interface HealthSummaryTabs {
  /** Array of tab items representing patient tabs. */
  tabItems: PatientTabsItem[];
  /** ID of the active tab. */
  activeTabId: string | null;
  /** Current view mode of the active tab. */
  activeTabMode: ViewMode | null;
}

/**
 * Represents an item in the patient tabs.
 */
export interface PatientTabsItem {
  /** ID of the patient associated with the tab. */
  patientId: string;
  /** Name displayed for the patient tab. */
  name: string;
  /** Indicates if the patient is currently active. */
  patientActive: boolean;
  /** Optional ID of the trial associated with the patient. */
  trialId?: string | null;
  /** Optional details specific to reaching selected patient. */
  reachPatientDetails?: ReachSelectedPatient;
}

/**
 * Represents details specific to reaching a selected patient.
 */
export interface ReachSelectedPatient {
  /** Optional trial ID associated with the patient. */
  trialId?: string | null;
  /** Specifies how the enrollment is managed for the patient. */
  reachEnrollmentBy: ReachEnrollmentBy;
  /** Indicates if enrollment is allowed for the patient. */
  allowEnrollment: boolean;
}

/**
 * Enum defining different ways of enrollment for reaching patients.
 */
export enum ReachEnrollmentBy {
  /** Enrollment via healthcare provider patient relation. */
  HcpPatient,
  /** Enrollment via principal investigator (PI) patient relation. */
  PiPatient,
  /** Enrollment via PI referred patient relation. */
  PiReferedPatient,
}

/**
 * Enum defining different view modes.
 */
export enum ViewMode {
  /** Maximized view mode. */
  Maximized = 1,
  /** Minimized view mode. */
  Minimized,
  /** Snapped view mode. */
  Snapped,
}
