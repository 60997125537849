import { Component } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
/**
 * Component representing content loader.
 */
@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [PipesModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {}
