<div class="dialog-xs">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'addFileName' | translate }}</h2>
    <div class="dialog-header-icons">
      <i-feather name="check" *ngIf="fileName" (click)="clickedCheck()"></i-feather>
      <i-feather name="x" mat-dialog-close></i-feather>
    </div>
  </div>
  <p class="message sm-text clr-secondary">
    {{ 'fileNameDialogMessage' | translate }} <span class="ff-bold">{{ 'resourcesFavorite' | translate }}</span>
  </p>

  <div class="form-group">
    <label for="fileName" required>{{ 'fileName' | translate }}</label>
    <input type="text" class="form-control" id="fileName" [(ngModel)]="fileName" />
  </div>
</div>
