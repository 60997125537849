<div class="message-list-card">
  <div class="message-list">
    <div class="message-item pointer" *ngFor="let thread of threadList" (click)="openThread(thread)">
      <div class="message-icon"><img src="assets/images/icons/user.png" alt="message-user" /></div>
      <div class="message-item-text">
        <div class="title-date flex-between">
          <span class="message-item-title">{{ thread.topic }}</span>
          <div class="message-info">
            <!-- <button class="btn btn-primary broadcast">Broadcast</button> -->
            <span class="message-item-date">
              {{ thread.lastMessage.date | dateTime: ' | ' }}
            </span>
          </div>
        </div>
        <div class="sidebar-msg-body">
          <div class="sidebar-msg-text">{{ thread.lastMessage.displayName }}: {{ thread.lastMessage.message }}</div>
          <div class="sidebar-msg-icons">
            <ng-container *ngIf="!patientId">
              <span class="unread-count" *ngIf="thread.unreadCount">{{ thread.unreadCount }}</span>
            </ng-container>
            <span class="unread-count" *ngIf="patientId && thread.requiresAttention"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
