<div class="dialog-sm">
  <div class="hs-tab-card-title">
    <div class="flex-between">
      <h2>{{ 'messageRecalled' | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
      </div>
    </div>
    <div class="recalled-message-details">
      <strong>{{ 'sent' | translate }}:</strong> {{ recalledMessageData.recalledBy }} |&nbsp;
      {{ recalledMessageData.sentDate | dateTime: ', ' }} <span class="dot-separator"></span>
      <strong>{{ 'recalled' | translate }}:</strong>&nbsp; {{ recalledMessageData.recalledBy }} |
      {{ recalledMessageData.recalledAt | dateTime: ', ' }}
    </div>
  </div>

  <div class="message" [innerHTML]="recalledMessageData.message | formatMessage"></div>

  <div class="attachment-icons" *ngIf="attachments">
    <div class="attachment-icons-item" *ngFor="let attachment of attachments">
      <img [src]="attachment.fileIcon" alt="user" />
    </div>
  </div>
</div>
