import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { FileUploadComponent } from './file-upload.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [CommonModule, FeatherModule, MatDialogModule, PipesModule],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
