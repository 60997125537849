<div class="table-wrapper">
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container [matColumnDef]="column.title" *ngFor="let column of columns; let colIndex = index">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.min-width]="column?.columnWidth"
          [ngClass]="{
            'active-cell': column?.active
          }"
        >
          <span>
            <span>{{
              column && (column.title.toLowerCase() === 'manager' || column.title.toLowerCase() === 'managed')
                ? (column.title | clientLabel)
                : column.title
            }}</span>
          </span>
          <span class="search-filter badge-indicator" *ngIf="column.search?.icon">
            <i-feather name="search" class="feather-20 pointer" (click)="clickOnSearch($event, column)"> </i-feather>
            <span class="inactive-badge" *ngIf="column.search?.active"> </span>
          </span>
          <span class="search-filter" *ngIf="column.filter?.icon">
            <i-feather name="filter" class="feather-20 pointer" (click)="clickOnFilter($event, column)"> </i-feather>
            <span class="inactive-badge" *ngIf="column.filter?.active"> </span>
          </span>
          <span class="tbl-sort" *ngIf="column.sort">
            <i-feather
              name="chevron-up"
              class="feather-20"
              [ngClass]="{
                'active-sort': column.active && column.order && column.order === 'ASC'
              }"
              (click)="sortByColumn(column.key, 'ASC')"
            ></i-feather>
            <i-feather
              name="chevron-down"
              class="feather-20"
              [ngClass]="{
                'active-sort': column.active && column.order && column.order === 'DESC'
              }"
              (click)="sortByColumn(column.key, 'DESC')"
            ></i-feather>
          </span>
          <!-- {{column | json}} -->
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="d-flex">
            <span *ngIf="element && column.key === 'name' && showCheckbox" class="me-1">
              <!-- {{element.checked}} -->
              <div class="form-check-flex d-inline">
                <input
                  type="checkbox"
                  [disabled]="element.isCheckBoxDisabled"
                  [(ngModel)]="element.checked"
                  (change)="changeChkState(element.id.queryParams.uid, element.checked)"
                  class="pointer form-check-input"
                />
              </div>
            </span>
            <span
              *ngIf="element && column.key"
              [ngClass]="{
                'text-primary': column && column.hasLink
              }"
              class="d-flex align-items-center"
            >
              <span *ngIf="column && !column?.icons">
                <div class="head-in ws-pre-line">
                  {{
                    (column &&
                    column.key &&
                    (column.key.toLowerCase() === 'usertype' ||
                      column.key.toLowerCase() === 'rolemetatype' ||
                      column.key.toLowerCase() === 'acceptingusertypes')
                      ? (element[column.key].value | clientLabel)
                      : element[column.key].value) || '-'
                  }}
                </div>
                <div *ngIf="element[column.key].queryParams.description" class="description mt-1">
                  {{ element[column.key].queryParams.description || '-' }}
                </div>
              </span>
              <span class="action-icons action" *ngIf="column && column?.icons && column.icons.length > 0">
                <ng-container *ngFor="let icon of column.icons; let iconIndex = index">
                  <i-feather
                    *ngIf="icon.show && (icon.iName || element.actions.icons[iconIndex].iName)"
                    name="{{
                      icon.iName
                        ? icon.iName
                        : element.actions.icons[iconIndex].iName
                          ? element.actions.icons[iconIndex].iName
                          : ''
                    }}"
                    class="pointer feather-20"
                    [class.disabled]="
                      icon.key === 'delete' &&
                      (element?.isUserDefaultGroup?.value ||
                        element?.isClientDefaultUserGroup?.value ||
                        icon.class === 'disabled' ||
                        (element?.providedBy && element.providedBy.value === 'PLATFORM'))
                    "
                    (click)="clickOnActionIcons($event, icon, element)"
                  ></i-feather>

                  <i-feather
                    *ngIf="icon.show && icon.iName === 'userStatus'"
                    name="{{
                      icon.iName
                        ? icon.iName
                        : element.actions.icons[iconIndex].iName
                          ? element.actions.icons[iconIndex].iName
                          : ''
                    }}"
                    class="pointer feather-20"
                    (click)="clickOnActionIcons($event, icon, element)"
                  ></i-feather>
                </ng-container>
              </span>
            </span>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <ng-container>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            disabled: row.status && row.status.value && row.status.value.toLowerCase() !== 'active'
          }"
        ></tr>
      </ng-container>
    </table>
    <!-- <div *ngIf="showPagination">
      <app-pagination *ngIf="data && data.length > 0" [paginationOptions]="paginationOptions"
        (pagination)="handlePagination($event)"></app-pagination>
    </div> -->
    <div class="norecord" *ngIf="data && data.length === 0">{{ 'noRecordFound' | translate }}</div>
  </div>
  <div *ngIf="showPagination">
    <app-pagination
      *ngIf="data && data.length > 0"
      [paginationOptions]="paginationOptions"
      (pagination)="handlePagination()"
    ></app-pagination>
  </div>
</div>
