import { HttpContextToken } from '@angular/common/http';
import { SafeResourceUrl } from '@angular/platform-browser';
import { GridsterItem } from 'angular-gridster2';
import { AlertSeverity } from 'src/app/modules/dashboard/patient-list/patient-list.entities';
import { VideoLobby } from 'src/app/modules/video-lobby/entities/video-lobby.entities';
/**
 * Interface representing pagination information.
 */
export interface Pagination {
  /**
   * The limit of items per page.
   */
  limit: number;

  /**
   * The current page number.
   */
  currentPage: number;

  /**
   * The total count of items across all pages.
   */
  totalCount: number;

  /**
   * The total number of pages.
   */
  totalPage: number;

  /**
   * Optional: The field by which to sort the results.
   */
  sort?: string;
}
/**
 * Interface representing pagination event response.
 */
export interface paginationEventresponse {
  /**
   * The current page number.
   */
  currentPage?: number;

  /**
   * Alias for `currentPage`. Deprecated; use `currentPage` instead.
   * @deprecated Use `currentPage` instead.
   */
  page?: number;

  /**
   * The limit of items per page.
   */
  limit?: number;

  /**
   * The field by which to sort the results.
   */
  sort?: string;

  /**
   * The search query.
   */
  query?: string;

  /**
   * The total count of items across all pages.
   */
  totalCount?: number;

  /**
   * The total number of pages.
   */
  totalPage?: number;

  /**
   * The offset of the items.
   */
  offset?: number;
}
/**
 * Interface representing sorting criteria for a table.
 */
export interface TableSort {
  /**
   * The column by which to sort.
   */
  column: string;

  /**
   * The order of sorting.
   * - 'ASC': Ascending order.
   * - 'DESC': Descending order.
   */
  order: 'ASC' | 'DESC';
}
/**
 * Interface representing a group.
 */
export interface groupInterface {
  /**
   * The identifier of the group.
   */
  id: string;
}
/**
 * Interface representing content group.
 */
export interface contentGroupInterface {
  /**
   * Array representing the content group.
   */
  contentGroup: [];
}
/**
 * Interface representing widget data, extending GridsterItem.
 */
export interface WidgetData extends GridsterItem {
  /**
   * Indicates whether the widget supports drag and drop.
   */
  dragAndDrop: boolean;

  /**
   * The icon associated with the widget.
   */
  icon: string;

  /**
   * The unique identifier of the widget.
   */
  id: string;

  /**
   * Indicates whether the widget is resizable.
   */
  resizable: boolean;

  /**
   * The status of the widget.
   */
  status: string;

  /**
   * The type of the widget.
   */
  type: string;

  /**
   * The display title of the widget.
   */
  widgetDisplayTitle: string;

  /**
   * Optional: The affiliation code associated with the widget.
   */
  affiliationCode?: string;

  /**
   * The path to the widget.
   */
  widgetPath: string;

  /**
   * The settings object specific to the widget.
   */
  widgetSettings: unknown;

  /**
   * The title of the widget.
   */
  widgetTitle: string;

  /**
   * The type of the widget.
   */
  widgetType: string;
}
/**
 * Interface representing WebSocket event.
 */
export interface WebSocketEvent {
  /**
   * The message payload of the WebSocket event.
   */
  message: {
    /**
     * Optional severity level of the message.
     */
    severity?: AlertSeverity;

    /**
     * Optional identifier of the patient associated with the message.
     */
    patientId?: string;

    /**
     * Optional sender of the message.
     */
    from?: string;
  };

  /**
   * The type of the WebSocket event.
   */
  type: AlertEventType;
}
/**
 * Interface representing chat WebSocket event.
 */
export interface ChatWebSocketEvent {
  /**
   * The identifier of the chat thread.
   */
  threadId: string;
  /**
   * The relation of the message event.
   * - 'myMessages': Indicates messages related to the user.
   * - 'myTeamMessages': Indicates messages related to the user's team.
   */
  relation: 'myMessages' | 'myTeamMessages';
  /**
   * The operation type of the event.
   * - 'DEC': Indicates a decrease operation.
   * - 'INC': Indicates an increase operation.
   */
  ops: 'DEC' | 'INC';
  /**
   * Optional: The count of messages affected by the event.
   */
  count?: number;
}
/**
 * Interface representing video lobby WebSocket event.
 */
export interface VideoLobbyWebSocketEvent {
  /**
   * The type of WebSocket event.
   * Possible values include:
   * - 'CheckInRequested'
   * - 'CallStarted'
   * - 'CallEnded'
   * - 'CallParticipantRemoved'
   * - 'CallParticipantAdded'
   * - 'CallRecordingStarted'
   * - 'CallRecordingStopped'
   * - 'VideoLobbyCreated'
   * - 'VideoLobbyUpdated'
   * - 'VideoLobbyDeleted'
   * - 'CheckedIn'
   */
  type:
    | 'CheckInRequested'
    | 'CallStarted'
    | 'CallEnded'
    | 'CallParticipantRemoved'
    | 'CallParticipantAdded'
    | 'CallRecordingStarted'
    | 'CallRecordingStopped'
    | 'VideoLobbyCreated'
    | 'VideoLobbyUpdated'
    | 'VideoLobbyDeleted'
    | 'CheckedIn';
  /**
   * The unique identifier of the room associated with the event.
   */
  roomId: string;

  /**
   * The title or name of the event.
   */
  eventTitle: string;

  /**
   * The identifier of the event.
   */
  id: string;

  /**
   * The date/time when the event occurred.
   */
  date: string;

  /**
   * The user involved in the event.
   */
  user: string;

  /**
   * The type of the user involved ('Managed' or 'Manager').
   */
  userType: 'Managed' | 'Manager';

  /**
   * Details of the video lobby associated with the event.
   */
  videoLobbyDetails: VideoLobby;
}
/**
 * Enum representing alert event types.
 */
export enum AlertEventType {
  /**
   * Indicates an alert was generated.
   */
  AlertGenerated = 'AlertGenerated',
}
/**
 * Interface representing count.
 */
export interface Count {
  /**
   * The numerical count value.
   */
  count: number;
}
/**
 * Interface representing alert severity.
 */
export interface IAlertSeverty {
  /**
   * Number of alerts categorized as severity level 0.
   */
  severity0: number;

  /**
   * Number of alerts categorized as severity level 1.
   */
  severity1: number;

  /**
   * Number of alerts categorized as severity level 2.
   */
  severity2: number;

  /**
   * Number of alerts categorized as severity level 3.
   */
  severity3: number;

  /**
   * Number of alerts categorized as severity level 4.
   */
  severity4: number;
}
/**
 * Interface representing reported alert.
 */
export interface IAlertReported {
  /**
   * The type or category of the reported alert.
   */
  type: string;

  /**
   * The count or number of alerts reported for the specified type.
   */
  count: number;
}
/**
 * Enum representing types of notes.
 */
export enum NotesTypes {
  /**
   * Notes related to vital signs or measurements.
   */
  Vitals = 'Vitals',

  /**
   * Notes related to medications or prescriptions.
   */
  Medications = 'Medications',

  /**
   * Notes related to alerts or notifications.
   */
  Alerts = 'Alerts',

  /**
   * Notes related to tasks or actions.
   */
  Task = 'Task',

  /**
   * Notes related to self-assessment or personal reflections.
   */
  Self = 'Self',

  /**
   * Notes related to other users or collaborators.
   */
  User = 'User',

  /**
   * Notes related to a trial or study participation.
   */
  ReachTrial = 'ReachTrial',
}
/**
 * Interface representing demographic data.
 */
export interface DemographicData {
  /**
   * The unique identifier of the user.
   */
  userID: string;

  /**
   * The first name of the user.
   */
  firstName: string;

  /**
   * The middle initial of the user.
   */
  middleInitial: string;

  /**
   * The last name of the user.
   */
  lastName: string;

  /**
   * The URL of the user's profile image.
   */
  imageUrl: string;

  /**
   * The sex or gender of the user.
   */
  sex: string;
}
/**
 * Interface representing successful file upload response.
 */
export interface FileUploadSuccess {
  /**
   * The URL where the uploaded file can be accessed.
   */
  url: string;
  /**
   * The MIME type of the uploaded file.
   */
  mimetype: string;
  /**
   * The unique identifier or document ID associated with the uploaded file.
   */
  documentId: string;
}
/**
 * Interface representing filter options.
 */
export interface FilterOptions {
  /**
   * The unique identifier for the filter option.
   */
  id: string;
  /**
   * The display name or label for the filter option.
   */
  name: string;
  /**
   * Optional flag indicating whether the filter option is checked.
   */
  checked?: boolean;
}
/**
 * Interface representing generic table.
 */
export interface GenericTable {
  /**
   * The value or data associated with the table row.
   */
  value: unknown;
  /**
   * The URL of the route associated with the table row.
   */
  routeUrl: string;
  /**
   * Query parameters object associated with the route.
   */
  queryParams: object;
}
/**
 * Interface representing columns in a generic table.
 */
export interface GenericTableColumn {
  /**
   * The key or identifier of the column.
   */
  key: string;
  /**
   * The title or header text of the column.
   */
  title: string;
  /**
   * The key used to display data in the column.
   */
  displayKey: string;
  /**
   * Optional key used for filtering data in the column.
   */
  filterKey?: string;
  /**
   * Optional key used for searching within the column.
   */
  searchKey?: string;
  /**
   * Indicates if the column is sortable.
   */
  sort?: boolean;
  /**
   * The sorting order ('ASC' or 'DESC') for the column.
   */
  order?: string | null;
  /**
   * Indicates if the column is currently active or visible.
   */
  active?: boolean;
  /**
   * Configuration for search functionality within the column.
   */
  search?: {
    /**
     * Indicates whether to display an icon for search in this column.
     */
    icon: boolean;
    /**
     * Indicates whether searching is currently active for this column.
     */
    active: boolean;
  };
  /**
   * Configuration for filter functionality within the column.
   */
  filter?: {
    /**
     * Indicates whether to display an icon for filter in this column.
     */
    icon: boolean;
    /**
     * Indicates whether filtering is currently active for this column.
     */
    active: boolean;
  };
  /**
   * Indicates whether the column has links associated with its data.
   */
  hasLink?: boolean;
  /**
   * Optional array of icons associated with the column data.
   */
  icons?: Array<{
    /**
     * Name or identifier of the icon.
     */
    iName: string;
    /**
     * Key associated with the icon.
     */
    key: string;
    /**
     * CSS class for styling the icon.
     */
    class: string;
    /**
     * Indicates whether to show the icon.
     */
    show: boolean;
    /**
     * Optional route URL associated with the icon.
     */
    route?: string;
  }>;
}
/**
 * Interface representing a filter option.
 */
export interface FilterOptions {
  /**
   * The unique identifier for the filter option.
   */
  id: string;
  /**
   * The display name or label for the filter option.
   */
  name: string;
  /**
   * Optional flag indicating whether the filter option is checked.
   */
  checked?: boolean;
}
/**
 * Interface representing confirmation dialog data.
 */
export interface ConfirmationDialogData {
  /**
   * The title of the confirmation dialog.
   */
  title: string;
  /**
   * The message or body text of the confirmation dialog.
   */
  message: string;
  /**
   * Optional text for the accept button. If not provided, a default text may be used.
   */
  acceptButtonText?: string;
  /**
   * Optional text for the decline button. If not provided, a default text may be used.
   */
  declineButtonText?: string;
  /**
   * Label for the yes button.
   */
  yesButtonLabel?: string;
  /**
   * Label for the no button.
   */
  noButtonLabel?: string;
}
/**
 * Interface representing registration form group.
 */
export interface registerFormGrp {
  /**
   * The username for registration.
   */
  userName: string;
  /**
   * The password for registration.
   */
  password: string;
  /**
   * The suffix (e.g., Mr., Ms., Dr.) for the user's name.
   */
  suffix: string;
  /**
   * The first name of the user.
   */
  firstName: string;
  /**
   * The last name of the user.
   */
  lastName: string;
  /**
   * The sex or gender of the user.
   */
  sex: string;
  /**
   * The phone number of the user (optional).
   */
  phone?: string;
  /**
   * The email address of the user (optional).
   */
  emailAddress?: string;
  /**
   * The affiliation code associated with the user.
   */
  affiliationCode: string;
  /**
   * The invitation ID for registration.
   */
  invitationId: string;
  /**
   * The National Provider Identifier (NPI) of the user (optional).
   */
  npi?: string;
}
/**
 * Interface representing chart response.
 */
export interface ChartResp {
  /**
   * Anomaly data points in the chart.
   */
  anomaly: [
    {
      /**
       * Lower bound of the anomaly range.
       */
      lower: string;
      /**
       * Timestamp associated with the anomaly data point.
       */
      timestamp: string;
      /**
       * Upper bound of the anomaly range.
       */
      upper: string;
      /**
       * Value of the anomaly data point.
       */
      value: string;
    },
  ];
  /**
   * Trend data points in the chart.
   */
  trends: [
    {
      /**
       * Average value in the trend data point.
       */
      avgValue: string;
      /**
       * Count of records for the trend data point.
       */
      countOfRecords: string;
      /**
       * Data field associated with the trend data point.
       */
      dataField: string;
      /**
       * Maximum value in the trend data point.
       */
      maxValue: string;
      /**
       * Minimum value in the trend data point.
       */
      minValue: string;
      /**
       * Timestamp associated with the trend data point.
       */
      timestamp: string;
      /**
       * User ID associated with the trend data point.
       */
      userId: string;
    },
  ];
}
/**
 * Interface representing attachment.
 */
export interface Attachment {
  /**
   * The type of the attachment (e.g., 'image', 'document', 'video').
   */
  type: string;
  /**
   * The name of the attachment.
   */
  name?: string;
  /**
   * The MIME type of the attachment.
   */
  mimeType?: string;
  /**
   * The URL where the attachment can be accessed.
   */
  url: string;
  /**
   * The filename of the attachment.
   */
  filename?: string;
  /**
   * SafeResourceUrl representing a safe URL for the attachment.
   */
  safeUrl?: SafeResourceUrl;
  /**
   * An optional icon representing the attachment type (e.g., file icon).
   */
  icon?: string;
}
/**
 * Interface representing temporary file URL.
 */
export interface TemporaryFileUrl {
  /**
   * The URL of the temporary file.
   */
  url: string;
}
/**
 * HttpContextToken representing whether to hide loader.
 */
export const HIDE_LOADER = new HttpContextToken<boolean>(() => false);
/**
 * Interface for defining options when filtering or searching by column.
 */
export interface FilterColumnOption {
  /** Search hold the applied column name searched item/name  */
  search?: string;
  /** The column to apply the filter or search. */
  column: GenericTableColumn;
  /** The X-coordinate position of the user's click event triggering the action. */
  pageX: string;
  /** The Y-coordinate position of the user's click event triggering the action. */
  pageY: string;
  /** The type of action triggered ('filter' or 'search'). */
  type: string;
}
