import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/modules/shared/services/file-upload.service';
import { CameraViewerComponent } from '../camera-viewer/camera-viewer.component';
/**
 * Component for uploading files and displaying file-related functionalities.
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  /**
   * The file to be uploaded. Can be `null` if no file is selected.
   * @type {File | null}
   */
  file!: File | null;
  /** Determines whether file validation is enabled. */
  @Input() validation!: boolean;
  /** The URL of the file. */
  @Input() fileUrl!: string;
  /** The label associated with the file upload component. */
  @Input() label!: string;
  /** Specific type of file being uploaded. */
  @Input() allowFileType!: string;
  /** Specific type of file being uploaded. */
  @Input() fileType!: string;
  /** Flag indicating whether to allow a path or URL. */
  @Input() allowPathOrUrl = false;
  /** Event emitter for when a file is uploaded. */
  @Output() fileUploaded: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitter for when the file name is uploaded. */
  @Output() fileUploadedName: EventEmitter<string> = new EventEmitter<string>();
  /** Reference to the file input element. */
  @ViewChild('fileInput') fileInput!: ElementRef;
  /** Flag indicating whether to allow using the camera for image capture. */
  @Input() isAllowCamera!: boolean;
  /** The URL of the uploaded image. */
  @Input() imageUrl!: string;
  /**
   * Constructor for initializing FileUploadComponent.
   * @param fileUploadService The service for handling file upload operations.
   * @param dialog The MatDialog service for displaying dialogs.
   */
  constructor(
    private fileUploadService: FileUploadService,
    public dialog: MatDialog,
  ) {}

  /**
   * Checks and initializes the imageUrl property if not defined.
   */
  ngOnInit(): void {
    this.imageUrl = this.imageUrl ? this.imageUrl : '';
  }
  /**
   * Emits the file URL when a file is uploaded.
   * @param fileUrl The URL of the uploaded file.
   */
  emitFileUrl(fileUrl: string) {
    this.fileUploaded.emit(fileUrl);
  }
  /**
   * Emits the file name when a file is uploaded.
   * @param fileName The URL of the uploaded file.
   */
  emitFileName(fileName: string) {
    this.fileUploadedName.emit(fileName);
  }
  /**
   * Retrieves the file icon based on the file URL.
   * @param fileUrl The URL of the file.
   * @returns The file icon.
   */
  getFileIcon(fileUrl: string) {
    return this.fileUploadService.getFileIcon(fileUrl);
  }
  /**
   * Handles the file upload event.
   * @param event The file upload event.
   */
  uploadFile(event: Event) {
    if (this.fileType) {
      const target = event.target as HTMLInputElement;
      this.file = (target.files as FileList)[0];
      this.getUploadedFileUrl(this.file);
    }
  }
  /**
   * Opens the camera viewer component for capturing images.
   */
  cameraViewer() {
    this.fileType = 'document';
    const dialogRef = this.dialog.open(CameraViewerComponent, {
      panelClass: 'document-expended',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data != '') {
        this.imageUrl = '';
        this.getUploadedFileUrl(data);
      }
    });
  }
  /**
   * Retrieves the uploaded file URL and processes it.
   * @param file The file to upload.
   */
  async getUploadedFileUrl(file: File) {
    if (file != undefined) {
      const formData = new FormData();
      formData.append('file', file);
      this.fileUploadService.uploadFile(formData, this.fileType).subscribe(async (response: any) => {
        if (response && response.url) {
          this.emitFileName(response.name);
          this.emitFileUrl(response.url);
          if (this.fileType === 'images' || this.fileType === 'goalstasks') {
            this.imageUrl = response.url;
          }
          if (this.fileType === 'document') {
            const file = await this.fileUploadService.uploadFileAccessSignature(response.url);
            if (this.fileUploadService.getFileType(response.url) === 'image') {
              this.imageUrl = file?.url;
            } else {
              this.imageUrl = this.fileUploadService.getFileIcon(response.url);
            }
          }
        }
      });
    }
  }
  /**
   * Handles the blur event on the URL input.
   * @param value The value of the input field.
   */
  onBlurUrlInput(value: string) {
    this.file = null;
    this.emitFileUrl(value);
  }
  /**
   * Removes the uploaded file.
   */
  removeFile() {
    this.file = null;
    this.fileInput.nativeElement.value = '';
    this.imageUrl = '';
    this.emitFileUrl('');
  }
  /**
   * Retrieves the file extension from the file URL.
   * @param fileUrl The URL of the file.
   * @returns The file extension.
   */
  getFileExtension(fileUrl: string) {
    if (!fileUrl) return '';
    return this.fileUploadService.getFileExtension(fileUrl);
  }
}
