/**
 * Configuration object for the application environment.
 */
export const environment = {
  /**
   * Indicates whether the application is in production mode.
   */
  production: false,

  /**
   * The name of the environment (e.g., 'develop', 'production', 'staging').
   */
  name: 'develop',

  /**
   * Short identifier for the environment (e.g., 'dev', 'prod', 'stg').
   */
  env: 'dev',

  /**
   * URLs for various web services used by the application.
   */
  url: {
    /**
     * URL for the authentication API.
     */
    apiAuth: 'https://oauth2.medocity.net',

    /**
     * Base URL for the main API.
     */
    apiHost: 'https://api.medocity.net',

    /**
     * URL for the v2 web provider portal.
     */
    v2webProviderPortal: 'https://doctorportal.medocity.net',

    /**
     * URL for the v2 API host.
     */
    v2apiHost: 'https://cerebellum.medocity.net',

    /**
     * API version prefix.
     */
    version: '/v3',

    /**
     * WebSocket URL.
     */
    websocket: 'wss://socket.medocity.net/applications',

    /**
     * URL for the chat service.
     */
    chat: 'https://communications-dev.communication.azure.com/',

    /**
     * URL for the azureOpenAi.
     */
    azureOpenAi: 'https://medgpt-prod-us-e2.openai.azure.com',
  },

  /**
   * App-specific settings.
   */
  appConfig: {
    /**
     * Display name of the application.
     */
    name: 'Site Portal',

    /**
     * Title of the application.
     */
    title: 'Medocity Site Portal',

    /**
     * Type of the application (e.g., 'doctor', 'admin').
     */
    type: 'doctor',

    /**
     * Version of the application.
     */
    version: '2.9',
  },
  azureOpenAiKey: 'fb46c8cc8b4d457baf21d2f0dced40a9',
};
