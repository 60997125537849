import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { ObservationSummarizationComponent } from 'src/app/modules/ai-metrics/components/observation-summarization/observation-summarization.component';
import { PermissionsModule } from 'src/app/modules/permissions/permissions.module';
import { DropdownMenuModule } from 'src/app/modules/shared/component/dropdown-menu/dropdown-menu.module';
import { FileUploadModule } from 'src/app/modules/shared/component/file-upload/file-upload.module';
import { HealthwisePreviewModule } from 'src/app/modules/shared/component/healthwise-preview/healthwise-preview.module';
import { PipesModule } from 'src/app/modules/shared/pipes/pipes.module';
import { CreateMessageThreadComponent } from './components/create-message-thread/create-message-thread.component';
import { MessageAttachmentFavouriteComponent } from './components/message-attachment-favourite/message-attachment-favourite.component';
import { MessageAttachmentPreviewComponent } from './components/message-attachment-preview/message-attachment-preview.component';
import { MessageConfirmationComponent } from './components/message-confirmation/message-confirmation.component';
import { MessageContactsComponent } from './components/message-contacts/message-contacts.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MessageFilterDialogComponent } from './components/message-filter-dialog/message-filter-dialog.component';
import { MessageReadByComponent } from './components/message-read-by/message-read-by.component';
import { MessageRecommendedResourcesComponent } from './components/message-recommended-resources/message-recommended-resources.component';
import { MessageTemplateComponent } from './components/message-template/message-template.component';
import { MessageThreadListComponent } from './components/message-thread-list/message-thread-list.component';
import { MessageUiComponent } from './components/message-ui/message-ui.component';
import { MessageWidgetComponent } from './components/message-widget/message-widget.component';
import { MessageComponent } from './components/message/message.component';
import { SuggestedMessageComponent } from './components/suggested-message/suggested-message.component';
import { ViewRecalledMessageComponent } from './components/view-recalled-message/view-recalled-message.component';
import { FormatMessagePipe } from './pipes/format-message.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MessageAPIsService } from './services/message-apis.service';
/**
 * Module that selects a subset of Feather icons from FeatherModule.
 */
const FeatherIconsModule = [FeatherModule.pick(allIcons)];

@NgModule({
  declarations: [
    MessageComponent,
    CreateMessageThreadComponent,
    MessageContactsComponent,
    MessageTemplateComponent,
    MessageReadByComponent,
    MessageConfirmationComponent,
    MessageDialogComponent,
    MessageUiComponent,
    MessageAttachmentPreviewComponent,
    MessageAttachmentFavouriteComponent,
    MessageFilterDialogComponent,
    ViewRecalledMessageComponent,
    MessageWidgetComponent,
    MessageRecommendedResourcesComponent,
    MessageThreadListComponent,
  ],
  exports: [MessageComponent, CreateMessageThreadComponent, MessageWidgetComponent],
  imports: [
    CommonModule,
    FeatherIconsModule,
    MatDialogModule,
    FormsModule,
    PipesModule,
    NgbModule,
    FileUploadModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CKEditorModule,
    DropdownMenuModule,
    PermissionsModule,
    HealthwisePreviewModule,
    ObservationSummarizationComponent,
    SuggestedMessageComponent,
    FormatMessagePipe,
    SafeHtmlPipe,
  ],
  providers: [MessageAPIsService],
})
export class MessageModule {
  public getComponent() {
    return MessageWidgetComponent;
  }
}
