import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from 'src/app/modules/shared/interfaces/common.entities';
import { FileUploadService } from 'src/app/modules/shared/services/file-upload.service';
/**
 * Component for displaying a carousel of document previews.
 */
@Component({
  selector: 'app-document-preview-carousel',
  templateUrl: './document-preview-carousel.component.html',
  styleUrls: ['./document-preview-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewCarouselComponent implements OnInit {
  /** Index of the currently selected attachment in the carousel. */
  index = 0;
  /** Flag indicating if there was an error loading the selected attachment. */
  loadingAttachmentError = false;
  /** Currently selected attachment for preview. */
  selectedAttachment!: Attachment;

  /**
   * Constructor for DocumentPreviewCarouselComponent.
   * @param change - ChangeDetectorRef for triggering change detection in Angular.
   * @param sanitize - DomSanitizer for sanitizing URLs and preventing security risks.
   * @param fileUploadService - Service for uploading and accessing files.
   * @param data - Data object containing attachments and selected attachment details.
   */
  public constructor(
    private change: ChangeDetectorRef,
    private sanitize: DomSanitizer,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      attachments: Attachment[];
      selectedAttachment: Attachment;
      title: string | null | undefined;
    },
  ) {}
  /**
   * This method checks if `data.selectedAttachment` exists. If it does, it finds the index
   * of the selected attachment in `data.attachments`, sets `this.index` accordingly, assigns
   * `data.selectedAttachment` to `this.selectedAttachment`, and then previews the selected
   * attachment using the `preview()` method.
   */
  async ngOnInit(): Promise<void> {
    if (this.data?.selectedAttachment) {
      const selectedAttachmentIndex = (this.index = this.data.attachments.findIndex(
        (attachment: Attachment) => attachment.url === this.data?.selectedAttachment.url,
      ));
      this.index = selectedAttachmentIndex >= 0 ? selectedAttachmentIndex : 0;
      //const selectedAttachment = this.data.attachments[this.index];
      this.selectedAttachment = this.data?.selectedAttachment;
      await this.preview(this.selectedAttachment);
    }
  }
  /**
   * Handler for attachment loading error.
   */
  onErrorAttachment() {
    this.loadingAttachmentError = true;
  }
  /**
   * Moves to the previous attachment in the carousel.
   */
  prev() {
    this.index = this.index > 0 ? this.index - 1 : 0;
    this.prevNextClickHandler();
  }
  /**
   * Moves to the next attachment in the carousel.
   */
  next() {
    this.index = this.index < this.data.attachments.length - 1 ? this.index + 1 : this.data.attachments.length - 1;
    this.prevNextClickHandler();
  }
  /**
   * Common handler for previous and next click events.
   * Fetches and previews the attachment at the current index.
   */
  prevNextClickHandler() {
    if (this.data?.attachments?.length && this.data?.attachments[this.index]?.url) {
      const file = this.data.attachments[this.index];
      this.preview(file);
    }
  }
  /**
   * Handler for thumbnail click events.
   *
   * @param file - Attachment object representing the clicked thumbnail.
   */
  onClickThumbnails(file: Attachment) {
    if (file?.url) {
      this.preview(file);
    }
  }
  /**
   * Asynchronously previews the given attachment.
   *
   * @param attachment - Attachment object to preview.
   */
  async preview(attachment: Attachment): Promise<void> {
    if (!attachment.safeUrl) {
      if (this.data?.selectedAttachment?.type === 'videoLobby') {
        attachment.safeUrl = this.sanitize.bypassSecurityTrustResourceUrl(
          (await this.fileUploadService.getVideoLobbyUploadFileAccessSignature(attachment.url))?.url,
        );
      } else
        attachment.safeUrl = this.sanitize.bypassSecurityTrustResourceUrl(
          (await this.fileUploadService.uploadFileAccessSignature(attachment.url))?.url,
        );
    }

    this.selectedAttachment = attachment;
    this.change.markForCheck();
  }
  /**
   * Retrieves the MIME type of the attachment based on its URL.
   *
   * @param url - URL of the attachment.
   * @returns MIME type of the attachment.
   */
  getMimeType(url: string) {
    if (!url) return '';
    const selectedAttachment = this.data.attachments.find((attachment: Attachment) => attachment.url === url);
    return selectedAttachment?.mimeType ? selectedAttachment.mimeType : this.fileUploadService.getMimeType(url);
  }
}
