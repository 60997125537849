import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { filter, forkJoin, from, map } from 'rxjs';
import { MustMatch, oneValidator } from 'src/app/modules/shared/validators/must-match.validators';
import { UsersService } from 'src/app/modules/user-management/users.service';

import {
  AffiliationCode,
  Demographics,
  ExternalIds,
  User,
  UserDetailsResponse,
  externalIdData,
  externalIdKeysData,
} from 'src/app/modules/user-management/interfaces/users.interface';
/**
 * Component for adding and editing user information.
 * This component manages the user form, validation, and interactions with the user service.
 */
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent {
  /** Indicates if the form has been submitted. */
  submitted = false;
  /** Validity status of the date of birth. */
  isValidDOB = false;
  /** User's timezone guessed from the browser. */
  userTz = moment.tz.guess();
  /** List of timezone names. */
  tzNames = moment.tz.names();
  /** Form group for adding/editing a user. */
  addUserFormGroup!: FormGroup;
  /** List of affiliation codes available for users. */
  affiliationCodes!: Array<AffiliationCode>;
  /** Selected affiliation code. */
  selectedAffiliationCode!: AffiliationCode;
  /** List of suffixes for user names. */
  suffixes!: Array<string>;
  /** List of managers available for selection. */
  managers!: Array<{ id: string; name: string }>;
  /** Current user object being edited or added. */
  user!: User;
  /** Client ID from query parameters. */
  clientId: string;
  /** User ID from query parameters. */
  uid: string;
  /** Parameters for fetching affiliation codes. */
  params = { limit: 200, page: 1, filter: 'Active', sort: 'code|DESC' };
  /** External ID types for the user. */
  types: externalIdData[] = [];
  /** Indicates if NPI is required. */
  isNpiRequired = false;
  /** Indicates if additional fields are required to be shown. */
  isRequiredShow = false;

  /**
   * Constructor initializing dependencies and setting up initial state.
   * @param formBuilder - Service to build reactive forms.
   * @param router - Service to handle routing.
   * @param route - Service to access route information.
   * @param toastrService - Service to show toast notifications.
   * @param usersService - Service for user management operations.
   */
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private usersService: UsersService,
  ) {
    this.clientId = this.route.snapshot.queryParamMap.get('clientId') || '';
    this.uid = this.route.snapshot.queryParamMap.get('uid') || '';
    this.createUserFormControls();

    this.setUserDetails();
    if (this.uid) {
      this.getUserById(this.uid);
    } else {
      this.defaultAPICalls();
    }
  }

  /** Initializes default API calls to populate form data. */
  defaultAPICalls() {
    this.getSuffix();
    this.getExternalIds();
    //this.getAffiliationCodes();
    //this.getManagers();
  }

  /** Creates form controls for adding/editing a user. */
  createUserFormControls() {
    this.addUserFormGroup = this.formBuilder.group(
      {
        affiliationCode: [{ value: '', disabled: this.uid ? true : false }, [Validators.required]],
        userType: ['', [Validators.required]],
        userName: [{ value: '', disabled: this.uid ? true : false }, Validators.required],
        password: [
          { value: '', disabled: this.uid ? true : false },
          [Validators.required, Validators.minLength(8), Validators.pattern(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/)],
        ],
        cPassword: [
          { value: '', disabled: this.uid ? true : false },
          [Validators.required, Validators.minLength(8), Validators.pattern(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/)],
        ],
        requirePasswordChange: [{ value: false, disabled: this.uid ? true : false }, Validators.required],
        status: ['Active', Validators.required],
        firstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        lastName: ['', [Validators.required, Validators.minLength(1)]],
        sex: ['', Validators.required],
        phone: ['', [Validators.required]],
        emailAddress: ['', [Validators.required]],
        birthDate: [''],
        timeZoneName: [''],
        manager: [''],
        externalIds: this.formBuilder.array([]),
      },
      {
        validators: [MustMatch('password', 'cPassword'), oneValidator('emailAddress')],
      },
    );
  }
  /**
   * Handles timezone changes and updates the form accordingly.
   * @param timeZoneName - The name of the selected timezone.
   */
  onChangeTimeZone(timeZoneName: string): void {
    if (timeZoneName) {
      this.updateTimeZone(timeZoneName);
    }
  }
  /**
   * Updates the timezone field in the form.
   * @param timeZoneName - The name of the timezone to set.
   */
  updateTimeZone(timeZoneName: string) {
    this.addUserFormGroup.patchValue({ timeZoneName: timeZoneName });
    this.addUserFormGroup.controls.timeZoneName.updateValueAndValidity();
  }
  /**
   * Handles changes to the selected affiliation code and updates relevant fields.
   */
  onChangeAffiliationCode() {
    if (this.addUserFormGroup?.value?.affiliationCode) {
      this.selectedAffiliationCode =
        this.affiliationCodes[
          this.affiliationCodes.map((code) => code.code).indexOf(this.addUserFormGroup?.value?.affiliationCode)
        ];
      this.addUserFormGroup.patchValue({ userType: this.selectedAffiliationCode?.userType });

      this.addUserFormGroup.patchValue({
        manager:
          this.addUserFormGroup?.value?.userType?.toLowerCase() === 'managed'
            ? this.selectedAffiliationCode?.manager
            : '',
      });
      this.addRemoveFormControlByUserType(this.selectedAffiliationCode?.userType);
      this.addUserFormGroup.get('manager')?.updateValueAndValidity();
      // this.getExternalIds();
      this.getExternalIdsBasedOnUserType(this.addUserFormGroup?.value?.affiliationCode);
      // this.onSelected(this.addUserFormGroup?.value?.affiliationCode)
    }
  }
  /**
   * Updates validation and fetches data based on user type.
   *
   * @param type The user type to update the form controls and fetch data.
   */
  addRemovedAffiliationValidation(type: string) {
    this.addUserFormGroup.get('affiliationCode')?.patchValue('');
    this.addRemoveFormControlByUserType(type);
    this.params = { limit: 200, page: 1, filter: `userType|${type}`, sort: 'code|DESC' };
    this.getAffiliationCodes();
    this.isRequiredShow = false;
    setTimeout(() => {
      this.getExternalIdsBasedOnUserType(type);
    });
  }
  /**
   * Adds or removes form controls based on the user type.
   * @param userType - The type of user selected.
   */
  addRemoveFormControlByUserType(userType: string) {
    if (userType) {
      if (userType.toLowerCase() === 'managed') {
        this.addUserFormGroup.removeControl('suffix');
        this.addUserFormGroup.removeControl('workPhone');
        this.addUserFormGroup.get('manager')?.addValidators([Validators.required]);
        this.addUserFormGroup.get('manager')?.updateValueAndValidity();
        this.getManagers();
      } else {
        this.addUserFormGroup.addControl('suffix', new FormControl());
        this.addUserFormGroup.addControl('workPhone', new FormControl());
        this.addUserFormGroup.get('manager')?.clearValidators();
        this.addUserFormGroup.get('manager')?.setErrors(null);
      }
    }
    this.addUserFormGroup.updateValueAndValidity();
  }
  /**
   * Navigates to the specified breadcrumb URL.
   * @param url - The URL to navigate to.
   */
  onClickBreadcrumbMenu(url: string) {
    let redirectUrl = '';
    if (url) {
      if (this.clientId) {
        redirectUrl = this.uid
          ? `${url}?clientId=${this.clientId}&uid=${this.uid}`
          : `${url}?clientId=${this.clientId}`;
        this.router.navigateByUrl(redirectUrl);
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }
  /**
   * Redirects to the user profile or list based on user ID.
   */
  redirectToUsers() {
    if (this.clientId && this.uid) {
      const url = `/userManagment/profile`;

      this.router.navigate([url], { queryParamsHandling: 'preserve' });
    } else {
      this.router.navigate(['/userManagment/list'], {
        queryParams: { clientId: this.clientId },
        queryParamsHandling: 'merge',
      });

      // this.refresh();
    }
  }
  /**
   * Adds an external ID entry to the form.
   * @param externalId - Optional external ID data to populate the new entry.
   */
  addItem(externalId?: ExternalIds): void {
    this.getIdFormArray().push(this.buildExternalFields(externalId));
  }
  /**
   * Removes an external ID entry from the form by index.
   * @param index - The index of the external ID entry to remove.
   */
  removeItem(index: number) {
    this.getIdFormArray().removeAt(index);
  }

  /**
   * Updates the birth date in the form group.
   * @param dob - The new date of birth.
   */
  updateDOB(dob: string) {
    this.addUserFormGroup.patchValue({ birthDate: dob });
    this.addUserFormGroup.controls.birthDate.updateValueAndValidity();
  }

  /**
   * Sets the error state for date of birth validation.
   * @param error - Boolean indicating if there's an error.
   */
  errorCallbackDOB(error: boolean) {
    this.isValidDOB = error;
  }

  /**
   * Updates the list of external IDs for a user.
   * @param user - The user object containing external IDs.
   */
  updateExternalIds(user: User) {
    if (user) {
      if (user?.externalIds?.length) {
        this.getIdFormArray().clear();
      }
      user.externalIds.map((exId: ExternalIds) => this.addItem(exId));
    }
  }

  /**
   * Builds a FormGroup for external IDs using provided data or defaults.
   * @param x - Optional ExternalIds object to initialize form fields.
   * @returns A FormGroup instance containing 'k' and 'v' form controls.
   */
  buildExternalFields(x?: ExternalIds): FormGroup {
    return new FormGroup({
      k: new FormControl(x?.name || ''),
      v: new FormControl(x?.v || ''),
    });
  }

  /**
   * Retrieves suffix data from the user service and assigns it to the component property.
   */
  getSuffix() {
    this.suffixes = this.usersService.getSuffix();
  }

  /**
   * Constructs a postDataO object based on the current form values.
   * Removes unnecessary fields and adjusts properties as needed.
   * @returns A modified postDataO object for HTTP requests.
   */
  getPostDataO() {
    const postDataO = this.addUserFormGroup.value;
    postDataO.forcePasswordChange = postDataO.requirePasswordChange;
    delete postDataO.requirePasswordChange;
    delete postDataO.cPassword;
    if (this.uid) {
      delete postDataO.affiliationCode;
      delete postDataO.userType;
      delete postDataO.userName;
      delete postDataO.password;
      delete postDataO.status;
      delete postDataO.manager;
      delete postDataO.externalIds;
    }
    if (postDataO?.userType?.toLowerCase() === 'manager' || !postDataO.birthDate) {
      delete postDataO.birthDate;
    }
    return postDataO;
  }

  /**
   * Constructs a demographics object with default values or provided data.
   * @param demographics - Optional Demographics object to initialize values.
   * @returns A demographics object with default or provided values.
   */
  getDemographics(demographics?: Demographics) {
    const demographicsO = {
      additionalDisease: demographics?.additionalDisease || '',
      birthDate: demographics?.birthDate || '',
      emailAddress: demographics?.emailAddress || '',
      firstName: demographics?.firstName || '',
      lastName: demographics?.lastName || '',
      phone: demographics?.phone || '',
      primaryDisease: demographics?.primaryDisease || '',
      requirePasswordChange: demographics?.requirePasswordChange || false,
      sex: demographics?.sex || '',
      suffix: demographics?.suffix || '',
      timeZoneName: demographics?.timeZoneName || '',
      user: demographics?.user || '',
      workPhone: demographics?.workPhone || '',
      _id: demographics?._id || '',
      id: demographics?.id || '',
    };
    return demographicsO;
  }

  /**
   * Callback function to handle user details response and update component state.
   * @param response - UserDetailsResponse object containing user details.
   */
  userByIdCallback(response: UserDetailsResponse) {
    this.setUserDetails(response);
  }

  /**
   * Updates the component's user object with details from a UserDetailsResponse.
   * @param response - Optional UserDetailsResponse object to update the user details.
   */
  setUserDetails(response?: UserDetailsResponse) {
    this.user = {
      affiliationCode: response?.affiliationCode || '',
      emailAddress: response?.demographics.emailAddress || '',
      userType: response?.userType || '',
      userName: response?.userName || '',
      firstName: response?.demographics?.firstName || '',
      requirePasswordChange: response?.demographics?.requirePasswordChange || false,
      lastName: response?.demographics?.lastName || '',
      phone: response?.demographics?.phone || '',
      timeZoneName: response?.demographics?.timeZoneName || this.userTz || '',
      manager: response?.primaryManager || '',
      sex: response?.demographics?.sex || '',
      status: response?.status || 'Active',
      externalIds: response?.externalIds || [],
      birthDate: response?.demographics?.birthDate || '',
      demographics: this.getDemographics(response?.demographics),
    };
    if (this.user?.userType?.toLowerCase() === 'manager') {
      this.user.suffix = this.user.demographics?.suffix;
      this.user.workPhone = this.user.demographics?.workPhone;
    }
    this.getSelectedAffiliationCodeDetails();
    this.updateExternalIds(this.user);
    this.addRemoveFormControlByUserType(this.user?.userType);
    this.addUserFormGroup.patchValue(this.user);
    this.addUserFormGroup.updateValueAndValidity();
  }

  /**
   * Retrieves and sets the selected affiliation code details based on the user's affiliation code.
   * Sets `selectedAffiliationCode` if a matching affiliation code is found in `affiliationCodes`.
   */
  getSelectedAffiliationCodeDetails() {
    if (this.user && this.user.affiliationCode) {
      if (this.affiliationCodes?.length > 0) {
        const temp = this.affiliationCodes.filter(
          (affiliationCode) => affiliationCode.code === this.user.affiliationCode,
        );
        if (temp.length > 0) {
          this.selectedAffiliationCode = temp.reduce((code) => {
            return code;
          });
        }
      }
    }
  }

  /**
   * Retrieves affiliation codes from the backend API and initializes `affiliationCodes`.
   * Calls `getSelectedAffiliationCodeDetails` if `uid` is defined.
   */
  getAffiliationCodes() {
    this.usersService.getAffiliationCodes(this.params).subscribe({
      next: (response) => {
        if (response && response?.data?.length) {
          this.affiliationCodes = response?.data;
          if (this.uid) {
            this.getSelectedAffiliationCodeDetails();
          }
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Retrieves user details by user ID (`uId`) from the backend API.
   * Calls `userByIdCallback`, `getExternalIds`, and `defaultAPICalls` upon successful response.
   * @param uId - User ID to fetch user details.
   */
  getUserById(uId: string) {
    this.usersService.getUserById(uId).subscribe({
      next: (response) => {
        this.userByIdCallback(response);
        this.getExternalIds();
        this.defaultAPICalls();
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Retrieves external IDs based on the current user type from the backend API.
   * Updates `types` with the retrieved external IDs.
   */
  getExternalIds() {
    const userType = this.addUserFormGroup?.value?.userType;
    if (userType) {
      this.usersService.getExternalIds(userType).subscribe({
        next: (response) => {
          this.types = response;
        },
        error: (error) => {
          if (error) {
            //console.log(error);
          }
        },
      });
    }
  }

  /**
   * Retrieves managers from the backend API and initializes `managers`.
   * Sets `id` and `name` for each manager object in `response.data`.
   */
  getManagers() {
    this.usersService.getManagers().subscribe({
      next: (response) => {
        if (response?.data) {
          this.managers = response?.data.map((manager: { id: string; name: string }) => {
            return { id: manager.id, name: manager.name };
          });
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Submits the form data. If `addUserFormGroup` is valid and `isValidDOB` is false,
   * calls `update()` if `uid` is defined; otherwise, calls `save()`.
   */
  submit() {
    this.submitted = true;
    if (this.addUserFormGroup.valid && !this.isValidDOB) {
      if (this.uid) {
        this.update();
      } else {
        this.save();
      }
    }
  }

  /**
   * Updates demographics based on form data by calling `getPostDataO()`.
   * @returns Observable from `usersService.updateDemographics()` method.
   */
  updateDemographics() {
    const postDataO = this.getPostDataO();
    return this.usersService.updateDemographics(postDataO, this.uid);
  }

  /**
   * Updates user details based on form data by constructing `postDataO`.
   * If user type is 'manager', `primaryManager` is omitted from `postDataO`.
   * @returns Observable from `usersService.updateUser()` method.
   */
  updateUser() {
    const postDataO = {
      status: this.addUserFormGroup.value.status,
      primaryManager: this.addUserFormGroup.value.manager,
      externalIds: this.addUserFormGroup.value.externalIds,
    };
    if (this.addUserFormGroup?.value?.userType?.toLowerCase() === 'manager') {
      delete postDataO.primaryManager;
    }
    return this.usersService.updateUser(postDataO, this.uid);
  }

  /**
   * Initiates user update by calling `updateUser()` and `updateDemographics()` concurrently using `forkJoin`.
   * Shows success message using `toastrService` upon successful update and redirects to user list.
   */
  update() {
    forkJoin([this.updateUser(), this.updateDemographics()]).subscribe({
      next: (response) => {
        if (response) {
          this.toastrService.success('User updated successfully');
          this.redirectToUsers();
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Saves new user by calling `addUsers()` from `usersService` with form data `postDataO`.
   * Shows success message using `toastrService` upon successful save and redirects to user list.
   */
  save() {
    const postDataO = this.getPostDataO();
    postDataO.timeZone = postDataO.timeZoneName;
    delete postDataO.timeZoneName;
    // console.log(this.addUserFormGroup.valid);
    if (this.addUserFormGroup.valid) {
      this.usersService.addUsers(postDataO).subscribe({
        next: (response) => {
          if (response) {
            this.toastrService.success('User added successfully');
            this.redirectToUsers();
          }
        },
      });
    }
  }

  /**
   * Retrieves the `externalIds` FormArray from `addUserFormGroup`.
   * @returns FormArray instance of `externalIds`.
   */
  getIdFormArray(): FormArray {
    return this.addUserFormGroup.get('externalIds') as FormArray;
  }

  /**
   * Getter function to retrieve form controls of `addUserFormGroup`.
   * @returns Form controls of `addUserFormGroup`.
   */
  get formControls() {
    return this.addUserFormGroup.controls;
  }

  /**
   * Returns the label (`k`) from `externalIdKeysData`.
   * @param value - Object containing `k` (key) and `v` (value).
   * @returns Label (`k`) from `externalIdKeysData`.
   */
  externalIds(value: externalIdKeysData) {
    const label = value.k;
    return label;
  }

  /**
   * Retrieves external IDs based on the current user type (`userType`) from the backend API.
   * Updates `types` with the retrieved external IDs and clears `externalIds` FormArray.
   * Builds `externalIds` FormFields for each external ID in `response`.
   * Calls `onSelected` with `selectedAffiliationCodeValue` after updating `types`.
   * @param selectedAffiliationCodeValue - Value of selected affiliation code.
   */
  getExternalIdsBasedOnUserType(selectedAffiliationCodeValue: string) {
    const userType = this.addUserFormGroup?.value?.userType;
    if (userType) {
      this.usersService.getExternalIds(userType).subscribe({
        next: (response) => {
          this.types = response;
          this.getIdFormArray().clear();
          response.forEach((item: ExternalIds | undefined) => {
            this.getIdFormArray().push(this.buildExternalFields(item));
          });
          this.onSelected(selectedAffiliationCodeValue);
        },
        error: (error) => {
          if (error) {
            //console.log(error);
          }
        },
      });
    }
  }

  /**
   * Handles validation logic for 'v' controls in 'externalIds' FormArray based on selected value.
   * If 'npiRequired' is true for the selected affiliation code, sets validators for 'v' controls with 'npi' key.
   * Clears validators for 'v' controls when 'npiRequired' is false.
   * Updates 'isRequiredShow' to indicate if 'npi' validation is required.
   * @param value - Selected affiliation code value to determine validation requirements.
   */
  onSelected(value: string): void {
    from(this.affiliationCodes)
      .pipe(
        filter((entry) => entry.name === value),
        map((entry) => entry.npiRequired ?? false),
      )
      .subscribe((npi) => {
        if (npi) {
          this.getIdFormArray().controls.forEach((element) => {
            const kControl = element.get('k');
            const vControl = element.get('v');
            if (kControl && vControl) {
              if (kControl.value.toLowerCase() === 'npi') {
                vControl.setValidators([
                  Validators.required,
                  Validators.pattern(/^\d{1,10}(?:\.\d{1,2})?$/),
                  Validators.pattern(/^[0-9]{10}$/),
                ]);
                this.isRequiredShow = true;
              } else {
                vControl.clearValidators();
                vControl.updateValueAndValidity();
              }
              vControl.updateValueAndValidity();
            }
          });
        } else {
          // Clear validators for each control when npi is false
          this.getIdFormArray().controls.forEach((element) => {
            const vControl = element.get('v');
            if (vControl) {
              // vControl.value.clear();
              vControl.setValue(null);
              vControl.clearValidators();
              vControl.updateValueAndValidity();
              vControl.setValidators([Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.pattern(/^[0-9]{10}$/)]);
              vControl.updateValueAndValidity();
              this.isRequiredShow = false;
            }
          });
        }
      });
  }
}
