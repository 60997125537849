import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { HIDE_LOADER } from '../interfaces/common.entities';
/**
 * Interceptor to handle HTTP responses, manage errors, and handle token refresh for unauthorized requests.
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  /**
   * Constructor to inject dependencies needed by the interceptor.
   * @param toastr The ToastrService for displaying error messages.
   * @param http The HttpService for managing loader state and making HTTP requests.
   * @param authService The AuthServiceService for handling authentication and token management.
   */
  constructor(
    private toastr: ToastrService,
    private http: HttpService,
    private authService: AuthServiceService,
  ) {}
  /**
   * Intercept method to handle HTTP responses and errors.
   * @param request The HTTP request object being intercepted.
   * @param next The HTTP handler to pass the modified request to the next interceptor or backend.
   * @returns An observable of HTTP events.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.http.loader.next(false);
          this.showErrors(error, request);
          return throwError(() => error);
        }),
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse && request.context.get(HIDE_LOADER) === false) {
            this.http.loader.next(false);
          }
          return evt;
        }),
      );
  }
  /**
   * Displays error messages based on the HTTP error response.
   * @param error The HTTP error response object.
   * @param request The original HTTP request object.
   */
  showErrors(error: HttpErrorResponse, request: HttpRequest<unknown>) {
    let message = '';
    if (error) {
      switch (error.error.statusCode) {
        case 401:
          // if (!request.url.includes("platform-login") && !request.url.includes("/auth/token")) {
          if (!request.url.includes('platform-login')) {
            //this.refreshToken(request, next);
            this.toastr.error(error.error.message, error.error.error);
            this.authService.signOut();
          } else {
            message = error.error.message;
          }
          break;
        case 500:
          message = 'Internal Server Error. The request could not be completed.';
          break;
        case 403:
          message = "Forbidden Request, You don't have permission to access this";
          break;
        case 404:
          message = 'Service not found';
          break;
        default:
          message = error.error.message;
          break;
      }
      if (message) {
        this.toastr.error(message, error.error.error);
      }
    }
  }
  /**
   * Attempts to refresh the authentication token and retries the original request.
   * @param request The original HTTP request object that encountered a 401 Unauthorized response.
   * @param next The HTTP handler to pass the modified request to the next interceptor or backend.
   */
  refreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.authService
        .refreshToken(body, { headers: headers })
        .pipe(
          //retry(3),
          switchMap((response: any) => {
            this.authService.setOAuthTokens(response);
            return next.handle(request);
          }),
        )
        .subscribe({
          next: (response) => response,
          error: (error) => {
            if (error) {
              this.toastr.error(error.error.message, error.error.error);
              this.authService.signOut();
            }
          },
        });
    }
  }
}
