import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
/**
 * A pure pipe that filters an array of items based on filter criteria.
 * If `isformGrp` is true, filters by `FormGroup` values; otherwise, filters by `userName` or `name` properties.
 */
@Pipe({
  name: 'filterPopUp',
  pure: true,
})
export class FilterPopUpPipe implements PipeTransform {
  /**
   * Transforms an array of items based on filter criteria.
   *
   * @param items The array of items to be filtered.
   * @param filterData The string used for filtering.
   * @param isformGrp Optional. Specifies if the items are FormGroups. Default is false.
   * @returns The filtered array of items.
   */
  transform(items: unknown[], filterData: string, isformGrp = false): any[] {
    if (!items) {
      items = [];
      return items;
    }
    if (!filterData) {
      return items;
    }
    filterData = filterData?.toString()?.toLowerCase();

    if (isformGrp) {
      const temp: Array<FormGroup> = items as Array<FormGroup>;
      return temp?.filter((ele) => {
        return ele?.value?.name?.toLowerCase()?.includes(filterData);
      });
    } else {
      return items.filter((it: any) => {
        if (it?.userName) {
          return it?.userName?.toLowerCase()?.includes(filterData);
        }
        if (it?.name) {
          return it?.name?.toLowerCase()?.includes(filterData);
        }
      });
    }
  }
}
