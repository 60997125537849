<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'readStatus' | translate }}</h2>
    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <div class="read-by">
    <div class="read-by-item" *ngFor="let participant of readReceiptParticipants">
      <div class="user-image">
        <img [src]="participant.userImageUrl" alt="message-user" />
      </div>
      <p>
        <span class="body-text read-by-user">{{ participant.userFullName }}</span>
        <span class="read-date">{{ participant.date | dateTime: ', ' }}</span>
      </p>
    </div>
  </div>
</div>
