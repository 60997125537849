<div class="video-section active" [ngClass]="[videoLobbyService.pipMode() ? 'video-pip' : '']">
  <div class="vdo-screen" [ngClass]="videoLobbyService.pipMode() ? 'h-100' : ''">
    <div class="video-right-area">
      <div
        class="participants-list"
        [ngClass]="{
          'mob-hide': videoLobbyService.sideMenu() && !videoLobbyService.pipMode(),
          'mob-show': !videoLobbyService.sideMenu(),
          'gallery-view': false,
          'participants-video-panel': showVideoPanelInMobileMode()
        }"
      >
        @if (videoLobbyService.numberOfJoinedParticipants()) {
          @if (cssService.windowSize$ | async; as size) {
            @if (size === 'md' || size === 'sm') {
              <span
                class="video-panel-toggle"
                [ngClass]="{ 'd-none': videoLobbyService.pipMode() }"
                (click)="toggleVideoPanel()"
              >
                <i-feather name="chevron-left"></i-feather>
              </span>
            }
          }
        }

        <div
          class="participant-item organizer"
          [ngClass]="{
            'big-screen-item': !videoLobbyService.numberOfJoinedParticipants() && videoLobbyService.pipMode()
          }"
        >
          @if (videoLobbyService.cameraStatus() !== 'On') {
            <img src="{{ videoLobbyService.userDemographicData.imageUrl }}" />
            <div class="partcipant-info">
              <label>
                {{ videoLobbyService.userDemographicData.firstName }}
                {{ videoLobbyService.userDemographicData.lastName }}
              </label>
            </div>
          }
          <div
            class="local-stream"
            id="local-stream"
            [ngClass]="{
              'invert-video': videoLobbyService.invertVideo(),
              'd-none': videoLobbyService.cameraStatus() !== 'On'
            }"
          ></div>
        </div>

        @for (participant of videoLobbyService.callParticipants(); track $index) {
          @if (participant.remoteData()) {
            <div
              class="participant-item"
              [ngClass]="{ 'big-screen-item': participant.acsId === focusedParticipant() }"
              (click)="focusedParticipant.set(participant.acsId)"
            >
              @if (!participant.streamRenderer()) {
                <img src="{{ participant.imageUrl }}" />
                <div class="partcipant-info">
                  <label>{{ participant.firstName }} {{ participant.lastName }}</label>
                </div>
              }
              <div class="video-iframe" id="{{ participant.id }}"></div>
            </div>
          }
        }

        @if (videoLobbyService.isScreenSharingInProgress() === 'Others') {
          <div
            class="participant-item"
            [ngClass]="{ 'big-screen-item': 'screenShare' === focusedParticipant() }"
            (click)="focusedParticipant.set('screenShare')"
          >
            <div class="video-iframe" id="screenShareStream"></div>
          </div>
        }
      </div>

      <div class="participants-list-right" *ngIf="videoLobbyService.sideMenu() && !videoLobbyService.pipMode()">
        @if (videoLobbyService.sideMenu() === 'participants') {
          <app-meeting-participants></app-meeting-participants>
        }
        @if (videoLobbyService.sideMenu() === 'deviceSettings') {
          <app-meeting-device-settings></app-meeting-device-settings>
        }
        <app-meeting-accessibility-options *ngIf="accessibilty"></app-meeting-accessibility-options>
        <app-meeting-options *ngIf="meetingOptions"></app-meeting-options>
        <app-meeting-info *ngIf="meetingInfo"></app-meeting-info>
      </div>
    </div>
  </div>
  <div
    class="video-icons"
    [ngClass]="videoLobbyService.sideMenu() ? 'mob-hide' : 'mob-show-flex'"
    *ngIf="!videoLobbyService.pipMode()"
  >
    <div class="centered-icons">
      <i-feather
        *ngIf="videoLobbyService.microphoneStatus() === 'Off'"
        name="mic-off"
        class="pointer active clr-secondary"
        aria-label="mic"
        ngbTooltip="Unmute microphone"
        (click)="videoLobbyService.unmuteAudio()"
      ></i-feather>

      <i-feather
        *ngIf="videoLobbyService.microphoneStatus() === 'On'"
        name="mic"
        class="pointer active text-success"
        aria-label="mic"
        ngbTooltip="Mute microphone"
        (click)="videoLobbyService.muteAudio()"
      ></i-feather>

      <i-feather
        *ngIf="videoLobbyService.cameraStatus() === 'Off'"
        name="video-off"
        class="pointer clr-secondary"
        aria-label="video"
        ngbTooltip="Start video"
        (click)="unmuteVideo()"
      ></i-feather>

      <i-feather
        *ngIf="videoLobbyService.cameraStatus() === 'On'"
        name="video"
        class="pointer text-success"
        aria-label="video"
        ngbTooltip="Stop video"
        (click)="muteVideo()"
      ></i-feather>

      <ng-container *appPermissions="'self.VideoLobbyRecording.Write'">
        @if (
          (videoLobbyService.callInProgress$ | async) &&
          videoLobbyService.acsId === videoLobbyService.activeCall()!.host.acsId
        ) {
          <div class="record-icon">
            @if (videoLobbyService.disableRecordingButton()) {
              <div class="recording-loader"></div>
            }
            @if (videoLobbyService.recordingId()) {
              <i-feather
                name="stop-circle"
                class="pointer"
                [ngClass]="videoLobbyService.disableRecordingButton() ? 'disabled' : 'text-danger'"
                aria-label="Stop recording"
                ngbTooltip="Stop recording"
                (click)="videoLobbyService.stopCallRecording()"
              ></i-feather>
            } @else {
              <i-feather
                name="record"
                class="pointer"
                [ngClass]="{ disabled: videoLobbyService.disableRecordingButton() }"
                aria-label="Start Recording"
                ngbTooltip="Start Recording"
                (click)="videoLobbyService.startCallRecording()"
              ></i-feather>
            }
          </div>
        }
      </ng-container>

      <div ngbDropdown>
        <i-feather
          *ngIf="videoLobbyService.callInProgress$ | async"
          name="phone"
          class="pointer vdo-call"
          ngbTooltip="Leave"
          id="ngb-more-dropdown"
          ngbDropdownToggle
        ></i-feather>
        <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown right top-center">
          <div class="dropdown-content" ngbDropdown placement="top-center">
            <div class="section-settings">
              <ul>
                <li ngbDropdownItem>
                  <span class="ngb-dropdown-text" (click)="endCall(false)">{{ 'leave' | translate }}</span>
                </li>
                <li ngbDropdownItem>
                  <span class="ngb-dropdown-text" (click)="endCall(true)">{{ 'endMeeting' | translate }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right-icons">
      @if (videoLobbyService.callInProgress$ | async) {
        <div class="view-only-desktop">
          @if (videoLobbyService.isScreenSharingInProgress() === 'Self') {
            <span class="stop-share ff-medium text-danger pointer" (click)="videoLobbyService.stopScreenShare()">
              {{ 'stopShare' | translate }}
              <i-feather
                name="unshare-screen"
                class="active"
                aria-label="Stop Screen Share"
                ngbTooltip="{{ 'stopScreenSharing' | translate }}"
              ></i-feather>
            </span>
          } @else {
            <i-feather
              name="share-screen"
              class="pointer active"
              aria-label="Start Screen Share"
              ngbTooltip="{{ 'startScreenSharing' | translate }}"
              (click)="videoLobbyService.startScreenShare()"
            ></i-feather>
          }
          <i-feather
            name="users"
            class="pointer active"
            aria-label="users"
            ngbTooltip="{{ 'showParticipants' | translate }}"
            (click)="videoLobbyService.sideMenu.set('participants')"
          ></i-feather>
          <i-feather
            name="file"
            class="pointer active"
            aria-label="file"
            ngbTooltip="{{ 'patientSummary' | translate }}"
            (click)="openPatientDashboard()"
          ></i-feather>

          <!-- <div ngbDropdown>
              <i-feather
                name="grid"
                class="pointer"
                aria-label="grid"
                ngbTooltip="View"
                id="ngb-more-dropdown"
                [ngClass]="{ active: isCallActive }"
                ngbDropdownToggle
              ></i-feather>
              <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown  right">
                <div class="dropdown-content" ngbDropdown placement="top-end">
                  <div class="section-settings">
                    <ul>
                      <li>
                        <i-feather name="grid" class="feather-16 pointer"> </i-feather>
                        <span class="ngb-dropdown-text">Gallery</span>
                      </li>
                      <li>
                        <i-feather name="user" class="feather-16 pointer"> </i-feather>
                        <span class="ngb-dropdown-text">Speaker</span>
                      </li>
                      <li>
                        <i-feather name="layout" class="feather-16 pointer"> </i-feather>
                        <span class="ngb-dropdown-text">Gallery at top</span>
                      </li>
                      <li class="pb-0">
                        <i-feather name="maximize" class="feather-16 pointer"> </i-feather>
                        <span class="ngb-dropdown-text">Full screen</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
      }

      <div ngbDropdown>
        <i-feather
          name="more-horizontal"
          class="pointer active"
          id="ngb-more-dropdown"
          ngbTooltip="More"
          ngbDropdownToggle
        ></i-feather>
        <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown right">
          <div class="dropdown-content" ngbDropdown placement="top-end">
            <div class="section-settings">
              <ul>
                @if (videoLobbyService.callInProgress$ | async) {
                  @if (videoLobbyService.isScreenSharingInProgress() === 'Self') {
                    <li
                      ngbDropdownItem
                      class="flex-between view-only-mob"
                      (click)="videoLobbyService.stopScreenShare()"
                    >
                      <p class="text-danger">
                        <i-feather name="unshare-screen" class="feather-16 pointer" aria-label="Stop Share"></i-feather>
                        <span class="ngb-dropdown-text">{{ 'stopShare' | translate }}</span>
                      </p>
                    </li>
                  } @else {
                    <li
                      ngbDropdownItem
                      class="flex-between view-only-mob"
                      (click)="videoLobbyService.startScreenShare()"
                    >
                      <p>
                        <i-feather name="share-screen" class="feather-16 pointer" aria-label="Start Share"></i-feather>
                        <span class="ngb-dropdown-text">{{ 'shareScreen' | translate }}</span>
                      </p>
                    </li>
                  }
                  <li
                    ngbDropdownItem
                    class="flex-between view-only-mob"
                    (click)="videoLobbyService.sideMenu.set('participants')"
                  >
                    <p>
                      <i-feather name="users" class="feather-16 pointer" aria-label="users"></i-feather>
                      <span class="ngb-dropdown-text">{{ 'showParticipants' | translate }}</span>
                    </p>
                  </li>
                  <li ngbDropdownItem class="flex-between view-only-mob" (click)="openPatientDashboard()">
                    <p>
                      <i-feather name="file" class="feather-16 pointer" aria-label="file"></i-feather>
                      <span class="ngb-dropdown-text">{{ 'patientSummary' | translate }}</span>
                    </p>
                  </li>
                }
                <li ngbDropdownItem class="flex-between" (click)="videoLobbyService.sideMenu.set('deviceSettings')">
                  <p>
                    <i-feather name="settings" class="feather-16 pointer" aria-label="device settings"></i-feather>
                    <span class="ngb-dropdown-text">{{ 'deviceSettings' | translate }}</span>
                  </p>
                </li>

                <!-- <li class="flex-between view-only-mob">
                    <p>
                      <i-feather
                        name="grid"
                        class="feather-16 pointer"
                        aria-label="grid"
                        ngbTooltip="View"
                        id="ngb-more-dropdown"
                      ></i-feather>
                      <span class="ngb-dropdown-text">{{ 'view' | translate }}</span>
                    </p>
                  </li> -->

                <!-- <li (click)="meetingInfo = !meetingInfo">
                    <i-feather name="info" class="feather-16 pointer"> </i-feather>
                    <span class="ngb-dropdown-text">Meeting info</span>
                  </li> -->

                <!-- <li class="flex-between pb-0" (click)="moreSettings = !moreSettings">
                    <p>
                      <i-feather name="settings" class="feather-16 pointer"> </i-feather>
                      <span>Settings</span>
                    </p>
  
                    <i-feather
                      name="{{ moreSettings ? 'chevron-up' : 'chevron-down' }}"
                      class="feather-20 pointer video-lobby-arrow"
                    >
                    </i-feather>
                  </li>
                  <ul *ngIf="moreSettings" class="pt-1 mt-1">
                    <li class="dropdown-item" (click)="meetingOptions = !meetingOptions">
                      <i-feather name="sliders" class="feather-16 pointer"> </i-feather>
  
                      <span class="ngb-dropdown-text">Meeting options</span>
                    </li>
                    <li class="dropdown-item" (click)="accessibilty = !accessibilty">
                      <i-feather name="accessibility" class="feather-16 pointer"> </i-feather>
                      <span class="ngb-dropdown-text">Accessibility</span>
                    </li>
                    <li class="dropdown-item">
                      <i-feather name="message-circle-off" class="feather-16 pointer"> </i-feather>
  
                      <span class="ngb-dropdown-text">Don’t show chat bubbles</span>
                    </li>
                    <li class="dropdown-item pb-0">
                      <i-feather name="bell-off" class="feather-16 pointer"> </i-feather>
                      Mute notifications
                    </li>
                  </ul> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
