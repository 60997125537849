import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCalendar, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { EventInput } from '@fullcalendar/core';
import { FeatherModule } from 'angular-feather';
import { CustomRecurrenceOptionsComponent } from 'src/app/modules/calendar/components/custom-recurrence-options/custom-recurrence-options.component';
import { Attendant, IdFullName, rOptionsList, untilOptions } from 'src/app/modules/calendar/entities/calendar-entities';
import { CalendarService } from 'src/app/modules/calendar/services/calendar.service';
import { PermissionsModule } from 'src/app/modules/permissions/permissions.module';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { TimepickerModule } from 'src/app/modules/shared/component/timepicker/timepicker.module';
import { PipesModule } from 'src/app/modules/shared/pipes/pipes.module';

/**
 * Component for adding or editing calendar events.
 */
@Component({
  selector: 'app-add-edit-event',
  templateUrl: './add-edit-event.component.html',
  styleUrls: ['./add-edit-event.component.scss'],
  standalone: true,
  imports: [
    PipesModule,
    FeatherModule,
    MatDialogModule,
    NgIf,
    MatDatepickerModule,
    ReactiveFormsModule,
    DatePipe,
    TimepickerModule,
    NgFor,
    MatAutocompleteModule,
    PermissionsModule,
    MatSelectModule,
  ],
})
export class AddEditEventComponent {
  /**
   * Reference to the MatCalendar instance.
   */
  @ViewChild('picker', { static: true }) datepicker!: MatCalendar<Date>;
  /**
   * Represents the recurrence rule string for event scheduling.
   */
  rRuleString = '';

  /**
   * Indicates whether the form has been submitted.
   */
  submitted = false;

  /**
   * Toggles the organizer view mode.
   */
  toggleOrganizerView = true;

  /**
   * Specifies the step size (in minutes) for time selection.
   */
  minuteStep = 15;

  /**
   * Specifies the default duration (in minutes) for events.
   */
  duration = 15;

  /**
   * Form group for adding or editing event details.
   */
  addEditEventForm!: FormGroup;

  /**
   * Specifies the default time used in the component.
   */
  defaultTime = new Date();

  /**
   * Represents the current date/time used in the component.
   */
  now = new Date();

  /**
   * List of recurrence options available for event scheduling.
   */
  recurrenceOptions = rOptionsList;

  /**
   * List of options for defining until when a recurrence should last.
   */
  untilOptions = untilOptions;

  /**
   * Selected attendees for the event, categorized into co-owners and members.
   */
  selectedAttendees: { coOwner: IdFullName[]; member: IdFullName[] } = { coOwner: [], member: [] };

  /**
   * Constructor for AddEditEventComponent.
   * @param dialog - MatDialog service for displaying dialogs.
   * @param dialogRef - MatDialogRef for managing dialog references.
   * @param data - Data object containing attendees and event details.
   * @param calendarService - Service for interacting with the calendar.
   * @param permissionsService - Service for managing permissions.
   */
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AddEditEventComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { attendees: Array<{ label: string; users: Attendant[] }>; eventDetails: EventInput },
    private calendarService: CalendarService,
    private permissionsService: PermissionsService,
  ) {
    this.createAddEditFormControls();
    if (this.data?.eventDetails?.id) {
      setTimeout(() => {
        this.setEventDetails();
      }, 0);
    } else {
      this.setInitialEventDates();
    }
  }

  /**
   * Getter for accessing the rruleSetting form group.
   * @returns The rruleSetting form group.
   */
  get rRuleSettingFormGroup() {
    return this.addEditEventForm.get('rruleSetting') as FormGroup;
  }

  /**
   * Getter for accessing form controls.
   * @returns Form controls of the addEditEventForm.
   */
  get f() {
    return this.addEditEventForm.controls;
  }

  /**
   * Creates form controls for adding/editing events.
   * This method initializes the addEditEventForm FormGroup.
   */
  createAddEditFormControls() {
    this.addEditEventForm = this.calendarService.getAddEditEventForm();
  }

  /**
   * Sets event details for editing an existing event.
   * This method populates the form with existing event details.
   */
  setEventDetails() {
    if (this.data?.eventDetails?.recurring) {
      this.data.eventDetails.rOption = this.data?.eventDetails.rruleSetting.freq;
      if (this.data?.eventDetails?.rruleSetting?.until) {
        this.data.eventDetails.untilOption = 'onDate';
        this.data.eventDetails.untilDate = this.data?.eventDetails?.rruleSetting?.until
          ? this.data?.eventDetails?.rruleSetting?.until
          : null;
      } else {
        if (this.data?.eventDetails?.rruleSetting?.count) {
          this.data.eventDetails.untilOption = 'afterNumberOfTimes';
        }
      }
    } else {
      this.data.eventDetails.rOption = 'doesNotRepeat';
      this.data.eventDetails.untilOption = 'doesNotRepeat';
    }
    this.selectedAttendees = this.calendarService.getAttendeesGroupByUserType(this.data?.eventDetails?.attendees);
    this.toggleOrganizerView =
      this.data?.eventDetails?.attendees.map((a: Attendant) => a.user).indexOf(this.getLoggedInUserId()) >= 0
        ? true
        : false;
    this.addEditEventForm.patchValue(this.data?.eventDetails);
    if (this.data?.eventDetails?.attendees?.length) {
      this.data?.eventDetails?.attendees.forEach((attendant: IdFullName) => {
        this.addEditEventForm?.controls?.attendees?.value?.push(attendant);
      });
    }
    this.addEditEventForm.updateValueAndValidity();
  }

  /**
   * Sets initial event dates when creating a new event.
   * This method initializes default values for new events.
   */
  setInitialEventDates() {
    const date = this.data?.eventDetails?.eventStart ? this.data?.eventDetails?.eventStart : new Date();
    this.setEventDates(new Date(date));
  }

  /**
   * Sets the event start and end dates based on the given date.
   * @param date The date to set as the event start date.
   */
  setEventDates(date: Date) {
    this.addEditEventForm?.controls?.eventStart?.patchValue(this.calendarService.getEventStartDate(date));
    this.addEditEventForm?.controls?.eventEnd?.patchValue(
      this.calendarService.getEventEndDate(this.addEditEventForm?.controls?.eventStart?.value),
    );
    this.addEditEventForm.updateValueAndValidity();
  }

  /**
   * Opens a custom recurrence options dialog.
   */
  openCustomRecurrenceOptionsDialog() {
    const customRecurrenceDialogRef = this.dialog.open(CustomRecurrenceOptionsComponent, {
      data: {
        rRuleSettingFormGroup: this.rRuleSettingFormGroup,
      },
    });
    customRecurrenceDialogRef.afterClosed().subscribe({
      next: (response: FormGroup) => {
        if (response) {
          response?.controls?.dtstart?.patchValue(this.addEditEventForm?.controls?.eventStart.value);
          this.addEditEventForm?.controls?.custom.patchValue(true);
          this.addEditEventForm.controls.rruleSetting = response;
          this.addEditEventForm.updateValueAndValidity();
          this.getRRuleString();
        }
      },
      error: (error: Error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Handles changes when the 'Video Visit' checkbox value changes.
   */
  onChangeVideoVisit() {
    this.addEditEventForm?.controls?.calendarType.patchValue(
      this.addEditEventForm?.value?.isVirtualVisit ? 'Virtual Visit' : 'Appointment',
    );
  }

  /**
   * Handles the change in recurrence options.
   * Updates the recurrence form group based on user selection.
   */
  onChangeRecurrenceOptions() {
    this.rRuleSettingFormGroup?.patchValue(this.calendarService.getRRuleSettingFormGroup().value);
    this.addEditEventForm?.controls?.custom.patchValue(false);
    const rOption = this.addEditEventForm?.value?.rOption || '';
    if (rOption && rOption === 'doesNotRepeat') {
      this.addEditEventForm?.controls?.recurring.patchValue(false);
      this.rRuleSettingFormGroup?.controls?.freq.patchValue('DAILY');
    } else {
      this.addEditEventForm?.controls?.recurring.patchValue(true);
      if (rOption && rOption === 'custom') {
        this.rRuleSettingFormGroup?.controls?.freq.patchValue('DAILY');
        this.openCustomRecurrenceOptionsDialog();
      } else {
        this.rRuleSettingFormGroup?.controls?.dtstart?.patchValue(this.addEditEventForm?.controls?.eventStart.value);
        this.rRuleSettingFormGroup?.controls?.freq.patchValue(rOption);
      }
    }
    this.addEditEventForm.updateValueAndValidity();
  }
  /**
   * Handles the change in number of times for recurrence.
   * Updates the count in the recurrence form group.
   *
   * @param $event The event containing the number of times input
   */
  onChangeNumberOfTimes($event: Event): void {
    if ($event?.target) {
      ($event.target as HTMLInputElement).value = ($event.target as HTMLInputElement).value.replace(/[^1-9]/g, '');
      const counter = ($event.target as HTMLInputElement).value
        ? Number(($event.target as HTMLInputElement).value)
        : '';
      this.rRuleSettingFormGroup?.controls?.count.patchValue(counter);
    }
  }

  /**
   * Handles the change in until options for recurrence.
   * Updates the until date in the recurrence form group based on selected option.
   */
  onChangeUntilOptions() {
    const untilOption = this.addEditEventForm?.value?.untilOption?.toLowerCase() || '';
    this.addEditEventForm?.controls?.untilDate.patchValue('');
    this.rRuleSettingFormGroup?.controls?.until.setErrors(null);
    this.rRuleSettingFormGroup?.controls?.count.setErrors(null);
    switch (untilOption) {
      case 'never':
        this.rRuleSettingFormGroup?.controls?.until.patchValue('');
        break;
      case 'afternumberoftimes':
        this.rRuleSettingFormGroup?.controls?.until.patchValue('');
        break;
      default:
        this.rRuleSettingFormGroup?.controls?.until.patchValue('');
        break;
    }
    this.addEditEventForm.updateValueAndValidity();
  }

  /**
   * Handles the change in the all day event checkbox.
   * Adjusts the event start and end times accordingly.
   */
  onChangeAllDay() {
    if (this.addEditEventForm?.controls?.allDay?.value) {
      const start = this.addEditEventForm?.controls?.eventStart?.value
        ? this.addEditEventForm.controls?.eventStart.value
        : new Date();
      this.addEditEventForm?.controls?.eventStart?.patchValue(this.calendarService.startOfDate(start));
      this.addEditEventForm?.controls?.eventEnd?.patchValue(
        this.calendarService.startOfDate(this.calendarService.getNextDate(start)),
      );
    }
  }

  /**
   * Handles the change in event start date.
   * Adjusts the all day checkbox and sets event dates if start date is changed.
   *
   * @param datepicker The MatDatepickerInputEvent containing the selected date
   */
  onChangeEventStart(datepicker: MatDatepickerInputEvent<Date>) {
    if (datepicker && datepicker.value) {
      this.addEditEventForm?.controls?.allDay?.patchValue(false);
      if (this.calendarService.isSameDate(this.addEditEventForm?.controls?.eventStart?.value, datepicker.value)) {
        const date = new Date(
          datepicker.value.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()),
        );
        this.setEventDates(date);
      }
    }
  }

  /**
   * Handles the change in event end date.
   * Adjusts the all day checkbox and sets event end date based on start date.
   *
   * @param datepicker The MatDatepickerInputEvent containing the selected date
   */
  onChangeEventEnd(datepicker: MatDatepickerInputEvent<Date>) {
    if (datepicker && datepicker.value) {
      this.addEditEventForm?.controls?.allDay?.patchValue(false);
      if (this.addEditEventForm?.controls?.eventStart?.value) {
        const endDate = new Date(
          datepicker.value.setHours(
            new Date(this.addEditEventForm?.controls?.eventStart?.value).getHours(),
            new Date(this.addEditEventForm?.controls?.eventStart?.value).getMinutes(),
            new Date(this.addEditEventForm?.controls?.eventStart?.value).getSeconds(),
          ),
        );
        this.addEditEventForm?.controls?.eventEnd?.patchValue(this.calendarService.getEventEndDate(endDate));
      }
    }
  }

  /**
   * Handles the change in time for event start or end.
   * Updates the respective time in the form controls.
   *
   * @param time The selected time
   * @param key 'start' or 'end' indicating event start or end time
   */
  onChangeTime(time: Date, key: string) {
    if (time) {
      if (key === 'start') {
        this.addEditEventForm?.controls?.eventStart?.patchValue(time);
      }
      if (key === 'end') {
        this.addEditEventForm?.controls?.eventEnd?.patchValue(time);
      }
    }
  }

  /**
   * Handles the change in until date for recurrence.
   * Updates the until date in the recurrence form group.
   *
   * @param until The MatDatepickerInputEvent containing the selected until date
   */
  onChangeUntilDate(until: MatDatepickerInputEvent<Date>) {
    if (until && until.value) {
      this.addEditEventForm?.controls?.untilDate.patchValue(until.value);
      this.rRuleSettingFormGroup?.controls?.until.patchValue(until.value);
    }
  }

  /**
   * Handles the click on an attendant.
   * Adds the attendant to the event attendees if not already added.
   *
   * @param attendant The attendant object containing user details
   */
  onClickAttendant(attendant: Attendant) {
    if (attendant?.user) {
      const attendantO = {
        id: attendant.id || attendant.user || '',
        user: attendant.user,
        acsId: attendant.userAcsId,
        fullName: attendant.userFullName,
      };
      if (attendant?.userGroupRelation === 'coOwner') {
        if (this.selectedAttendees.coOwner.map((attendant: IdFullName) => attendant.user).indexOf(attendant.user) < 0) {
          this.selectedAttendees.coOwner.push(attendantO);
        }
      }
      if (attendant?.userGroupRelation === 'member') {
        //restrict to addonly one patient
        this.selectedAttendees.member.forEach((member) => {
          this.removeAttendant(member?.id, attendant?.userGroupRelation);
        });
        this.selectedAttendees.member = [];
        if (this.selectedAttendees.member.map((attendant: IdFullName) => attendant.user).indexOf(attendant.user) < 0) {
          this.selectedAttendees.member.push(attendantO);
        }
      }
      if (
        this.addEditEventForm?.controls?.attendees?.value.map((a: IdFullName) => a.user).indexOf(attendant.user) < 0
      ) {
        this.addEditEventForm?.controls?.attendees?.value?.push(attendantO);
        this.addEditEventForm?.controls?.keywords?.patchValue(attendantO.fullName);
        this.addEditEventForm.updateValueAndValidity();
        setTimeout(() => {
          this.setValidationErrors();
        });
      }
    }
  }

  /**
   * Removes an attendant from the event attendees based on user ID and user group relation.
   *
   * @param id The ID of the attendant to be removed
   * @param userGroupRelation The user group relation of the attendant ('coOwner' or 'member')
   */
  removeAttendant(id: string, userGroupRelation: string) {
    if (userGroupRelation === 'coOwner') {
      const removeIndex = this.selectedAttendees.coOwner.findIndex((owner) => owner.user === id);
      if (removeIndex >= 0) {
        this.selectedAttendees.coOwner.splice(removeIndex, 1);
      }
    }
    if (userGroupRelation === 'member') {
      const removeIndex = this.selectedAttendees.member.findIndex((member) => member.user === id);
      if (removeIndex >= 0) {
        this.selectedAttendees.member.splice(removeIndex, 1);
      }
    }
    if (this.addEditEventForm?.controls?.attendees?.value.map((a: IdFullName) => a.user).indexOf(id) >= 0) {
      const removeIndex = this.addEditEventForm?.controls?.attendees?.value.map((a: IdFullName) => a.user).indexOf(id);
      this.addEditEventForm?.controls?.attendees?.value.splice(removeIndex, 1);
      this.addEditEventForm.updateValueAndValidity();
    }
  }

  /**
   * Adds or removes the logged-in user as an organizer or attendee to/from the event.
   * @param postEventO - The event data to be updated.
   */
  addRemoveOrganizerToAttendees(postEventO: EventInput) {
    const loggedInUserId = this.getLoggedInUserId();
    if (this.toggleOrganizerView && loggedInUserId) {
      if (postEventO && postEventO?.attendees?.length) {
        if (
          this.addEditEventForm.value.hasOwnProperty('isVirtualVisit') &&
          this.addEditEventForm.value.isVirtualVisit
        ) {
          if (postEventO?.attendees.findIndex((a: Attendant) => a.userId === loggedInUserId) < 0) {
            postEventO.attendees.push({ userId: loggedInUserId, acsId: this.getLoggedInUserAcsId() });
          }
        } else {
          if (postEventO?.attendees.findIndex((a: Attendant) => a.id === loggedInUserId) < 0) {
            postEventO.attendees.push({ id: loggedInUserId, acsId: this.getLoggedInUserAcsId() });
          }
        }
      }
    } else {
      const removeIndex = postEventO?.attendees.findIndex((a: Attendant) => a.id === loggedInUserId);
      if (removeIndex >= 0) {
        postEventO.attendees.splice(removeIndex, 1);
      }
    }
  }

  /**
   * Retrieves the ID of the currently logged-in user.
   * @returns The ID of the logged-in user.
   */
  getLoggedInUserId() {
    return this.calendarService.getLoggedInUserId();
  }

  /**
   * Retrieves the ACS ID of the currently logged-in user.
   * @returns The ACS ID of the logged-in user.
   */
  getLoggedInUserAcsId() {
    return this.calendarService.getLoggedInUserAcsId();
  }

  /**
   * Retrieves the recurrence rule settings for the event.
   * @returns The processed recurrence rule settings object.
   */
  getPostRRuleSettings() {
    const rRuleSettingO = this.rRuleSettingFormGroup.value;
    const freq = rRuleSettingO?.freq?.toLowerCase() || 'daily';
    switch (freq) {
      case 'daily':
        delete rRuleSettingO.bymonthday;
        delete rRuleSettingO.byweekday;
        delete rRuleSettingO.bymonth;
        delete rRuleSettingO.bysetpos;
        break;
      case 'weekly':
        delete rRuleSettingO.bymonthday;
        delete rRuleSettingO.bymonth;
        delete rRuleSettingO.bysetpos;
        break;
      case 'monthly':
        delete rRuleSettingO.bymonth;
        if (rRuleSettingO && rRuleSettingO.eachOption && rRuleSettingO.eachOption === 'eachOption') {
          delete rRuleSettingO.bysetpos;
          delete rRuleSettingO.byweekday;
        } else {
          delete rRuleSettingO.bymonthday;
        }
        break;
      case 'yearly':
        delete rRuleSettingO.bymonthday;
        if (rRuleSettingO && !rRuleSettingO.onTheOption) {
          delete rRuleSettingO.bysetpos;
          delete rRuleSettingO.byweekday;
        }
        break;
      default:
        break;
    }
    delete rRuleSettingO.onTheOption;
    delete rRuleSettingO.onTheWeekOption;
    delete rRuleSettingO.eachOption;
    rRuleSettingO.dtstart = rRuleSettingO.dtstart ? this.calendarService.convertLocalToUTC(rRuleSettingO.dtstart) : '';
    rRuleSettingO.until = rRuleSettingO.until ? this.calendarService.convertLocalToUTC(rRuleSettingO.until) : '';
    if (this.addEditEventForm?.controls?.custom?.value) {
      delete rRuleSettingO.count;
    } else {
      delete rRuleSettingO.interval;
    }
    return rRuleSettingO;
  }

  /**
   * Prepares event data for saving or updating.
   * @returns The processed event data object.
   */
  getPostEventData() {
    const postEventO = { ...this.addEditEventForm.value };
    postEventO.calendarType = !postEventO.isVirtualVisit ? postEventO.calendarType : 'Appointment';
    postEventO.eventStart = postEventO.eventStart ? this.calendarService.convertLocalToUTC(postEventO.eventStart) : '';
    postEventO.eventEnd = postEventO.eventEnd ? this.calendarService.convertLocalToUTC(postEventO.eventEnd) : '';
    postEventO.rruleSetting = postEventO.recurring ? this.getPostRRuleSettings() : {};
    postEventO.organizer = postEventO?.organizer?.id ? postEventO.organizer.id : this.getLoggedInUserId();
    postEventO.attendees = postEventO.attendees.map((a: IdFullName) => {
      return { id: a.user, acsId: a.acsId };
    });
    delete postEventO.untilDate;
    delete postEventO.untilOption;
    delete postEventO.keywords;
    delete postEventO.rOption;
    delete postEventO.id;
    //delete postEventO.isVirtualVisit;
    delete postEventO.custom;
    return postEventO;
  }

  /**
   * Prepares event data for video lobby events.
   * @returns The processed video lobby event data object.
   */
  getVideoLobbyPostEventData() {
    const videoLobbyEventO = { ...this.addEditEventForm.value };
    return {
      //client: this.calendarService.getClientId(),
      validFrom: videoLobbyEventO.eventStart ? this.calendarService.convertLocalToUTC(videoLobbyEventO.eventStart) : '',
      validUntil: videoLobbyEventO.eventEnd ? this.calendarService.convertLocalToUTC(videoLobbyEventO.eventEnd) : '',
      //createdBy: this.getLoggedInUserId(),
      host: {
        userId: this.getLoggedInUserId(),
        acsId: this.getLoggedInUserAcsId(),
      },
      attendees: videoLobbyEventO?.attendees.map((a: IdFullName) => {
        return { userId: a.user, acsId: a.acsId };
      }),
      eventTitle: videoLobbyEventO.title ? videoLobbyEventO.title : '',
      notes: videoLobbyEventO.notes ? videoLobbyEventO.notes : '',
    };
  }

  /**
   * Generates a recurrence rule string for displaying recurrence details.
   */
  getRRuleString() {
    this.rRuleString = '';
    if (this.addEditEventForm?.controls?.recurring?.value && this.addEditEventForm?.controls?.custom?.value) {
      const rRuleSettingO = { ...this.getPostRRuleSettings() };
      rRuleSettingO.freq = this.calendarService.getFrequencyIndex(rRuleSettingO.freq);
      if (rRuleSettingO?.byweekday?.length) {
        rRuleSettingO.byweekday = this.calendarService.getByWeekDayIndexes(rRuleSettingO.byweekday);
      }
      rRuleSettingO.dtstart = rRuleSettingO.dtstart
        ? this.calendarService.convertUTCToLocal(rRuleSettingO.dtstart)
        : '';
      rRuleSettingO.until = rRuleSettingO.until ? this.calendarService.convertUTCToLocal(rRuleSettingO.until) : '';
      this.rRuleString = this.calendarService.getRRuleString(rRuleSettingO);
    }
  }

  /**
   * Sets validation errors based on form data.
   */
  setValidationErrors() {
    this.submitted = true;
    if (this.addEditEventForm?.controls?.attendees?.value.length > 0) {
      this.addEditEventForm?.controls?.keywords.setErrors(null);
    } else {
      this.addEditEventForm?.controls?.keywords.setErrors({ required: true });
    }
    if (this.addEditEventForm?.controls?.untilOption?.value === 'afterNumberOfTimes') {
      if (!this.rRuleSettingFormGroup?.controls?.count?.value) {
        this.rRuleSettingFormGroup?.controls?.count.setErrors({ required: true });
      } else {
        this.rRuleSettingFormGroup?.controls?.count.setErrors(null);
      }
    }
    if (this.addEditEventForm?.controls?.untilOption?.value === 'onDate') {
      if (!this.rRuleSettingFormGroup?.controls?.until?.value) {
        this.rRuleSettingFormGroup?.controls?.until.setErrors({ required: true });
      } else {
        this.rRuleSettingFormGroup?.controls?.until.setErrors(null);
      }
    }
    this.addEditEventForm?.controls?.title?.patchValue(this.addEditEventForm?.controls?.title?.value?.trim());
    this.addEditEventForm?.controls?.notes?.patchValue(this.addEditEventForm?.controls?.notes?.value?.trim());
    this.validateEventDates();
  }

  /**
   * Validates event start and end dates.
   * @returns True if event dates are valid, false otherwise.
   */
  validateEventDates() {
    let valid =
      this.addEditEventForm?.controls?.eventStart.value &&
      this.addEditEventForm?.controls?.eventEnd.value &&
      Date.parse(this.addEditEventForm?.controls?.eventEnd.value) >
        Date.parse(this.addEditEventForm?.controls?.eventStart.value)
        ? true
        : false;
    if (valid) {
      this.addEditEventForm?.controls?.eventStart.setErrors(null);
      if (
        this.calendarService.isSameDate(
          new Date(new Date(this.addEditEventForm?.controls?.eventStart.value).setHours(0, 0, 0, 0)),
          new Date(new Date(this.addEditEventForm?.controls?.eventEnd.value).setHours(0, 0, 0, 0)),
        )
      ) {
        if (Date.parse(this.addEditEventForm?.controls?.eventStart.value) > Date.parse(new Date().toString())) {
          valid = true;
          this.addEditEventForm?.controls?.eventStart.setErrors(null);
        } else {
          valid = false;
          this.addEditEventForm?.controls?.eventStart.setErrors({ startGreaterThanCurrentTime: true });
        }
      }
    } else {
      this.addEditEventForm?.controls?.eventStart.setErrors({ startGreaterThanEnd: true });
    }
    this.addEditEventForm.updateValueAndValidity();
    return valid;
  }

  /**
   * Handles form submission.
   */
  submit() {
    this.setValidationErrors();
    if (this?.addEditEventForm?.valid) {
      let postEventO;
      if (this?.addEditEventForm?.value?.isVirtualVisit) {
        postEventO = this.getVideoLobbyPostEventData();
      } else {
        postEventO = this.getPostEventData();
      }
      this.addRemoveOrganizerToAttendees(postEventO);
      delete postEventO.isVirtualVisit;
      const eventId = this.data?.eventDetails?.id || '';
      if (this?.addEditEventForm?.value?.isVirtualVisit) {
        if (eventId) {
          this.updateVideoLobbyEvent(eventId, postEventO);
        } else {
          this.saveVideoLobbyEvent(postEventO);
        }
      } else {
        if (eventId) {
          this.update(eventId, postEventO);
        } else {
          this.save(postEventO);
        }
      }
    }
  }

  /**
   * Save the event to the calendar.
   * @param postEventO - The event data to be saved.
   */
  save(postEventO: EventInput) {
    this.calendarService.addEvent(postEventO).subscribe({
      next: (response) => {
        if (response) {
          this.dialogRef.close(true);
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Updates an event with the provided ID using the calendar service.
   * @param id The ID of the event to update.
   * @param postEventO The updated event data.
   */
  update(id: string, postEventO: EventInput) {
    this.calendarService.updateEvent(id, postEventO).subscribe({
      next: (response) => {
        if (response) {
          this.dialogRef.close(true);
          this.calendarService.calendarRefresh$.next({ refresh: true, delete: { id: null } });
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Deletes an event if user confirms, based on permissions.
   */
  delete() {
    if (!this.permissionsService.hasPermission('self.Calendar.Delete')) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title: 'Confirm remove Event', message: 'Are you sure, you want to remove this event ?' },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        const id = this?.data?.eventDetails?.id;
        if (id) {
          let deleteObservable;
          if (!this?.data?.eventDetails?.isVirtualVisit) {
            deleteObservable = this.calendarService.delete(id);
          } else {
            //const roomId = this?.data?.eventDetails?.roomId;
            deleteObservable = this.calendarService.deleteVideoLobbyEvent(id);
          }
          deleteObservable.subscribe({
            next: (response) => {
              if (response) {
                this.dialogRef.close(true);
                this.calendarService.calendarRefresh$.next({ refresh: false, delete: { id: id } });
              }
            },
            error: (error) => {
              if (error) {
                //console.log(error);
              }
            },
          });
        }
      }
    });
  }

  /**
   * Updates a video lobby event with the provided ID using the calendar service.
   * @param id The ID of the video lobby event to update.
   * @param postEventO The updated video lobby event data.
   */
  updateVideoLobbyEvent(id: string, postEventO: EventInput) {
    this.calendarService.updateVideoLobbyEvent(id, postEventO).subscribe({
      next: (response) => {
        if (response) {
          this.dialogRef.close(true);
          this.calendarService.calendarRefresh$.next({ refresh: true, delete: { id: null } });
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Saves a new video lobby event using the calendar service.
   * @param postEventO The video lobby event data to save.
   */
  saveVideoLobbyEvent(postEventO: EventInput) {
    this.calendarService.addVideoLobbyEvent(postEventO).subscribe({
      next: (response) => {
        if (response) {
          this.dialogRef.close(true);
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }
}
