import { GenericTable } from 'src/app/modules/shared/interfaces/generic-table';
/**
 * Utility class for various helper functions.
 */
export class Utils {
  /**
   * Creates an object conforming to the GenericTable interface.
   *
   * @param value - The value to be assigned to the table.
   * @param routeUrl - Optional URL for routing.
   * @param queryParams - Optional query parameters for the route.
   * @returns A GenericTable object.
   */
  public static createGenericTableKeysData(value: any, routeUrl?: string, queryParams?: object) {
    let tableViewData: GenericTable;
    // eslint-disable-next-line prefer-const
    tableViewData = {
      value: value ? value : '',
      routeUrl: routeUrl ? routeUrl : '',
      queryParams: queryParams ? queryParams : {},
    };
    return tableViewData;
  }

  /**
   * Retrieves the keys of an object.
   *
   * @param __obj - The object to get keys from.
   * @returns An array of keys.
   */
  public static getObjectKeys(__obj: any) {
    return Object.keys(__obj);
  }

  /**
   * Checks if a value is a string.
   *
   * @param value - The value to check.
   * @returns True if the value is a string, false otherwise.
   */
  public static isString(value: unknown) {
    return Object.prototype.toString.call(value) === '[object String]';
  }
  /**
   * Converts a string to camel case.
   *
   * @param value - The string to convert.
   * @returns The camel case string.
   */
  public static camelize(value: string) {
    let camelizeString = value;
    if (this.isString(value)) {
      camelizeString = value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }
    return camelizeString;
  }
  /**
   * Validates if a given URL is valid.
   *
   * @param url - The URL to validate.
   * @returns True if the URL is valid, false otherwise.
   */
  public static isValidUrl(url: string) {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  }
  /**
   * Converts a UTC date string to a local Date object.
   *
   * @param date - The UTC date string.
   * @returns The local Date object.
   */
  public static convertUTCToLocal(date: string | number | Date) {
    return date ? new Date(date) : '';
  }
  /**
   * Converts a local Date object to a UTC date string.
   *
   * @param date - The local Date object.
   * @returns The UTC date string.
   */
  public static convertLocalToUTC(date: Date | undefined) {
    let utcDate;
    if (this.isValidDate(date)) {
      utcDate = this.getISODate(date);
    }
    return utcDate;
  }
  /**
   * Converts a date to ISO string format.
   *
   * @param date - The date to convert.
   * @returns The ISO string format date.
   */
  public static getISODate(date: any) {
    return this.isValidDate(date) ? new Date(date).toISOString() : date;
  }
  /**
   * Validates if a value is a valid Date object.
   *
   * @param date - The value to check.
   * @returns True if the value is a valid Date object, false otherwise.
   */
  public static isValidDate(date: any) {
    const nDateO = date ? new Date(date) : null;
    return date && nDateO && nDateO instanceof Date && isFinite(nDateO.getTime()) ? true : false;
  }
  /**
   * Checks if two dates are the same.
   *
   * @param start - The start date.
   * @param end - The end date.
   * @returns True if the dates are the same, false otherwise.
   */
  public static isSameDate(start: Date = new Date(), end: Date = new Date()) {
    const startDateO = this.isValidDate(start)
      ? new Date(start.setHours(0, 0, 0, 0))
      : new Date(new Date().setHours(0, 0, 0, 0));
    const endDateO = this.isValidDate(start)
      ? new Date(end.setHours(0, 0, 0, 0))
      : new Date(new Date().setHours(0, 0, 0, 0));
    if (startDateO && endDateO) {
      return startDateO.getTime() === endDateO.getTime() ? true : false;
    } else {
      return false;
    }
  }
  /**
   * Checks if a date is in the past.
   *
   * @param date - The date to check.
   * @returns True if the date is in the past, false otherwise.
   */
  public static isPastDate(date: Date = new Date()) {
    const dateO = this.isValidDate(date)
      ? new Date(date.setHours(0, 0, 0, 0))
      : new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    if (dateO && today) {
      return dateO.getTime() < today.getTime() ? true : false;
    } else {
      return false;
    }
  }
  /**
   * Rounds a date to the nearest duration.
   *
   * @param date - The date to round.
   * @param duration - The duration in minutes.
   * @returns The rounded date.
   */
  public static getNearestRoundedDateByDuration(date: Date = new Date(), duration: number) {
    const nDateO = this.isValidDate(date) ? new Date(date) : new Date();
    const coff = 1000 * 60 * duration;
    return new Date(Math.ceil(nDateO.getTime() / coff) * coff);
  }
  /**
   * Adds minutes to a date.
   *
   * @param date - The date to add minutes to.
   * @param duration - The number of minutes to add.
   * @returns The updated date.
   */
  public static addMinutes(date: Date = new Date(), duration: number) {
    const nDateO = Utils.isValidDate(date) ? new Date(date) : new Date();
    return new Date(new Date(nDateO).setMinutes(new Date(nDateO).getMinutes() + duration));
  }
  /**
   * Adds number of days to a date.
   *
   * @param date - The date to add number of days to.
   * @param duration - The number of days to add.
   * @returns The updated date.
   */
  public static addDate(date: Date = new Date(), duration: number) {
    const nDateO = Utils.isValidDate(date) ? new Date(date) : new Date();
    return new Date(new Date(nDateO).setDate(new Date(nDateO).getDate() + duration));
  }
  /**
   * Subtract number of days to a date.
   *
   * @param date - The date to subtract number of days to.
   * @param duration - The number of days to subtract.
   * @returns The updated date.
   */
  public static subtractDate(date: Date = new Date(), duration: number) {
    const nDateO = Utils.isValidDate(date) ? new Date(date) : new Date();
    return new Date(new Date(nDateO).setDate(new Date(nDateO).getDate() - duration));
  }
  /**
   * Gets the previous date from a given date.
   *
   * @param date - The date to get the previous date from.
   * @returns The previous date.
   */
  public static getPreviousDate(date: any) {
    let nDateO = new Date(date);
    if (this.isValidDate(nDateO)) {
      nDateO.setDate(nDateO.getDate() - 1);
      nDateO = new Date(nDateO);
    }
    return nDateO;
  }
  /**
   * Gets the next date from a given date.
   *
   * @param date - The date to get the next date from.
   * @returns The next date.
   */
  public static getNextDate(date: any) {
    let nDateO = new Date(date);
    if (this.isValidDate(nDateO)) {
      nDateO.setDate(nDateO.getDate() + 1);
      nDateO = new Date(nDateO);
    }
    return nDateO;
  }
  /**
   * Calculates the number of days between two dates.
   *
   * @param from - The start date.
   * @param to - The end date.
   * @returns The number of days between the dates.
   */
  public static getNoOfDays(from: Date | string, to: Date | string) {
    let noOfDays = 0;
    if (from) {
      const toDate = to ? new Date(new Date(to).setHours(0, 0, 0, 0)) : new Date(new Date().setHours(0, 0, 0, 0));
      const fromDate = new Date(new Date(from).setHours(0, 0, 0, 0));
      const oneDayInMS = 1000 * 60 * 60 * 24;
      const dateDiff = toDate.getTime() - fromDate.getTime();
      noOfDays = dateDiff / oneDayInMS;
    }
    return noOfDays > 0 ? noOfDays : 0;
  }
  /**
   * Gets the start of the month for a given date.
   *
   * @param date - The date to get the start of the month from.
   * @returns The start of the month date.
   */
  public static startOfMonth(date: Date) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  /**
   * Gets the end of the month for a given date.
   *
   * @param date - The date to get the end of the month from.
   * @returns The end of the month date.
   */
  public static endOfMonth(date: Date) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  /**
   * Gets the start of the day for a given date.
   *
   * @param date - The date to get the start of the day from.
   * @returns The start of the day date.
   */
  public static startOfDate(date: Date = new Date()) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }
  /**
   * Gets the end of the day for a given date.
   *
   * @param date - The date to get the end of the day from.
   * @returns The end of the day date.
   */
  public static endOfDate(date: Date = new Date()) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  }
  public static copy(data: any) {
    if (data) {
      return structuredClone(data);
    }
    return data;
  }
  public static groupBy(array: any[], key: string | number) {
    return array.reduce((result: { [x: string]: any[] }, item: { [x: string]: any }) => {
      const groupKey = item[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    }, {});
  }
}
