import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
  CalendarApi,
  CalendarOptions,
  DayCellMountArg,
  DayHeaderMountArg,
  EventClickArg,
  EventInput,
  MoreLinkContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { AddEditEventComponent } from 'src/app/modules/calendar/components/add-edit-event/add-edit-event.component';
import { CustomHeaderForDatepickerComponent } from 'src/app/modules/calendar/components/custom-header-for-datepicker/custom-header-for-datepicker.component';
import {
  Attendant,
  CalendarContact,
  CalendarSearchOptions,
  MyCalendar,
} from 'src/app/modules/calendar/entities/calendar-entities';
import { CalendarService } from 'src/app/modules/calendar/services/calendar.service';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { CssService } from 'src/app/modules/shared/services/css.service';
import { Utils } from 'src/app/modules/shared/utils/utils';
import { EventDetailsComponent } from '../event-details/event-details.component';
import { EventFilterComponent } from '../event-filter/event-filter.component';
import { MoreEventComponent } from '../more-event/more-event.component';
/**
 * CalendarComponent represents a calendar UI component.
 */
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements AfterViewInit {
  /**
   * Reference to the FullCalendarComponent instance.
   * @ViewChild('calendar')
   */
  @ViewChild('calendar') fullCalendarComponentRef!: FullCalendarComponent;
  /**
   * Reference to the MatCalendar instance used for date picking.
   * @ViewChild('fcDatePicker')
   */
  @ViewChild('fcDatePicker') datepicker!: MatCalendar<Date>;
  /**
   * Flag indicating whether to open the other calendar search box.
   */
  openOtherCalendarSearchBox = false;
  /**
   * Flag indicating if mobile view is enabled.
   */
  mobileView = false;

  /**
   * Index for selecting color codes.
   */
  colorCodeIndex = 0;

  /**
   * Label for the date in the component.
   */
  fcDateLabel = '';

  /**
   * Default color code used when no specific color code is selected.
   */
  defaultColorCode = '#3c66ff';

  /**
   * Array containing different color codes as strings.
   */
  colorCodes: string[] = [
    '#8B0000',
    '#BDB76B',
    '#006400',
    '#CD853F',
    '#996666',
    '#4B0082',
    '#556B2F',
    '#0000FF',
    '#D2691E',
    '#2F4F4F',
    '#E9967A',
    '#FF6347',
    '#800080',
    '#808000',
    '#00BFFF',
    '#B8860B',
    '#696969',
    '#B22222',
    '#663399',
    '#FF1493',
    '#556B2F',
    '#F4A460',
    '#DB7093',
    '#FF00FF',
    '#32CD32',
    '#8A2BE2',
    '#8B4513',
    '#00008B',
    '#DC143C',
    '#008000',
    '#F0E68C',
    '#FF7F50',
    '#FFC0CB',
    '#48D1CC',
    '#FFE4C4',
    '#A0522D',
    '#BC8F8F',
    '#87CEFA',
    '#66CDAA',
    '#EE82EE',
    '#FFDAB9',
    '#FFA07A',
    '#98981b',
    '#00b359',
    '#ff80bf',
    '#1b9898',
    '#8cff1a',
    '#7d3659',
    '#00b3b3',
    '#591b98',
    '#591b98',
    '#981b1b',
    '#800000',
  ];
  /**
   * Array of selected contact IDs.
   */
  selectedContactList: string[] = [this.getLoggedInUserId()];

  /**
   * Array of contacts for the calendar.
   */
  contacts: CalendarContact[] = [];

  /**
   * Array of attendees for events.
   */
  attendees: Array<{ label: string; users: Contact[] }> = [];

  /**
   * Array of user's calendars.
   */
  myCalendars: MyCalendar[] = [
    {
      id: this.getLoggedInUserId(),
      user: this.getLoggedInUserId(),
      userFullName: this.translatePipe.transform('myCalendar') || 'My Calendar',
      colorCode: this.defaultColorCode,
      checked: true,
      myCalendar: true,
    },
  ];
  /**
   * Array of selected other calendars.
   */
  selectedOtherCalendars: CalendarContact[] = [];

  /**
   * Array of events to display.
   */
  events: EventInput[] = [];

  /**
   * Selected date in the calendar.
   */
  selectedDate = new Date();

  /**
   * Do not disturb settings.
   */
  dnd = { doNotDisturb: false };

  /**
   * Full calendar date range object.
   */
  fullCalendarDateO = { fromDate: '', toDate: '' };

  /**
   * Calendar filter options.
   */
  cFilterOptions = { appointment: false, virtualVisit: false };

  /**
   * Search options for calendar.
   */
  searchOptions: CalendarSearchOptions = {
    myCalendars: { keywords: '', openSearchBox: false },
    otherCalendars: { keywords: '', openSearchBox: false },
  };
  /**
   * FullCalendar API instance.
   */
  calendarApi!: CalendarApi;
  /**
   * Options for configuring the FullCalendar.
   */
  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    editable: false,
    headerToolbar: false,
    displayEventTime: false,
    nextDayThreshold: '00:00:00',
    showNonCurrentDates: false,
    fixedWeekCount: false,
    dayMaxEvents: 3,
    locale: 'en',
    dayHeaderDidMount: this.dayHeaderDidMount.bind(this),
    dayCellDidMount: this.dayCellDidMount.bind(this),
    moreLinkContent: this.moreLinkContent.bind(this),
    dateClick: this.dateClick.bind(this),
    eventClick: this.eventClick.bind(this),
    datesSet: this.handleDatesSet.bind(this),
  };
  /**
   * Window size string.
   */
  windowSize = '';
  /**
   * Custom header component for datepicker.
   */
  customHeaderForDatepickerComponent = CustomHeaderForDatepickerComponent;

  /**
   * Constructs a new CalendarComponent instance.
   * @param dialog - MatDialog service for opening dialogs.
   * @param calendarService - CalendarService for managing calendar data.
   * @param permissionsService - PermissionsService for checking user permissions.
   * @param contactsService - ContactsService for managing contacts.
   * @param datePipe - DatePipe for formatting dates.
   * @param translatePipe - TranslatePipe for translating strings.
   * @param cssService - CssService for managing CSS styles.
   */
  constructor(
    public dialog: MatDialog,
    private calendarService: CalendarService,
    private permissionsService: PermissionsService,
    private contactsService: ContactsService,
    private datePipe: DatePipe,
    private translatePipe: TranslatePipe,
    public cssService: CssService,
  ) {
    if (this.permissionsService.hasPermission('self.Contact.Read')) {
      this.getContacts();
    }
    this.getMyCalendars();
    this.fetchDoNotDisturbSetting();
    this.triggerCalendarRefresh();
    this.cssService.windowSize$.subscribe((res: string): void => {
      this.windowSize = res;
    });
  }

  /**
   * This method initializes the FullCalendar API after the view has been initialized,
   * and updates its size to ensure proper rendering.
   */
  ngAfterViewInit(): void {
    this.calendarApi = this.fullCalendarComponentRef.getApi();
    setTimeout(() => {
      this.calendarApi.updateSize();
    }, 100);
  }

  /**
   * Executes when a day cell is mounted in the calendar.
   * @param dayCellDidMount - DayCellMountArg object containing information about the day cell.
   */
  dayCellDidMount(dayCellDidMount: DayCellMountArg) {
    if (dayCellDidMount.el) {
      if (dayCellDidMount.isPast || dayCellDidMount.el.classList.contains('fc-day-past')) {
        dayCellDidMount.el.classList.add('fc-day-disabled');
      } else {
        dayCellDidMount.el.classList.remove('fc-day-disabled');
      }
    }
  }

  /**
   * Executes when a day header is mounted in the calendar.
   * @param dayHeaderMountArg - DayHeaderMountArg object containing information about the day header.
   */
  dayHeaderDidMount(dayHeaderMountArg: DayHeaderMountArg) {
    if (dayHeaderMountArg.el) {
      if (dayHeaderMountArg.isPast) {
        dayHeaderMountArg.el.classList.add('fc-day-disabled');
      } else {
        dayHeaderMountArg.el.classList.remove('fc-day-disabled');
      }
    }
  }

  /**
   * Customizes the more link content in the calendar.
   * @param moreLinkContent - MoreLinkContentArg object containing more link text.
   */
  moreLinkContent(moreLinkContent: MoreLinkContentArg) {
    return moreLinkContent.text.replace(/\+/g, '');
  }

  /**
   * Retrieves data for the dialog based on event click.
   * @param eventClickArg - EventClickArg object containing event click information.
   * @returns Data object containing attendees and event details.
   */
  getDialogData(eventClickArg?: EventClickArg) {
    const data = {
      attendees: this.attendees,
      eventDetails:
        eventClickArg && (eventClickArg?.event?.id || eventClickArg?.event?.extendedProps?.roomId)
          ? eventClickArg.event
          : {},
    };
    return data;
  }

  /**
   * Opens the add/edit event dialog.
   * @param dateClickArg - DateClickArg object containing date click information.
   */
  openAddEditEventDialog(dateClickArg?: DateClickArg) {
    if (!this.permissionsService.hasPermission('self.Calendar.Write')) {
      return;
    }
    const data = this.getDialogData();
    if (dateClickArg) {
      const date =
        this.calendarApi?.view?.type === 'dayGridMonth'
          ? new Date(
              new Date(dateClickArg.date).setHours(
                new Date().getHours(),
                new Date().getMinutes(),
                new Date().getSeconds(),
              ),
            )
          : dateClickArg.date;
      data.eventDetails = {
        eventStart: this.calendarService.getEventStartDate(date),
        allDay: dateClickArg.allDay ? true : false,
      };
    }
    const addEventDialogRef = this.dialog.open(AddEditEventComponent, {
      data: data,
      panelClass: 'full-screen-dialog',
    });
    addEventDialogRef.afterClosed().subscribe({
      next: (response: boolean) => {
        if (response) {
          this.refresh();
        }
      },
      error: (error: Error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Opens the add event dialog.
   */
  addEvent(): void {
    this.openAddEditEventDialog();
  }

  /**
   * Opens the more event dialog.
   */
  moreEvent(): void {
    this.dialog.open(MoreEventComponent);
  }

  /**
   * Executes when a date is clicked in the calendar.
   * @param dateClickArg - DateClickArg object containing date click information.
   */
  dateClick(dateClickArg: DateClickArg) {
    if (dateClickArg?.date && !this.calendarService.isPastDate(dateClickArg?.date)) {
      this.openAddEditEventDialog(dateClickArg);
    }
  }

  /**
   * Executes when an event is clicked in the calendar.
   * @param eventClickArg - EventClickArg object containing event click information.
   */
  eventClick(eventClickArg: EventClickArg) {
    if (eventClickArg && eventClickArg?.event?.id) {
      this.dialog.open(EventDetailsComponent, {
        data: this.getDialogData(eventClickArg),
        panelClass: ['mob-dialog-page', 'full-screen-dialog'],
      });
    }
  }

  /**
   * Changes the view of the calendar.
   * @param view - String representing the new view ('dayGridMonth', 'timeGridWeek', 'timeGridDay', etc.).
   */
  changeView(view: string) {
    if (view) {
      this.removeAllEvents();
      this.calendarApi.changeView(view);
    }
  }

  /**
   * Toggles the Do Not Disturb setting for the calendar.
   */
  toggleDoNotDisturb() {
    this.calendarService.toggleDoNotDisturb(this.dnd).subscribe({
      next: (response) => {
        if (response) {
          this.calendarService.doNotDisturb$.next(this.dnd.doNotDisturb);
          //console.log(error);
        }
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Moves to the next date range in the calendar.
   */
  next() {
    if (this.calendarApi) {
      this.removeAllEvents();
      this.calendarApi.next();
      this.setDatePickerActiveDate();
    }
  }

  /**
   * Moves to the previous date range in the calendar.
   */
  prev() {
    if (this.calendarApi) {
      this.removeAllEvents();
      this.calendarApi.prev();
      this.setDatePickerActiveDate();
    }
  }

  /**
   * Moves to today's date in the calendar.
   */
  today() {
    if (this.calendarApi) {
      this.removeAllEvents();
      this.calendarApi.today();
      this.setDatePickerActiveDate();
    }
  }

  /**
   * Removes all events from the calendar.
   */
  removeAllEvents() {
    this.events = [];
    this.calendarApi.removeAllEvents();
    this.calendarApi.removeAllEventSources();
  }

  /**
   * Refreshes the calendar events.
   */
  refresh() {
    this.removeAllEvents();
    this.fetchEvents(this.fullCalendarDateO.fromDate, this.fullCalendarDateO.toDate);
  }

  /**
   * Removes an event from the calendar by ID.
   * @param id - ID of the event to remove.
   */
  removeEventById(id: string) {
    if (id) {
      const events = this.calendarApi.getEvents();
      events.forEach((event) => {
        if (event.id === id || event?.extendedProps?.roomId === id) {
          event.remove();
        }
      });
      this.calendarService.calendarRefresh$.next({ refresh: false, delete: { id: null } });
    }
  }

  /**
   * Opens a dialog to filter events based on user selection.
   */
  eventFilter(): void {
    const dialogRef = this.dialog.open(EventFilterComponent, { data: { options: this.cFilterOptions } });
    dialogRef.afterClosed().subscribe({
      next: (response: { appointment: boolean; virtualVisit: boolean }) => {
        if (response) {
          this.cFilterOptions = response;
          this.filterEventsSuccessCallBack(response);
        }
      },
      error: (error: Error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Callback function to filter events based on applied filters.
   * @param appliedFilter - Filter options object containing appointment and virtual visit filters.
   */
  filterEventsSuccessCallBack(appliedFilter: { appointment: boolean; virtualVisit: boolean }) {
    this.selectedContactList.forEach((id: string) => {
      const events = this.filterEventsByUserId(id);
      let filteredEvents: EventInput[] = [];
      if (
        (appliedFilter?.appointment && appliedFilter?.virtualVisit) ||
        (!appliedFilter?.appointment && !appliedFilter?.virtualVisit)
      ) {
        filteredEvents = events;
      } else {
        if (appliedFilter?.appointment) {
          filteredEvents = events.filter(
            (e) => e.calendarType && e.calendarType?.replace(/\s+/g, '')?.toLowerCase() !== 'virtualvisit',
          );
        }
        if (appliedFilter?.virtualVisit) {
          filteredEvents = events.filter(
            (e) => e.calendarType && e.calendarType?.replace(/\s+/g, '')?.toLowerCase() === 'virtualvisit',
          );
        }
      }
      this.calendarApi.getEventSourceById(id)?.remove();
      if (filteredEvents?.length > 0) {
        this.calendarApi.addEventSource({ id: id, events: filteredEvents });
      }
      this.calendarApi.getEventSourceById(id)?.refetch();
    });
  }

  /**
   * Filters events by user ID.
   * @param userId - ID of the user to filter events for.
   * @returns Array of events associated with the specified user ID.
   */
  filterEventsByUserId(userId: string) {
    return userId ? this.events.filter((e) => e.extendedProps && e.extendedProps?.user === userId) : [];
  }

  /**
   * Retrieves the logged-in user ID from the calendar service.
   * @returns Logged-in user ID.
   */
  getLoggedInUserId() {
    return this.calendarService.getLoggedInUserId();
  }

  /**
   * Retrieves user demographic information from the calendar service.
   * @returns User demographic information.
   */
  getUserDemographic() {
    return this.calendarService.getUserDemographic();
  }

  /**
   * Retrieves the current date of the calendar view.
   * @returns Current date of the calendar view.
   */
  getCalendarDate() {
    if (!this.fullCalendarComponentRef) {
      return;
    }
    return this.calendarApi.getDate();
  }

  /**
   * Retrieves the current view type of the calendar.
   * @returns Current view type ('dayGridMonth', 'timeGridWeek', 'timeGridDay', etc.).
   */
  getCurrentView() {
    return this.calendarApi ? this.calendarApi.view.type : 'dayGridMonth';
  }

  /**
   * Sets the active date of the datepicker component to match the calendar's active date.
   */
  setDatePickerActiveDate() {
    if (this.datepicker) {
      this.datepicker.activeDate = new Date(this.fullCalendarDateO.fromDate);
      this.datepicker.updateTodaysDate();
    }
  }

  /**
   * Sets the 'from' and 'to' dates of the full calendar date object based on the current calendar view.
   */
  setFullCalendarDateO() {
    this.calendarApi = this.fullCalendarComponentRef.getApi();
    this.fullCalendarDateO.fromDate = Utils.getISODate(this.calendarApi.view.currentStart);
    this.fullCalendarDateO.toDate = Utils.getISODate(this.calendarApi.view.currentEnd);
  }

  /**
   * Sets the label for the full calendar date based on the current calendar view.
   */
  setFullCalendarDateLabel() {
    if (this.calendarApi) {
      switch (this.calendarApi.view.type) {
        case 'dayGridMonth':
          this.fcDateLabel = `${this.datePipe.transform(new Date(this.fullCalendarDateO.fromDate), 'MMMM, yyyy')}`;
          break;
        case 'timeGridWeek':
          this.fcDateLabel = `${this.datePipe.transform(
            new Date(this.fullCalendarDateO.fromDate),
            'MMM dd',
          )} - ${this.datePipe.transform(new Date(this.fullCalendarDateO.toDate), 'MMM dd')}, ${this.datePipe.transform(
            new Date(this.fullCalendarDateO.fromDate),
            'yyyy',
          )}`;
          break;
        case 'timeGridDay':
          this.fcDateLabel = `${this.datePipe.transform(
            new Date(this.fullCalendarDateO.fromDate),
            'EEEE, MMM dd, yyyy',
          )}`;
          break;
        default:
          this.fcDateLabel = `${this.datePipe.transform(new Date(this.fullCalendarDateO.fromDate), 'MMMM, yyyy')}`;
          break;
      }
    }
  }

  /**
   * Removes a user from the 'My Calendars' list.
   * @param myContact - MyCalendar object representing the user to be removed.
   */
  removeUserFromMyCalendars(myContact: MyCalendar) {
    if (myContact.user) {
      const removeIndex = this.myCalendars.map((c) => c.user).indexOf(myContact.user);
      this.myCalendars.splice(removeIndex, 1);
      const contactIndex = this.contacts.map((c) => c.user).findIndex((id) => id === myContact.user);
      if (contactIndex >= 0) {
        this.contacts[contactIndex].myCalendar = false;
      }
      this.calendarService.setMyCalendars(this.myCalendars);
    }
  }

  /**
   * Checks if a user ID exists in 'My Calendars'.
   * @param id - User ID to check.
   * @returns True if the user ID exists in 'My Calendars', false otherwise.
   */
  isUserIdExistInMyCalendars(id: string) {
    return id && this.myCalendars.map((contact) => contact.user).indexOf(id) >= 0 ? true : false;
  }

  /**
   * Determines whether to show other contacts based on search options and selected contact list.
   * @param contact - CalendarContact object representing a contact.
   * @returns True if the contact should be shown, false otherwise.
   */
  isShowOtherContacts(contact: CalendarContact) {
    if (contact.user) {
      if (this.searchOptions.otherCalendars.keywords) {
        return true;
      } else {
        if (this.selectedContactList.indexOf(contact.user) >= 0) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Adds a contact to 'My Calendars' if it doesn't already exist.
   * @param contact - MyCalendar object representing the contact to be added.
   */
  addToMyCalendars(contact?: MyCalendar) {
    if (contact) {
      if (!this.isUserIdExistInMyCalendars(contact.user)) {
        contact.myCalendar = true;
        const myContact = (({ id, user, userFullName, checked, colorCode, myCalendar }) => ({
          id,
          user,
          userFullName,
          checked,
          colorCode,
          myCalendar,
        }))(contact);
        this.myCalendars.push(myContact);
        this.calendarService.setMyCalendars(this.myCalendars);
      }
    }
  }

  /**
   * Event handler for when a date is selected.
   * @param date - Selected date.
   */
  onSelectedChange(date: any) {
    this.selectedDate = date;
    this.calendarApi.gotoDate(this.selectedDate);
    this.changeView('timeGridDay');
    this.mobileView = false;
  }

  /**
   * Event handler for changing calendars contact options.
   * @param contact - CalendarContact object representing the contact.
   */
  onChangeCalendarsContactOptions(contact: CalendarContact) {
    if (contact.checked) {
      this.selectedContactList.push(contact.user);
      this.fetchEventsByUserId(contact.user, this.fullCalendarDateO.fromDate, this.fullCalendarDateO.toDate);
    } else {
      const removeIndex = this.selectedContactList.findIndex((c) => c === contact.user);
      this.selectedContactList.splice(removeIndex, 1);
      this.calendarApi.getEventSourceById(contact.user)?.remove();
    }
  }

  /**
   * Event handler for changing other calendars options.
   * @param contact - CalendarContact object representing the contact.
   */
  onChangeOtherCalendarsOptions(contact: CalendarContact) {
    this.onChangeCalendarsContactOptions(contact);
    const otherCalendars = this.contacts.filter((contact) => !contact.myCalendar);
    this.selectedOtherCalendars = otherCalendars.filter((c) => c.checked === true);
  }

  /**
   * Event handler for changing 'My Calendars' options.
   * @param contact - CalendarContact object representing the contact.
   */
  onChangeMyCalendarsOptions(contact: CalendarContact) {
    this.onChangeCalendarsContactOptions(contact);
    this.calendarService.setMyCalendars(this.myCalendars);
  }

  /**
   * Event handler for when dates are set on the calendar.
   */
  handleDatesSet() {
    this.setFullCalendarDateO();
    setTimeout(() => {
      this.setFullCalendarDateLabel();
    }, 0);
    this.fetchEvents(this.fullCalendarDateO.fromDate, this.fullCalendarDateO.toDate);
  }

  /**
   * Function to determine custom date class for calendar cells.
   */
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    const format = 'MMddYYYY';
    const date = this.datePipe.transform(cellDate, format);
    if (view === 'month') {
      const starts = this.events.map((e: EventInput) => {
        return this.datePipe.transform(new Date(e.eventStart), format);
      });
      const ends = this.events.map((e: EventInput) => {
        return this.datePipe.transform(new Date(e.eventEnd), format);
      });
      const dates = [...new Set([...starts, ...ends])];
      if (dates && dates.length > 0) {
        if (dates.indexOf(date) >= 0) {
          return 'custom-date-class';
        }
      }
    }
    return '';
  };

  /**
   * Retrieves 'My Calendars' from the calendar service.
   */
  getMyCalendars() {
    if (this.calendarService.getMyCalendars()) {
      this.myCalendars = this.calendarService.getMyCalendars();
      this.selectedContactList = this.myCalendars
        .filter((myCalendar: MyCalendar) => myCalendar.checked)
        .map((myCalendar: MyCalendar) => myCalendar.id);
    }
  }

  /**
   * Generates a unique color code.
   * @returns Unique color code.
   */
  getUniqueColorCode() {
    let colorCode = '';
    if (!this.colorCodes[this.colorCodeIndex]) {
      colorCode = '#' + Math.floor(Math.random() * 16777215).toString(16);
      if (this.colorCodes.indexOf(colorCode) < 0) {
        this.colorCodes.push(colorCode);
        return colorCode;
      } else {
        this.getUniqueColorCode();
      }
    } else {
      ++this.colorCodeIndex;
      colorCode = this.colorCodes[this.colorCodeIndex];
      return colorCode;
    }
    return colorCode;
  }

  /**
   * Retrieves color code by user ID.
   * @param userId - User ID to retrieve color code for.
   * @returns Color code associated with the user ID.
   */
  getColorCodeByUserId(userId: string) {
    let colorCode = this.defaultColorCode;
    if (userId) {
      if (this.contacts.filter((contact) => contact.user === userId).map((c) => c.colorCode)?.length) {
        colorCode = this.contacts
          .filter((contact) => contact.user === userId)
          .map((c) => c.colorCode)
          .reduce((d) => d);
      }
    }
    return colorCode;
  }

  /**
   * Retrieves attendees from a list of calendar contacts.
   * @param contacts - Array of CalendarContact objects.
   * @returns Array of attendees.
   */
  getAttendees(contacts: CalendarContact[]) {
    let attendees: Attendant[] = [];
    if (contacts?.length) {
      attendees = contacts?.map((contact: CalendarContact) => {
        return {
          userImageUrl: contact?.userImageUrl,
          userFullName: contact?.userFullName,
          id: contact?._id,
          user: contact?.user,
          userAcsId: contact?.userAcsId || '',
          groupOwnerType: contact?.groupOwnerType,
          userGroupRelation: contact?.userGroupRelation,
        };
      });
    }
    return attendees;
  }

  /**
   * Adds events to the event source for a specific user ID.
   * @param userId - User ID to add events for.
   * @param events - Array of events to add.
   */
  addToEventSource(userId: string, events: EventInput[]) {
    this.events = [...this.events, ...events];
    if (this.calendarApi.getEventSourceById(userId)) {
      events.forEach((e: EventInput) => {
        this.calendarApi.addEvent(e, userId);
      });
    } else {
      this.calendarApi.addEventSource({ id: userId, events: events });
    }
    if (this.cFilterOptions.appointment || this.cFilterOptions.virtualVisit) {
      this.filterEventsSuccessCallBack(this.cFilterOptions);
    }
    if (this.datepicker) {
      this.datepicker.updateTodaysDate();
    }
  }

  /**
   * Callback function for successful retrieval of events.
   * @param userId - User ID for which events were fetched.
   * @param response - Response containing fetched events.
   */
  fetchEventsSuccessCallBack(userId: string, response: { message: Array<EventInput> }) {
    if (response) {
      if (response?.message?.length > 0) {
        const events = response?.message.map((e: EventInput) => {
          e.start = Utils.convertUTCToLocal(e.eventStart);
          e.end = Utils.convertUTCToLocal(e.eventEnd);
          e.virtualVisit = false;
          e.extendedProps = {
            id: e.id,
            duration: e.duration,
            colorCode: this.getColorCodeByUserId(userId),
            user: userId,
          };
          return e;
        });
        this.addToEventSource(userId, events);
      }
    }
  }

  /**
   * Callback function for successful retrieval of video lobby events.
   * @param userId - User ID for which video lobby events were fetched.
   * @param response - Response containing fetched video lobby events.
   */
  videoLobbyEventsSuccessCallBack(userId: string, response: Array<EventInput>) {
    if (response) {
      if (response?.length > 0) {
        const events = response.map((e: EventInput) => {
          e.id = e.id || '';
          e.roomId = e.roomId || '';
          e.title = e.eventTitle || '';
          e.eventStart = Utils.convertUTCToLocal(e.validFrom);
          e.eventEnd = Utils.convertUTCToLocal(e.validUntil);
          e.start = e.eventStart;
          e.end = e.eventEnd;
          e.virtualVisit = true;
          e.calendarType = e.calendarType ? e.calendarType : 'Virtual Visit';
          e.extendedProps = { duration: e.duration, colorCode: this.getColorCodeByUserId(userId), user: userId };
          return e;
        });
        this.addToEventSource(userId, events);
      }
    }
  }

  /**
   * Fetches 'Do Not Disturb' setting from the calendar service.
   */
  fetchDoNotDisturbSetting() {
    this.calendarService.doNotDisturb$.subscribe({
      next: (response) => {
        this.dnd.doNotDisturb = response;
      },
      error: (error) => {
        if (error) {
          //console.log(error);
        }
      },
    });
  }

  /**
   * Subscribes to calendar refresh events from the calendar service.
   */
  triggerCalendarRefresh() {
    this.calendarService.calendarRefresh$.subscribe({
      next: (response) => {
        if (response?.refresh) {
          this.refresh();
        }
        if (response?.delete?.id) {
          this.removeEventById(response?.delete?.id);
        }
      },
    });
  }

  /**
   * Fetches events for selected contacts within a specified date range.
   * @param from - Start date.
   * @param to - End date.
   */
  fetchEvents(from: string, to: string) {
    if (this.selectedContactList.length > 0 && from && to) {
      this.selectedContactList.forEach((id: string) => {
        if (id) {
          this.fetchEventsByUserId(id, from, to);
        }
      });
    }
  }

  /**
   * Fetches events for a specific user ID within a specified date range.
   * @param userId - User ID to fetch events for.
   * @param from - Start date.
   * @param to - End date.
   */
  fetchEventsByUserId(userId: string, from: string, to: string) {
    if (userId) {
      if (this.permissionsService.hasPermission('self.Calendar.Read')) {
        this.getEventsByUserId(userId, from, to);
      }
      if (this.permissionsService.hasPermission('self.VideoLobby.Read')) {
        this.getVideoLobbyEventsByUserId(userId, from, to);
      }
    }
  }

  /**
   * Retrieves contacts and updates attendees based on the contacts service.
   */
  async getContacts() {
    await this.contactsService.updateContacts();
    this.attendees = [
      {
        label: 'Manager',
        users: this.contactsService.managerUsers(),
      },
      {
        label: 'Managed',
        users: this.contactsService.managedUsers(),
      },
    ];
    this.contacts = JSON.parse(JSON.stringify(this.contactsService.contactList())).map((contact: CalendarContact) => {
      contact.id = contact.user;
      contact.checked = false;
      contact.myCalendar =
        this.myCalendars.length > 0
          ? this.myCalendars.map((c) => c.user).indexOf(contact.user) >= 0
            ? true
            : false
          : false;
      contact.colorCode = this.getUniqueColorCode();
      return contact;
    });
  }

  /**
   * Fetches events for a specific user ID within a specified date range.
   * @param userId - User ID to fetch events for.
   * @param from - Start date.
   * @param to - End date.
   */
  getEventsByUserId(userId: string, from: string, to: string) {
    if (userId) {
      this.calendarService.fetchEventsByUserId(userId, from, to).subscribe({
        next: (response) => {
          if (response?.message?.length > 0) {
            this.fetchEventsSuccessCallBack(userId, response);
          }
          //console.log(response);
        },
        error: (error) => {
          if (error) {
            //console.log(error);
          }
        },
      });
    }
  }

  /**
   * Fetches video lobby events for a specific user ID within a specified date range.
   * @param userId - User ID to fetch video lobby events for.
   * @param from - Start date.
   * @param to - End date.
   */
  getVideoLobbyEventsByUserId(userId: string, from: string, to: string) {
    if (userId) {
      this.calendarService
        .fetchVideoLobbyEventsByUserId(userId && userId === this.getLoggedInUserId() ? '' : userId, from, to)
        .subscribe({
          next: (response) => {
            if (response?.length > 0) {
              this.videoLobbyEventsSuccessCallBack(userId, response);
            }
            //console.log(response);
          },
          error: (error) => {
            if (error) {
              //console.log(error);
            }
          },
        });
    }
  }
}
