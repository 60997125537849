<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'resourceRecommendation' | translate }}</h2>
    <span class="dialog-header-icons">
      <i-feather mat-dialog-close name="x" class="pointer" aria-label="Close"></i-feather>
    </span>
  </div>
  <mat-dialog-content>
    <div class="message-recommended-resources">
      <div class="message-recommended-resources-resources">
        {{ data.summary }}
      </div>

      <ng-container *ngFor="let resource$ of resourceData$">
        <div class="message-recommended-resources-item card-base card-varient" *ngIf="resource$ | async as resource">
          <div class="message-recommended-resources-options">
            <div class="recommendation-resource-thumbnail">
              <img [src]="resource.resource.imageUrl" *ngIf="resource.resource.imageUrl"
                alt="recommendation-resource-thumbnail" />
              <div class="recommendation-resource-thumbnail-inner" *ngIf="!resource.resource.imageUrl">
                <i-feather [name]="resource.resource.mimetype" [ngClass]="resource.resource.mimetype"></i-feather>
              </div>
            </div>
            <!-- <div ngbDropdown>
              <i-feather name="more-vertical" class="pointer" ngbDropdownToggle></i-feather>
              <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown">
                <div class="recommendation-resource-rection-icons">
                  <i-feather name="star" class="feather-16"></i-feather>
                  <i-feather name="thumbs-up" class="feather-16"></i-feather>
                  <i-feather name="thumbs-down" class="feather-16"></i-feather>
                </div>
              </div>
            </div> -->
          </div>
          <h2>
            {{ resource.resource.caption }}
          </h2>
          <div class="message-recommended-resources-details">
            <p>{{ resource.resource.description }}</p>
          </div>
          <span class="btn btn-primary" (click)="
              clickedViewNow(resource.resource.contentType, resource.resource.contentUrl || resource.resource.topicId)
            ">{{ 'viewNow' | translate }}</span>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
</div>