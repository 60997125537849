import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsRoutingModule } from './groups-routing.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { GroupComponent } from './components/group/group.component';
import { GroupAddEditComponent } from './components/group-add-edit/group-add-edit.component';
import { GroupViewComponent } from './components/group-view/group-view.component';
import { GroupUseraddComponent } from './components/group-useradd/group-useradd.component';
import { DirectivesModule } from '../shared/directives/directive.module';


@NgModule({
  declarations: [
    GroupComponent,
    GroupAddEditComponent,
    GroupViewComponent,
    GroupUseraddComponent
  ],
  imports: [
    CommonModule,
    GroupsRoutingModule,
    SharedModule,
    DirectivesModule
  ]
})
export class GroupsModule { }
