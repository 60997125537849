import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PipesModule } from 'src/app/modules/shared/pipes/pipes.module';
import { ConfirmationDialogData } from '../../interfaces/common.entities';
/**
 * Confirmation Dialog Component
 *
 * This dialog component is used to display a confirmation message with options.
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  /**
   * Specifies that this component is standalone and does not require other components
   * to function correctly.
   */
  standalone: true,
  /**
   * Import necessary modules for this component.
   */
  imports: [PipesModule, MatDialogModule],
})
export class ConfirmationDialogComponent {
  /**
   * Constructor of ConfirmationDialogComponent
   *
   * @param dialogRef - Reference to the dialog opened by MatDialog.
   * @param data - Data passed to the dialog.
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {}
}
