<div class="dialog-md">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'details' | translate }}</h2>
    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content>
    <div class="summary-analysis-grid">
      @for (metric of data; track $index) {
        @if (metric.labelKey === 'tableInfo') {
          <div class="grid-item">
            <div class="table-wrapper">
              <div class="table-responsive">
                <table>
                  <tr class="border-bottom">
                    <th>{{ 'summary' | translate }}</th>
                    <td>{{ 'medicalAccuracy' | translate }}</td>
                    <td>{{ 'consistency' | translate }}</td>
                    <td>{{ 'quality' | translate }}</td>
                  </tr>
                  @for (info of metric.tableInfo; track $index) {
                    <tr>
                      <th>{{ info.labelKey | translate }}</th>
                      <td>{{ info.accuracy === -1 ? '–' : info.accuracy + '%' }}</td>
                      <td>{{ info.consistency === -1 ? '–' : info.consistency + '%' }}</td>
                      <td>{{ info.quality === -1 ? '–' : info.quality + '%' }}</td>
                    </tr>
                  }
                </table>
              </div>
            </div>
          </div>
        } @else {
          <div class="grid-item">
            <h2 class="display-4">
              @if (metric.score === -1) {
                –
              } @else {
                {{ metric.score }}%
                @if (metric.labelKey === 'toxicity') {
                  &nbsp;<i-feather name="info" class="feather-16 ms-1" [appTooltip]="toxicityAnalysis"></i-feather>
                }
              }
            </h2>
            <div class="progress-bar">
              <div [ngClass]="metric.colorClass" [ngStyle]="{ width: metric.score + '%' }"></div>
            </div>
            <p>{{ metric.labelKey | translate }}</p>
            <p class="text-sm">
              {{ metric.descriptionKey | translate }}
            </p>
          </div>
        }
      }
    </div>

    <div class="session-status-row sm-text">
      <ul class="list-reset">
        <li>
          <span class="status-circle servity-1"></span>
          <span class="status-label">{{ 'high' | translate }}</span>
        </li>

        <li>
          <span class="status-circle servity-2"></span>
          <span>{{ 'moderate' | translate }}</span>
        </li>
        <li>
          <span class="status-circle servity-3"></span>
          <span>{{ 'marginal' | translate }}</span>
        </li>
        <li>
          <span class="status-circle servity-4"></span>
          <span>{{ 'low' | translate }}</span>
        </li>
      </ul>
    </div>
  </mat-dialog-content>
</div>

<ng-template #toxicityAnalysis>
  @if (toxicitySubDetails()) {
    <div class="list-row">
      <span>{{ 'obscene' | translate }}</span>
      <span>{{ toxicitySubDetails()!.obscene === -1 ? '–' : toxicitySubDetails()!.obscene + '%' }}</span>
    </div>
    <div class="list-row">
      <span>{{ 'identityAttack' | translate }}</span>
      <span>{{
        toxicitySubDetails()!.identity_attack === -1 ? '–' : toxicitySubDetails()!.identity_attack + '%'
      }}</span>
    </div>
    <div class="list-row">
      <span>{{ 'insult' | translate }}</span>
      <span>{{ toxicitySubDetails()!.insult === -1 ? '–' : toxicitySubDetails()!.insult + '%' }}</span>
    </div>
    <div class="list-row">
      <span>{{ 'threat' | translate }}</span>
      <span>{{ toxicitySubDetails()!.threat === -1 ? '–' : toxicitySubDetails()!.threat + '%' }}</span>
    </div>
    <div class="list-row">
      <span>{{ 'sexuallyExplicit' | translate }}</span>
      <span>{{
        toxicitySubDetails()!.sexual_explicit === -1 ? '–' : toxicitySubDetails()!.sexual_explicit + '%'
      }}</span>
    </div>
  }
</ng-template>
