<div class="full-message-item" [ngClass]="{ right: communicationUserId === messageUserId }">
  <div class="full-message-item-inner">
    <div class="full-message-icon">
      <img [src]="sender?.userImageUrl || 'assets/images/icons/user.png'" alt="message-user" />
    </div>
    <div class="full-message-content">
      <div class="full-message-content-inner">
        <div class="message-container" [ngClass]="{ 'message-recalled': chatMessage.metadata?.recallDate }">
          <ng-container *ngIf="chatMessage.metadata?.recallDate">
            <p class="message-recall-time">
              <span>{{ 'messageRecalled' | translate }}</span> |
              <span>{{ chatMessage.metadata?.recallDate! | dateTime: ', ' }}</span>
            </p>
            <div class="message-text feather-reset">
              <i-feather name="slash" class="feather-16"></i-feather> {{ 'recalledMessageText' | translate }}
            </div>
          </ng-container>
          <ng-container *ngIf="!chatMessage.metadata?.recallDate">
            <div class="message-body">
              <div class="message-text" [innerHTML]="chatMessage.content?.message | formatMessage | safeHtml"></div>
              <div
                class="recommendation-resource-icon pointer"
                *ngIf="recommendedResources.length"
                (click)="openRecommendedResources()"
              >
                <img src="assets/images/icons/recommendation-resource.svg" alt="" />
              </div>
            </div>
            <div class="attachment-icons" *ngIf="attachments.length">
              <div
                class="attachment-icons-item"
                *ngFor="let attachment of attachments"
                (click)="openAttachmentPreview(attachment)"
              >
                <img [src]="attachment.fileIcon" alt="user" />
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="mode === 'MyMessages'">
          <app-dropdown-menu [xPosition]="'before'" *ngIf="communicationUserId === messageUserId">
            <button mat-menu-item (click)="openReadBy()">
              <i-feather name="eye" class="feather-16"></i-feather>
              <span>{{ 'readStatus' | translate }}</span>
            </button>
            <button mat-menu-item *ngIf="chatMessage.metadata?.recallDate" (click)="openRecalledMessage()">
              <i-feather name="message-square" class="feather-16"></i-feather>
              <span>{{ 'viewMessage' | translate }}</span>
            </button>
            <ng-container *appPermissions="'self.Chat.Write'">
              <button mat-menu-item *ngIf="!chatMessage.metadata?.recallDate" (click)="forwardMessage()">
                <i-feather name="corner-up-right" class="feather-16"></i-feather>
                <span>{{ 'forwardMessage' | translate }}</span>
              </button>
            </ng-container>
            <ng-container *appPermissions="'self.Chat.Update'">
              <button mat-menu-item *ngIf="!chatMessage.metadata?.recallDate" (click)="recallMessage()">
                <i-feather name="trash-2" class="feather-16"></i-feather>
                <span>{{ 'recallMessage' | translate }}</span>
              </button>
            </ng-container>
          </app-dropdown-menu>
          <app-dropdown-menu
            *ngIf="communicationUserId !== messageUserId && (hasWritePermission || chatMessage.metadata?.recallDate)"
          >
            <button mat-menu-item (click)="openReadBy()">
              <i-feather name="eye" class="feather-16"></i-feather>
              <span>{{ 'readStatus' | translate }}</span>
            </button>
            <button mat-menu-item (click)="forwardMessage()">
              <i-feather name="corner-up-right" class="feather-16"></i-feather>
              <span>{{ 'forwardMessage' | translate }}</span>
            </button>
            <button mat-menu-item *ngIf="chatMessage.metadata?.recallDate" (click)="openRecalledMessage()">
              <i-feather name="message-square" class="feather-16"></i-feather>
              <span>{{ 'viewMessage' | translate }}</span>
            </button>
          </app-dropdown-menu>
        </ng-container>
      </div>
      <span class="message-date-text">
        <span>{{ sender?.userFullName || chatMessage.senderDisplayName }}</span> |
        <span>{{ chatMessage.createdOn | dateTime: ', ' }}</span>
      </span>
    </div>
  </div>
</div>
