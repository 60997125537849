import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { FeatherModule } from 'angular-feather';
import { PipesModule } from 'src/app/modules/shared/pipes/pipes.module';
import { DropdownMenuComponent } from './dropdown-menu.component';
/**
 * Module for DropdownMenuComponent.
 */
@NgModule({
  declarations: [DropdownMenuComponent],
  imports: [CommonModule, MatMenuModule, FeatherModule, PipesModule],
  exports: [DropdownMenuComponent, MatMenuModule],
})
export class DropdownMenuModule {}
