<div class="hs-tab-card-title flex-between">
  <h2>{{ 'advanceSearch' | translate }}</h2>
  <div class="dialog-header-icons">
    <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
  </div>
</div>

<form [formGroup]="filterForm">
  <div class="form-group position-relative">
    <label for="from" class="form-label">{{ 'from' | translate }}</label>
    <input type="text" id="from" class="form-control adv-input" formControlName="from" [matAutocomplete]="fromInput" />
    <mat-autocomplete class="message-autocomplete" #fromInput="matAutocomplete" [displayWith]="autocompleteDisplayFn">
      <mat-option *ngFor="let contact of filteredFromContacts | async" [value]="contact">
        <div class="from-suggestion-item">
          <div class="from-suggestion-img">
            <img [src]="contact.userImageUrl" alt="{{ contact.userFullName }}" />
          </div>
          <label class="mb-0">{{ contact.userFullName }}</label>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="form-group">
    <label for="to" class="form-label">{{ 'to' | translate }}</label>
    <input type="text" id="to" class="form-control adv-input" formControlName="to" [matAutocomplete]="toInput" />
    <mat-autocomplete class="message-autocomplete" #toInput="matAutocomplete" [displayWith]="autocompleteDisplayFn">
      <mat-option *ngFor="let contact of filteredToContacts | async" [value]="contact">
        <div class="from-suggestion-item">
          <div class="from-suggestion-img">
            <img [src]="contact.userImageUrl" alt="user" />
          </div>
          <label class="mb-0">{{ contact.userFullName }}</label>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="form-group">
    <label for="subject" class="form-label">{{ 'subject' | translate }}</label>
    <input type="text" id="subject" class="form-control adv-input" formControlName="subject" />
    <div *ngIf="subject?.invalid" class="invalid-feedback">{{ 'charLimitError' | translate }} - 500</div>
  </div>

  <div class="form-group">
    <label for="message" class="form-label">{{ 'body' | translate }}</label>
    <input type="text" id="message" class="form-control adv-input" formControlName="message" />
    <div *ngIf="message?.invalid" class="invalid-feedback">{{ 'charLimitError' | translate }} - 500</div>
  </div>

  <div class="form-group inline-field-group flex-row">
    <div class="form-group">
      <label for="startDate" class="form-label">{{ 'startDate' | translate }}</label>
      <div class="time-input">
        <input
          type="text"
          id="startDate"
          class="form-control"
          placeholder="{{ 'noDateSet' | translate }}"
          formControlName="startDate"
          [matDatepicker]="startDateInput"
          [max]="todayDate"
          readonly
        />
        <i-feather name="calendar" class="feather-20 icon-in-input pointer" (click)="startDateInput.open()"></i-feather>
        <mat-datepicker #startDateInput></mat-datepicker>
      </div>
    </div>

    <div class="form-group">
      <label for="endDate" class="form-label">{{ 'endDate' | translate }}</label>
      <div class="time-input">
        <input
          type="text"
          id="endDate"
          class="form-control"
          placeholder="{{ 'noDateSet' | translate }}"
          formControlName="endDate"
          [matDatepicker]="endDateInput"
          [max]="todayDate"
          readonly
        />
        <i-feather name="calendar" class="feather-20 icon-in-input pointer" (click)="endDateInput.open()"></i-feather>
        <mat-datepicker #endDateInput></mat-datepicker>
      </div>
    </div>
  </div>

  <div class="form-group radio-inline" *ngIf="filterData.mode === 'MyMessages'">
    <div class="form-check-flex align-items-center">
      <input type="checkbox" class="form-check-input me-0" id="unread" formControlName="unread" />
      <label class="check-flex-label clr-primary mb-0" for="unread">{{ 'unread' | translate }}</label>
    </div>
  </div>

  <div class="form-group radio-inline" *ngIf="filterData.mode === 'MyTeamMessages'">
    <div class="form-check-flex align-items-center">
      <input type="checkbox" class="form-check-input me-0" id="unhandled" formControlName="unhandled" />
      <label class="check-flex-label clr-primary mb-0" for="unhandled">{{ 'notReviewed' | translate }}</label>
    </div>
  </div>

  <div class="btn-group">
    <button class="btn btn-secondary" (click)="clickedReset()">{{ 'reset' | translate }}</button>
    <button
      class="btn"
      [ngClass]="filterForm.invalid ? 'btn-disable' : 'btn-primary'"
      (click)="clickedSearch()"
      [disabled]="filterForm.invalid"
    >
      {{ 'search' | translate }}
    </button>
  </div>
</form>
