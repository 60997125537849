<div class="title-bar page-info">
  <h1 *ngIf="!filterEnable">{{ 'userManageMent' | translate }}</h1>
  <h1 *ngIf="filterEnable">{{ 'systemUsers' | translate }}</h1>
</div>
<div *ngIf="usersGroup">
  <div class="card-base">
    <div class="card-titlebar">
      <div class="filter-box">
        <div class="filter-label">
          <span class="pe-1">{{ 'filterBy' | translate }}:</span>
          <span ngbDropdown class="pointer">
            <span id="clientsFilterByStatusDropDown" ngbDropdownToggle>
              <span> {{ selectedFilterByOption.displayText || 'All clients' }}</span>
              <i-feather name="chevron-down" class="feather-16"></i-feather>
            </span>
            <div
              ngbDropdownMenu
              aria-labelledby="clientsFilterByStatusDropDown"
              class="shadow-lg bg-white radius-sm filter-dropdown"
            >
              <a
                ngbDropdownItem
                href="javascript:void(0)"
                (click)="onChangeFilterOptions(fOption)"
                *ngFor="let fOption of filterOptions"
                >{{ fOption.displayText }}</a
              >
            </div>
          </span>
        </div>
        <div class="align-btn">
          <div *appPermissions="'self.Management_User.Write'" class="btn-group">
            <button *ngIf="filterEnable" class="btn btn-primary btn-flex" [routerLink]="['/users/add']">
              <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
            </button>
            <button
              *ngIf="!filterEnable"
              class="btn btn-primary btn-flex"
              [routerLink]="['/userManagment/add']"
              [queryParams]="{ clientId: clientID }"
            >
              <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-table
      class="prov-table-size action-enabled"
      [columns]="columns"
      [displayedColumns]="displayedColumns"
      [paginationOptions]="paginationOptions"
      [data]="usersGroup"
      [tableRender]="usedTable"
      (clickOnActionItems)="toggleUserStatus($event)"
      (delete)="deleteUser()"
      (sort)="sortByColumn($event)"
      (refetch)="refreshUsers($event)"
      (search)="openSearchAndFiltersDialog($event)"
      (filter)="openSearchAndFiltersDialog($event)"
      (clear)="clear($event)"
    ></app-table>
  </div>
</div>
