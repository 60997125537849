import { NgClass, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { TooltipDirective } from 'src/app/modules/shared/component/custom-tooltip/tooltip.directive';
import { PipesModule } from 'src/app/modules/shared/pipes/pipes.module';
import { AIMetricFormattedData, ToxicitySubDetails } from '../../entities/ai-metrics.entities';
/**
 * Component for displaying detailed analysis of a summary.
 */
@Component({
  selector: 'app-summary-detailed-analysis',
  standalone: true,
  imports: [FeatherModule, MatDialogModule, NgbTooltipModule, PipesModule, NgStyle, NgClass, TooltipDirective],
  templateUrl: './summary-detailed-analysis.component.html',
  styleUrl: './summary-detailed-analysis.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryDetailedAnalysisComponent implements AfterViewInit {
  public data: AIMetricFormattedData[] = inject(MAT_DIALOG_DATA);
  public toxicitySubDetails: WritableSignal<ToxicitySubDetails | null> = signal(null);

  public ngAfterViewInit(): void {
    this.toxicitySubDetails.set(this.data.find((metric) => metric.labelKey === 'toxicity')?.toxicityInfo || null);
  }
}
