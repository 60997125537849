<form>
  <h3>{{ 'contacts' | translate }}</h3>
  <div class="form-group search-input">
    <input type="text" class="form-control" placeholder="{{ 'search' | translate }}" [formControl]="searchQuery" />
    <i-feather name="search" class="feather-20 clr-secondary"></i-feather>
  </div>

  @if (displayedManagedUsers().length) {
    <div class="template-group">
      <h6 class="flex-between">
        <span>{{ 'Managed' | clientLabel }}</span>
        <i-feather
          name="chevron-up"
          class="feather-16"
          *ngIf="!collapseManaged"
          (click)="collapseManaged = true"
        ></i-feather>
        <i-feather
          name="chevron-down"
          class="feather-16"
          *ngIf="collapseManaged"
          (click)="collapseManaged = false"
        ></i-feather>
      </h6>
      @if (!collapseManaged) {
        @for (user of displayedManagedUsers(); track user.userAcsId) {
          @if (user.userAcsId && (listAllManagedUsers || user.userAcsId === selectedManagedUserACSId)) {
            <div class="template-radio">
              <input
                type="radio"
                name="managed-users"
                class="form-check-input"
                [value]="user.userAcsId"
                [(ngModel)]="selectedManagedUserACSId"
                (ngModelChange)="updatedManagedUser(user)"
              />
              <div class="user-image">
                <img [src]="user.userImageUrl" [alt]="'managed-user-' + $index" />
              </div>
              <label class="radio-label" [for]="'managed-user-' + $index">
                <span class="user-name">{{ user.userFullName }}</span>
                <span class="sm-text message-contact-detail-text" *ngIf="user.groupName"
                  ><span class="ff-medium">{{ 'Groups' | translate }}:</span> {{ user.groupName }}</span
                >
              </label>
              <i-feather name="file" class="feather-16 contact-summary"></i-feather>
            </div>
          }
        }
      }
    </div>
  }

  @if (displayedManagerUsers().length) {
    <div class="template-group">
      <h6 class="flex-between">
        <span>{{ 'Manager' | clientLabel }}</span>
        <i-feather
          name="chevron-up"
          class="feather-16"
          *ngIf="!collapseManager"
          (click)="collapseManager = true"
        ></i-feather>
        <i-feather
          name="chevron-down"
          class="feather-16"
          *ngIf="collapseManager"
          (click)="collapseManager = false"
        ></i-feather>
      </h6>

      @if (!collapseManager) {
        @for (user of displayedManagerUsers(); track $index) {
          @if (user.userAcsId && user.userAcsId !== communicationUserId) {
            <div class="template-radio">
              <input
                type="checkbox"
                [id]="'my-support-' + $index"
                name="my-supports"
                class="form-check-input"
                [checked]="isSelected(user.userAcsId)"
                (change)="updateParticipants(user)"
              />
              <div class="user-image">
                <img [src]="user.userImageUrl" [alt]="'my-support-' + $index" />
              </div>
              <label class="radio-label" [for]="'my-support-' + $index">
                <span class="body-text">{{ user.userFullName }}</span>
                <span *ngIf="user.groupName">{{ 'Groups' | translate }}: {{ user.groupName }}</span>
              </label>
            </div>
          }
        }
      }
    </div>
  }
</form>
