<div class="hs-tab-card-title flex-between">
  <h2>
    @if (keywords().length) {
      {{ 'keyword' | translate }}
    }
    @if (keywords().length && intents().length) {
      &#38;
    }
    @if (intents().length) {
      {{ 'intent' | translate }}
    }
    {{ 'analysis' | translate }}
  </h2>
  <div class="dialog-header-icons">
    <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
  </div>
</div>
<mat-dialog-content>
  @for (keyword of keywords(); track $index) {
    <div class="keyword-item">
      <span class="keyword-bullet" [ngClass]="keyword.severityClass"></span>
      <strong>{{ 'keyword' | translate }}:</strong>
      <span>{{ keyword.category }}</span>
    </div>
  }

  @for (intent of intents(); track $index) {
    <div class="intent-reasoning-container">
      <div class="keyword-item">
        <span class="keyword-bullet" [ngClass]="intent.severityClass"></span>
        <strong>{{ 'intent' | translate }}:</strong>
        <span>{{ intent.category }}</span>
      </div>
      @for (reasoning of intent.rationale; track $index) {
        <div class="intent-reasoning">
          <strong>{{ 'reasoning' | translate }}: </strong>
          <span>{{ reasoning }}</span>
        </div>
      }
    </div>
  }
</mat-dialog-content>
