import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenAIService {
  /**
   * API version used for API requests.
   */
  apiVersion: string = environment.url.version;
  systemPrompt = `You are a nurse in Dr. Drummond's office. You are responsible for answering patient questions as a case manager. Patients are either pre- or post-surgery. You have access to their medical information, surgery details, diagnosis, hospital, and insurance coverage.`;
  #http!: HttpClient;

  constructor(httpBackend: HttpBackend) {
    this.#http = new HttpClient(httpBackend);
  }
  sendPrompt(prompt: string): Observable<any> {
    const apiUrl = `${environment.url.azureOpenAi}/openai/deployments/gpt4-32k-us-e2/chat/completions?api-version=2023-07-01-preview`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': `${environment.azureOpenAiKey}`,
    });

    const body = {
      messages: this.buildMessages(prompt),
      temperature: 0.3,
      // stream: true, // This is required for streaming responses
    };

    return this.#http.post(apiUrl, body, { headers });
  }
  // Helper function to build the chat messages
  buildMessages(userQuestion: string) {
    return [
      { role: 'system', content: this.systemPrompt },
      {
        role: 'user',
        content: userQuestion,
      },
    ];
  }
}
