<div class="vl-dialog-container" [ngClass]="videoLobbyService.pipMode() ? 'pip-card' : 'dialog-xl'">
  <div
    class="hs-tab-card-title flex-between"
    cdkDragBoundary=".cdk-global-overlay-wrapper"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragFreeDragPosition]="videoLobbyService.dialogPosition()"
    [cdkDragDisabled]="!videoLobbyService.pipMode()"
  >
    <h2>
      <span *ngIf="!videoLobbyService.pipMode()">{{ 'videoLobby' | translate }}</span>
      <span class="record-video" *ngIf="videoLobbyService.callInProgress$ | async">
        {{ videoLobbyService.callDuration() }}

        <i-feather name="record" class="active feather-16 ms-2" *ngIf="videoLobbyService.recordingId()"></i-feather>
        <span *ngIf="videoLobbyService.recordingId() && !videoLobbyService.pipMode()">
          {{ 'recording' | translate }}...
        </span>
      </span>
    </h2>
    <span class="dialog-header-icons">
      <i-feather
        [name]="videoLobbyService.microphoneStatus() === 'On' ? 'mic' : 'mic-off'"
        class="pointer"
        [ngClass]="videoLobbyService.microphoneStatus() === 'On' ? 'text-success' : 'clr-secondary'"
        *ngIf="videoLobbyService.pipMode()"
        (click)="
          videoLobbyService.microphoneStatus() === 'On'
            ? videoLobbyService.muteAudio()
            : videoLobbyService.unmuteAudio()
        "
      ></i-feather>
      <i-feather
        name="minimize-2"
        class="pointer"
        *ngIf="videoLobbyService.activeCall() && !videoLobbyService.pipMode()"
        (click)="videoLobbyService.enterPip()"
      ></i-feather>
      <i-feather
        name="maximize-2"
        class="pointer"
        *ngIf="videoLobbyService.activeCall() && videoLobbyService.pipMode()"
        (click)="videoLobbyService.exitPip()"
      ></i-feather>
      <i-feather
        name="unshare-screen-white"
        class="pointer"
        aria-label="Stop Screen Share"
        ngbTooltip="{{ 'stopScreenSharing' | translate }}"
        *ngIf="videoLobbyService.pipMode() && videoLobbyService.isScreenSharingInProgress()"
        (click)="videoLobbyService.stopScreenShare()"
      ></i-feather>
      <i-feather
        name="share-screen-white"
        class="pointer"
        aria-label="Start Screen Share"
        ngbTooltip="{{ 'startScreenSharing' | translate }}"
        *ngIf="videoLobbyService.pipMode() && !videoLobbyService.isScreenSharingInProgress()"
        (click)="videoLobbyService.startScreenShare()"
      ></i-feather>
      <i-feather
        name="phone"
        class="pointer vdo-call"
        *ngIf="videoLobbyService.pipMode()"
        (click)="stopLocalVideoStream()"
        mat-dialog-close
      ></i-feather>
      <i-feather
        mat-dialog-close
        name="x"
        class="pointer"
        aria-label="Close"
        *ngIf="!videoLobbyService.pipMode()"
        (click)="stopLocalVideoStream()"
      ></i-feather>
    </span>
  </div>

  <div class="video-lobby-wrap">
    <app-video-list
      *ngIf="!videoLobbyService.pipMode()"
      [ngClass]="[!videoLobbyService.activeCall() ? 'mob-show' : 'mob-hide']"
    ></app-video-list>
    @if (showVideoCallComponent()) {
      <app-video-call
        [ngClass]="{
          'w-100': videoLobbyService.pipMode(),
          'mob-show': videoLobbyService.activeCall(),
          'mob-hide': !videoLobbyService.activeCall()
        }"
      ></app-video-call>
    }
  </div>
</div>
