import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChatParticipant } from '@azure/communication-chat';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { MessageService } from '../../services/message.service';

/**
 * Component for managing message contacts.
 */
@Component({
  selector: 'app-message-contacts',
  templateUrl: './message-contacts.component.html',
  styleUrls: ['./message-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageContactsComponent implements OnInit, OnChanges {
  /** List of participants in the chat. */
  @Input() public participantList: ChatParticipant[] = [];
  /** Selected managed user. */
  @Input() public selectedManagedUser: ChatParticipant | null = null;
  /** Event emitter for updated managed user. */
  @Output() public updatedManagedUserEvent = new EventEmitter<ChatParticipant | null>();
  /** Event emitter for updated participant list. */
  @Output() public updatedParticipantList = new EventEmitter();

  /** Signal for displayed managed users. */
  public displayedManagedUsers: WritableSignal<Contact[]> = signal([]);
  /** Signal for displayed manager users. */
  public displayedManagerUsers: WritableSignal<Contact[]> = signal([]);
  /** Flag to indicate listing all managed users. */
  public listAllManagedUsers = true;
  /** Communication user ID. */
  public communicationUserId = '';
  /** Selected managed user ACS ID. */
  public selectedManagedUserACSId = '';
  /** Flag to collapse manager section. */
  public collapseManager = false;
  /** Flag to collapse managed section. */
  public collapseManaged = false;
  /** Form control for search query. */
  public searchQuery: FormControl<string> = new FormControl('', {
    nonNullable: true,
  });
  /** Contacts service instance. */
  #contactsService = inject(ContactsService);
  /** Message service instance. */
  #messageService = inject(MessageService);
  /** Change detector instance. */
  #change = inject(ChangeDetectorRef);
  /**
   * Initializes the component.
   */
  public async ngOnInit(): Promise<void> {
    await this.#contactsService.updateContacts();
    this.communicationUserId = this.#messageService.communicationUserId;
    this.displayedManagedUsers.set(this.#contactsService.managedUsers());
    this.displayedManagerUsers.set(this.#contactsService.managerUsers());

    if (!this.selectedManagedUser) this.selectedManagedUser = this.findSelectedManagedUser();
    this.selectedManagedUserACSId =
      (this.selectedManagedUser?.id as CommunicationUserIdentifier)?.communicationUserId || '';
    this.#change.markForCheck();

    this.searchQuery.valueChanges.subscribe((res: string): void => {
      this.displayedManagedUsers.update(() =>
        this.#contactsService
          .managedUsers()
          .filter((contact: Contact): boolean =>
            contact.userFullName.toLocaleLowerCase().includes(res.toLocaleLowerCase()),
          ),
      );
      this.displayedManagerUsers.update(() =>
        this.#contactsService
          .managerUsers()
          .filter((contact: Contact): boolean =>
            contact.userFullName.toLocaleLowerCase().includes(res.toLocaleLowerCase()),
          ),
      );
    });
  }

  /**
   * Responds to changes in input properties.
   */
  public ngOnChanges(): void {
    this.selectedManagedUserACSId =
      (this.selectedManagedUser?.id as CommunicationUserIdentifier)?.communicationUserId || '';
    this.#change.detectChanges();
  }
  /**
   * Updates the participant list with the specified user.
   *
   * @param user - The user to update in the participant list.
   */
  public updateParticipants(user: Contact): void {
    const index: number = this.participantList.findIndex(
      (participant: ChatParticipant): boolean =>
        (participant.id as CommunicationUserIdentifier).communicationUserId === user.userAcsId,
    );
    if (index > -1) {
      this.participantList.splice(index, 1);
      this.updatedParticipantList.emit();
      return;
    }
    this.participantList.push({
      id: {
        communicationUserId: user.userAcsId || '',
      },
      displayName: user.userFullName,
    });
    this.updatedParticipantList.emit();
  }
  /**
   * Checks if the specified ACS ID is selected.
   *
   * @param acsId - The ACS ID to check.
   * @returns True if the ACS ID is selected; otherwise, false.
   */
  public isSelected(acsId: string): boolean {
    for (let i = 0; i < this.participantList.length; i++) {
      if ((this.participantList[i].id as CommunicationUserIdentifier).communicationUserId === acsId) {
        return true;
      }
    }
    return false;
  }
  /**
   * Emits an event for updated managed user.
   *
   * @param user - The updated user.
   */
  public updatedManagedUser(user: Contact): void {
    this.updatedManagedUserEvent.emit({
      id: {
        communicationUserId: user.userAcsId || '',
      },
      displayName: user.userFullName,
    });
  }
  /**
   * Finds and returns the selected managed user from the participant list.
   *
   * @returns The selected managed user; otherwise, null.
   */
  private findSelectedManagedUser(): ChatParticipant | null {
    for (let i = 0; i < this.participantList.length; i++) {
      for (let j = 0; j < this.#contactsService.managedUsers().length; j++) {
        if (
          (this.participantList[i].id as CommunicationUserIdentifier).communicationUserId ===
          this.#contactsService.managedUsers()[j].userAcsId
        ) {
          this.listAllManagedUsers = false;
          return {
            id: {
              communicationUserId: this.#contactsService.managedUsers()[j].userAcsId || '',
            },
            displayName: this.#contactsService.managedUsers()[j].userFullName,
          };
        }
      }
    }
    return null;
  }
}
