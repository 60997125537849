import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddEditUserGroupComponent } from '../shared/component/add-edit-user-group/add-edit-user-group.component';
import { DirectivesModule } from '../shared/directives/directive.module';
import { SharedModule } from '../shared/shared.module';
import { AccessComponent } from './components/access/access.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { ManagedAddComponent } from './components/managed-add-dialog/managed-add.component';
import { ManagedDialogComponent } from './components/managed-group-dialog/managed-group.component';
import { ManagedUserComponent } from './components/managed-user-dialog/managed-user.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { UsersListingComponent } from './components/users-listing/users-listing.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  declarations: [
    AddUserComponent,
    UsersListingComponent,
    ProfileEditComponent,
    AccessComponent,
    ManagedDialogComponent,
    ManagedUserComponent,
    ManagedAddComponent,
  ],
  imports: [CommonModule, SharedModule, DirectivesModule, UsersRoutingModule, AddEditUserGroupComponent],
})
export class UsersModule {}
