import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Utils } from 'src/app/modules/shared/utils/utils';
/**
 * Component for selecting and manipulating time values.
 */
@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent implements OnChanges {
  /** Input to bind the form control for time selection. */
  @Input() timeControl!: any;
  /** Input to specify the initial value for time selection. */
  @Input() timeControlValue!: Date;
  /** Default time value when no specific time is provided. */
  @Input() defaultTime: any = new Date(new Date().setHours(0, 0, 0, 0));
  /** Step interval (in minutes) for minute selection. */
  @Input() minuteStep = 5;
  /** Event emitted when the selected time changes. */
  @Output() changeTime: EventEmitter<Date> = new EventEmitter<Date>();
  /** Internal representation of the selected time. */
  time!: any;

  /**
   * Lifecycle hook called when input properties change.
   * Initializes the component with the appropriate time value.
   */
  ngOnChanges(): void {
    this.time = this.timeControl?.value ? this.getTime(this.timeControl?.value) : this.getTime(this.defaultTime);
    if (this.timeControlValue) {
      this.timeControlValue = this.time;
    }
  }
  /**
   * Converts a time string into an object with hour, minute, and second components.
   * @param time The time string to convert.
   * @returns An object containing hour, minute, and second components.
   */
  getTime(time: string) {
    const timeDateO = new Date(time);
    const timeO = {
      hour: timeDateO.getHours(),
      minute: timeDateO.getMinutes(),
      second: 0,
    };
    return timeO;
  }
  /**
   * Handles changes in the selected time and emits the updated time value.
   * @param time The updated time object containing hour, minute, and second components.
   */
  timeChangeHandler(time: TimePickerO) {
    let timeDateO = this.timeControl?.value ? new Date(this.timeControl?.value) : new Date(this.defaultTime);
    if (Utils.isValidDate(timeDateO)) {
      timeDateO = new Date(timeDateO.setHours(time.hour, time.minute, time.second));
      if (this.timeControl?.patchValue) {
        this.timeControl?.patchValue(timeDateO);
      }
      this.changeTime.emit(timeDateO);
    }
  }
}
/**
 * Interface representing a time object with hour, minute, and second components.
 */
interface TimePickerO {
  /** The hour component of the time. */
  hour: number;

  /** The minute component of the time. */
  minute: number;

  /** The second component of the time. */
  second: number;
}
