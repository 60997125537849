import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { debounceTime, distinctUntilChanged, map, Observable, of, switchMap } from 'rxjs';
import { PatientTabsItem } from 'src/app/modules/dashboard/health-summary-tabs/entities/tabs.entities';
import { TabsService } from 'src/app/modules/dashboard/health-summary-tabs/services/tabs.service';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { PipesModule } from '../../pipes/pipes.module';

/**
 * Component representing a search dialog.
 */
@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, FeatherModule, NgFor, MatDialogModule, PipesModule],
})
export class SearchDialogComponent implements OnInit {
  /**
   * Form control for patient search input.
   */
  public patient: FormControl = new FormControl('');
  /**
   * Signal to store the list of patients.
   */
  public patientList: WritableSignal<Patient[]> = signal([]);

  /**
   * Constructs the SearchDialogComponent.
   * @param commonService - Service for common functionality and API calls.
   * @param matDialogRef - Reference to the MatDialog instance for the dialog component.
   * @param router - Router service for navigation within the application.
   * @param tabs - Service for managing tabs within the application.
   */
  public constructor(
    private commonService: CommonService,
    private matDialogRef: MatDialogRef<SearchDialogComponent>,
    private router: Router,
    private tabs: TabsService,
  ) {}

  /**
   * Sets up the patient search functionality with debounce and switches to new Observable.
   */
  public ngOnInit(): void {
    this.patient.valueChanges
      .pipe(
        map((name: string): string => name.trim()),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((name: string): Observable<PatientListData | null> => {
          if (name && name.length > 2) {
            return this.getPatientList(name);
          }
          return of(null);
        }),
      )
      .subscribe((res: PatientListData | null): void => {
        if (!res) {
          this.patientList.set([]);
          return;
        }
        this.patientList.set(res.data);
      });
  }

  /**
   * Handles click event when a patient is selected.
   * Closes the dialog, navigates to the dashboard, and adds a new tab for the selected patient.
   * @param patient The selected patient object.
   */
  public clickedPatient(patient: Patient): void {
    this.matDialogRef.close();
    this.router.navigate(['/dashboard']);
    const patientTabsItem: PatientTabsItem = {
      patientId: patient.user,
      name: patient.userFullName,
      patientActive: false,
    };
    this.tabs.addNewTab(patientTabsItem);
  }

  /**
   * Retrieves the list of patients based on the search name.
   * @param name The name to search for.
   * @returns An Observable of PatientListData containing the search results.
   */
  private getPatientList(name: string): Observable<PatientListData> {
    const url = `/user/search/member?search=name|${name}`;
    return this.commonService.get(url, '');
  }
}

/**
 * Represents a patient.
 */
interface Patient {
  /**
   * User ID of the patient.
   */
  user: string;
  /**
   * Full name of the patient.
   */
  userFullName: string;
  /**
   * URL of the patient's profile image.
   */
  userImageUrl: string;
}

/**
 * Represents a collection of patient data with pagination information.
 */
interface PatientListData {
  /**
   * Array of patients.
   */
  data: Patient[];
  /**
   * Current page number of the pagination.
   */
  currentPage: number;
  /**
   * Limit of items per page in the pagination.
   */
  limit: number;
  /**
   * Query used for searching patients.
   */
  query: string;
  /**
   * Total count of patients in the entire data set.
   */
  totalCount: number;
  /**
   * Total number of pages based on the pagination settings.
   */
  totalPage: number;
}
