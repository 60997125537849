<div class="dialog-sm">
  <div class="message-template">
    <div class="hs-tab-card-title flex-between" *ngIf="!fromCreateMessage">
      <h2>{{ 'templates' | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather name="check" class="pointer" (click)="submitTemplate()"></i-feather>
        <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
      </div>
    </div>
    <mat-dialog-content>
      <h3>{{ 'templates' | translate }}</h3>
      <div class="form-group search-input">
        <input type="text" class="form-control" placeholder="Search" [formControl]="searchQuery" />
        <i-feather name="search" class="feather-20 text-disabled icon-in-input"></i-feather>
      </div>

      <form>
        <div class="template-group">
          <h6 class="message-h6"></h6>
          <div class="template-radio" *ngFor="let template of displayedTemplates">
            <input
              type="radio"
              id="{{ template.id }}"
              name="messageTemplate"
              class="form-check-input"
              value="{{ template.id }}"
              (change)="selectTemplate(template)"
            />
            <label class="radio-label" for="tm1">
              <span class="body-text">{{ template.title }}</span>
              <span class="template-message ws-pre-line">{{ template.message }}</span>
            </label>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>
</div>
