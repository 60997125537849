import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
/**
 * Component for filtering options in the video lobby.
 */
@Component({
  selector: 'app-video-lobby-filter',
  templateUrl: './video-lobby-filter.component.html',
  styleUrl: './video-lobby-filter.component.scss',
})
export class VideoLobbyFilterComponent {
  /**
   * Constructor to initialize the VideoLobbyFilterComponent.
   * @param change Reference to Angular's ChangeDetectorRef for manual change detection.
   * @param dialog Reference to the MatDialogRef for interacting with the dialog instance.
   * @param data Injected data containing any additional information passed to the dialog.
   */
  public constructor(
    private change: ChangeDetectorRef,
    private dialog: MatDialogRef<VideoLobbyFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  /**
   * Closes the current dialog.
   */
  public closeDialog(): void {
    this.dialog.close();
  }
}
