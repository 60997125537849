<div class="dialog-message">
  <div class="new-message">
    <div class="hs-tab-card-title flex-between" [ngClass]="{ 'mob-hide': templatesVisible || contactsVisible }">
      <h2>{{ headerTitle | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather name="x" class="pointer" (click)="closeDialog()"></i-feather>
      </div>
    </div>
    <mat-dialog-content>
      <div class="new-message-container">
        <div class="new-message-col" [ngClass]="{ 'mob-hide': templatesVisible || contactsVisible }">
          <div class="form-group">
            <label for="" required>{{ 'to' | translate }} </label>
            <div class="form-control custom-chips-wrap" (click)="showContacts()">
              <span class="custom-chips" *ngFor="let participant of participantList; index as i">
                {{ participant.displayName }}&nbsp;
                <i-feather name="x" class="pointer feather-16" (click)="removeParticipant($event, i)"></i-feather>
              </span>
              <span class="custom-chips" *ngIf="selectedMangedUser">
                {{ selectedMangedUser.displayName }}&nbsp;
                <i-feather name="x" class="pointer feather-16" (click)="removeManagedUser($event)"></i-feather>
              </span>
            </div>
            <div *ngIf="clickedSend && !participantList.length && !selectedMangedUser" class="invalid-feedback">
              {{ 'addParticipantsError' | translate }}
            </div>
          </div>
          <div class="form-group">
            <label for="" required>{{ 'subject' | translate }} </label>
            <input type="text" class="form-control" [(ngModel)]="threadTopic" />
            <div *ngIf="clickedSend && !threadTopic" class="invalid-feedback">
              {{ 'noSubjectError' | translate }}
            </div>
          </div>
          <div class="form-group">
            <label for="" required>{{ 'message' | translate }} </label>
            <ckeditor
              class="form-control message-thread-ck-editor"
              [editor]="ckEditor"
              [config]="ckEditorConfig"
              [(ngModel)]="message"
              (change)="onMessageEdit()"
            ></ckeditor>
            <div *ngIf="clickedSend && !message" class="invalid-feedback">
              {{ 'noMessageError' | translate }}
            </div>
            <div *ngIf="!selectedTemplate && firstTimeTemplateSelected" class="warning-feedback">
              {{ 'participantChangeMessageTemplateWarning' | translate }}
            </div>
          </div>

          <ng-container *appPermissions="'ai.SuggestedHcpReplies.Read'">
            <div class="suggested-message-wrapper view-only-desktop" *ngIf="showSuggestedMessage">
              <app-suggested-message
                [patientId]="messageData.participantID"
                [mode]="'Alert'"
                (selectedSuggestion)="useSuggestion($event)"
              ></app-suggested-message>
            </div>
            <button class="btn btn-primary message-reset" *ngIf="showResetButton" (click)="resetMessage()">
              {{ 'reset' | translate }}
            </button>
          </ng-container>

          <div class="attachment-icons" *ngIf="attachments.length">
            <div class="attachment-icons-item" *ngFor="let attachment of attachments; index as i">
              <i-feather name="x-circle" class="attachment-cancel" (click)="removeFile(i)"></i-feather>
              <img [src]="attachment.fileIcon" alt="img" />
              <mat-progress-spinner
                class="attachment-icons-spinner"
                [mode]="'determinate'"
                [value]="attachment.uploadProgress"
                strokeWidth="4"
                diameter="40"
                *ngIf="attachment.uploadProgress"
              ></mat-progress-spinner>
            </div>
          </div>
        </div>
        <div class="contact-col" *ngIf="contactsVisible" [ngClass]="{ 'mob-hide': showPatientSummary }">
          <div class="hs-tab-card-title justify-content-end view-only-mob">
            <i-feather name="x" class="pointer" (click)="contactsVisible = false"></i-feather>
          </div>
          <app-message-contacts
            [participantList]="participantList"
            [selectedManagedUser]="selectedMangedUser"
            (updatedManagedUserEvent)="updateManagedUser($event)"
            (updatedParticipantList)="updateParticipantList()"
          ></app-message-contacts>
        </div>
        <div class="template-col" *ngIf="templatesVisible">
          <div class="hs-tab-card-title justify-content-end view-only-mob">
            <i-feather name="x" class="pointer" (click)="templatesVisible = false"></i-feather>
          </div>
          <app-message-template
            [fromCreateMessage]="true"
            (templateSelected)="selectTemplate($event)"
          ></app-message-template>
        </div>
        <div class="summary-col" *ngIf="showPatientSummary">
          <h3 class="patient-summary-title">
            <span class="patient-summary-title-text">{{ 'recentObservationSummary' | translate }}</span>
            <img class="beta-badge" src="assets/images/icons/beta-icon.svg" alt="beta" />
            <i-feather class="feather-20 pointer" name="refresh-cw" (click)="reloadObservationSummary()"></i-feather>
          </h3>
          <div class="hs-tab-card-title justify-content-end view-only-mob">
            <i-feather name="x" class="pointer" (click)="showPatientSummary = false"></i-feather>
          </div>
          <app-observation-summarization [patientId]="patientUserId()"></app-observation-summarization>
        </div>
      </div>
    </mat-dialog-content>
    <div class="new-message-footer" [ngClass]="{ 'mob-hide': templatesVisible || contactsVisible }">
      <div class="new-message-icons">
        <input type="file" #fileInput id="file" class="d-none" multiple (change)="onFileSelected($event)" />
        <i-feather name="paperclip" class="feather-20 pointer" (click)="fileInput.click()"></i-feather>
        <i-feather name="camera" class="feather-20 pointer" (click)="openCamera()"></i-feather>
        <i-feather name="file-text" class="feather-20 pointer" (click)="showTemplates()"></i-feather>
      </div>
      <button type="button" class="btn btn-primary" [disabled]="fileUploadsInProgress" (click)="createThread()">
        {{ 'send' | translate }}
      </button>
    </div>
  </div>
</div>
