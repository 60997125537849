import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CalendarRoutingModule } from './calendar-routing.module';
import { CalendarComponent } from './components/calendar/calendar.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DirectivesModule } from '../shared/directives/directive.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventFilterComponent } from './components/event-filter/event-filter.component';
import { MoreEventComponent } from './components/more-event/more-event.component';
import { CustomHeaderForDatepickerComponent } from './components/custom-header-for-datepicker/custom-header-for-datepicker.component';

@NgModule({
  declarations: [CalendarComponent, EventFilterComponent, MoreEventComponent, CustomHeaderForDatepickerComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    NgbTooltipModule,
    FeatherModule,
    PipesModule,
    MatSlideToggleModule,
    MatDialogModule,
    FullCalendarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SharedModule,
    DirectivesModule,
    FormsModule,
    NgbModule,
  ],
})
export class CalendarModule {}
