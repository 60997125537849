import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { GenericTable, GenericTableColumn } from 'src/app/modules/shared/interfaces/common.entities';
import { PaginationOptions } from 'src/app/modules/shared/interfaces/pagination-options';
import { Utils } from 'src/app/modules/shared/utils/utils';
import { UsersService } from 'src/app/modules/user-management/users.service';
import { GroupsService } from '../../groups.service';
/**
 * Represents member data in the system.
 */
export interface memberData {
  /**
   * Description of the member.
   */
  description: string;

  /**
   * Unique identifier for the member.
   */
  id: string;

  /**
   * Full name of the member.
   */
  name: string;

  /**
   * Owner of the member.
   */
  owner: string;

  /**
   * GroupType of the member.
   */
  groupType: string;

  /**
   * Indicates if the member belongs to the client default user group.
   */
  isClientDefaultUserGroup?: boolean;

  /**
   * Demographics of the owner.
   */
  owner_demographics: {
    /**
     * First name of the owner.
     */
    firstName: string;

    /**
     * URL of the owner's image.
     */
    imageUrl: string;

    /**
     * Last name of the owner.
     */
    lastName: string;

    /**
     * Username of the owner.
     */
    user: string;

    /**
     * Unique identifier for the owner.
     */
    _id: string;
  };

  /**
   * First name of the member.
   */
  firstName: string;

  /**
   * URL of the member's image.
   */
  imageUrl: string;

  /**
   * Last name of the member.
   */
  lastName: string;

  /**
   * Username of the member.
   */
  user: string;

  /**
   * Unique identifier for the member.
   */
  _id: string;
}

/**
 * Represents data for a table in the system.
 */
export interface tableData {
  /**
   * Identifier column in the table.
   */
  id: GenericTable;

  /**
   * Name column in the table.
   */
  name: GenericTable;

  /**
   * User type column in the table.
   */
  userType: GenericTable;

  /**
   * Role column in the table.
   */
  role: GenericTable;

  /**
   * Indicates if the row in the table is checked.
   */
  checked: boolean;
}
/**
 * Component for displaying and managing group details.
 */
@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss'],
})
export class GroupViewComponent implements OnInit {
  /**
   * Represents a form group used for managing group-related data.
   */
  groupForm: FormGroup;
  /**
   * Flag to track form submission.
   */
  submitted = false;
  /**
   * Client ID associated with the group.
   */
  clientID: string;
  /**
   * Group ID.
   */
  gid: string;
  /**
   * Details of group members.
   */
  members!: memberData;
  /**
   * Parameters for pagination.
   */
  params = { limit: 20, offset: 0 };
  /**
   * Pagination options for different group sections.
   */
  paginationCoOwnerOptions = { limit: 5, currentPage: 1, totalCount: 0 }; //this.params;
  /**
   * Maximum number of items to display per page.
   */
  paginationObserverOptions = { limit: 5, currentPage: 1, totalCount: 0 }; //this.params;
  /**
   * Options for pagination related to members.
   */
  paginationMembersOptions = { limit: 5, currentPage: 1, totalCount: 0 }; //this.params;
  // users: any = [];
  /**
   * Array of column names to be displayed.
   */
  displayedColumns: Array<string> = [];

  /**
   * Array of support group table data.
   */
  supportGroup: Array<tableData> = [];

  /**
   * Array of member group table data.
   */
  memberGroup: Array<tableData> = [];

  /**
   * Array of observer group table data.
   */
  observerGroup: Array<tableData> = [];

  /**
   * Boolean flag indicating the input status.
   */
  inputStatus = false;
  /**
   * Indicates whether to show a checkbox element.
   */
  showCheckbox = false;
  /**
   * The name of the owner, if available.
   */
  ownerName: string | undefined;

  /**
   * Owner identifier.
   */
  Owner!: string; // Note: consider initializing or setting a default value

  /**
   * List of managers (users) associated with the component.
   */
  managersList!: Array<User>;

  /**
   * Columns specific to the support group table.
   */
  columnsSupport = [
    { key: 'name', title: 'Name', displayKey: 'name', sort: true },
    { key: 'role', title: 'Role', displayKey: 'role', sort: true },
    {
      key: 'actions',
      title: 'Actions',
      displayKey: 'actions',
      hasLink: false,
      sort: false,
      icons: [
        {
          iName: 'edit',
          key: 'edit',
          class: '',
          show: false,
          route: '/groupManagment/view',
        },
        {
          iName: 'eye',
          key: 'view',
          class: '',
          show: false,
          route: '/groupManagment/view',
        },
        {
          iName: 'trash-2',
          key: 'delete',
          class: '',
          show: this.permissionService.hasPermission('self.Management_UserGroup.Update'),
          route: '',
        },
        {
          iName: 'settings',
          key: 'settings',
          class: '',
          show: false,
          route: '',
        },
      ],
    },
  ];

  /**
   * Columns for the member group table, initialized as a deep copy of `columnsSupport`.
   */
  memberGroupColumns = JSON.parse(JSON.stringify(this.columnsSupport));

  /**
   * Columns for the observers group table, initialized as a deep copy of `columnsSupport`.
   */
  observersGroupColumns = JSON.parse(JSON.stringify(this.columnsSupport));

  /**
   * Sorting criteria in the format 'fieldName|ASC/DESC'.
   */
  sort = 'name|DESC';

  /**
   * Flag indicating if the component is shown.
   */
  isShow = false;

  /**
   * Flag indicating if the component is active.
   */
  isActive = true;

  /**
   * Flag indicating if the active class should be applied.
   */
  activeClass = true;

  /**
   * Flag indicating if the component is visible.
   */
  isVisible = false;

  /**
   * Flag indicating if the current user is the owner.
   */
  isOwner = false;

  /**
   * Index of the selected item, or null if none is selected.
   */
  selectedIndex: number | null = 0;

  /**
   * Constructor initializing dependencies and setting up initial state.
   * @param formBuilder Service for building Angular forms.
   * @param router Service for routing navigation.
   * @param route Service for accessing route parameters.
   * @param toast Service for displaying toast notifications.
   * @param dialog Service for displaying dialogs.
   * @param groupsService Service for managing groups (adjust path as per project structure).
   * @param usersService Service for managing users (adjust path as per project structure).
   * @param permissionService Service for managing permissions (adjust path as per project structure).
   * @param cdr Service for detecting and handling changes in Angular's change detection.
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private dialog: MatDialog,
    private groupsService: GroupsService,
    private usersService: UsersService,
    private permissionService: PermissionsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.clientID = String(this.route.snapshot.queryParamMap.get('clientId'));
    this.gid = this.route.snapshot.queryParamMap.get('gid') || '';
    this.selectedIndex = Number(this.route.snapshot.queryParamMap.get('selectedIndex'));
    this.groupForm = this.formBuilder.group({
      gName: [],
      description: [],
      owner: [''],
    });
    //  this.selectedIndex
  }

  /**
   * This method initializes various group details and retrieves data necessary
   * for displaying or managing groups within the component.
   */
  ngOnInit(): void {
    this.getGroupsDetails(this.gid);
    this.getSupportGroupDetails();
    this.getMemberGroupDetails();
    this.getObserverGroupDetails();
    this.getManagers();
    this.cdr.detectChanges();
  }
  /**
   * Method to display column titles from GenericTableColumn array.
   * @param columns Array of GenericTableColumn objects
   * @returns Array of column titles
   */
  displayColumns(columns: Array<GenericTableColumn>) {
    return columns.map((column: GenericTableColumn) => {
      return column.title;
    });
  }
  /**
   * Toggle input status for editing.
   */
  clickEventEdit() {
    this.inputStatus = !this.inputStatus;
  }
  /**
   * Handle next page event for pagination.
   * @param event PaginationOptions object containing limit and offset
   */
  groupNextPage(event: PaginationOptions) {
    this.params.limit = event.limit;
    this.params.offset = event.offset !== undefined ? event.offset : 1;
  }

  /**
   * Refresh users based on pagination options and table name.
   * @param event PaginationOptions object containing limit and currentPage
   * @param tableName Name of the table ('member', 'observers', 'coOwner')
   */
  refreshUsers(event: PaginationOptions, tabelName: string) {
    if (tabelName === 'member') {
      this.paginationMembersOptions.limit = event.limit;
      this.paginationMembersOptions.currentPage = event.currentPage;
      this.getMemberGroupDetails();
    } else if (tabelName === 'observers') {
      this.paginationObserverOptions.limit = event.limit;
      this.paginationObserverOptions.currentPage = event.currentPage;
      this.getObserverGroupDetails();
    } else {
      this.paginationCoOwnerOptions.limit = event.limit;
      this.paginationCoOwnerOptions.currentPage = event.currentPage;
      this.getSupportGroupDetails();
    }
  }

  /**
   * Fetch support group details.
   */
  getSupportGroupDetails() {
    this.usersService
      .getGroupsList(
        'coOwner',
        this.gid,
        this.paginationCoOwnerOptions.limit,
        this.paginationCoOwnerOptions.currentPage,
        this.sort,
      )
      .subscribe((result) => {
        this.paginationCoOwnerOptions = result.pagination;
        this.supportGroup = result.data?.map((userData: MemberData) => {
          //the actual id of user in the user property, so assigned here, which will be used in delete route
          userData.id = userData.user;
          return this.createTableViewClients(userData);
        });
      });
  }

  /**
   * Fetch member group details.
   */
  getMemberGroupDetails() {
    this.usersService
      .getGroupsList(
        'member',
        this.gid,
        this.paginationMembersOptions.limit,
        this.paginationMembersOptions.currentPage,
        this.sort,
      )
      .subscribe((result) => {
        this.paginationMembersOptions = result.pagination;
        // console.log(result);
        this.memberGroup = result.data?.map((userData: MemberData) => {
          //the actual id of user in the user property, so assigned here, which will be used in delete route
          userData.id = userData.user;
          return this.createTableViewClients(userData);
        });
      });
  }
  /**
   * Fetch observer group details.
   */
  getObserverGroupDetails() {
    this.usersService
      .getGroupsList(
        'observer',
        this.gid,
        this.paginationObserverOptions.limit,
        this.paginationObserverOptions.currentPage,
        this.sort,
      )
      .subscribe((result) => {
        this.paginationObserverOptions = result.pagination;
        // this.setPaginationOptions(result.pagination);
        this.observerGroup = result.data?.map((userData: MemberData) => {
          //the actual id of user in the user property, so assigned here, which will be used in delete route
          userData.id = userData.user;
          return this.createTableViewClients(userData);
        });
      });
  }
  /**
   * Create table view data for a user.
   * @param user MemberData object representing a user
   * @returns tableData object for the user
   */
  createTableViewClients(user: MemberData) {
    const tableData = {
      id: Utils.createGenericTableKeysData(user.id, '/groupManagment/view', {
        clientId: this.clientID,
        uid: user.id,
      }),
      name: Utils.createGenericTableKeysData(user.name, '', {}),
      userType: Utils.createGenericTableKeysData(user.userType, '', {}),
      // role: Utils.createGenericTableKeysData(user?.roles[0]?.label, '', {}),
      role: Utils.createGenericTableKeysData(user?.roles.map((item) => item.label).join(', '), '', {}),
      checked: false,
    };
    return tableData;
  }

  /**
   * Delete support member from group.
   * @param id ID of the member to be deleted
   */
  deleteSupport(id: string) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirm remove Support from group',
        message: 'Are you sure, you want to remove an user from group?',
      },
      panelClass: 'confirmation-dialog',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteUserFromGroup(id, 'coOwner');
      }
    });
  }

  /**
   * Delete member from group.
   * @param id ID of the member to be deleted
   */
  deleteMember(id: string) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirm remove Member from group',
        message: 'Are you sure, you want to remove an user from group?',
      },
      panelClass: 'confirmation-dialog',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteUserFromGroup(id, 'member');
      }
    });
  }

  /**
   * Delete observer from group.
   * @param id ID of the observer to be deleted
   */
  deleteObserver(id: string) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirm remove Observer from group',
        message: 'Are you sure, you want to remove an user from group?',
      },
      panelClass: 'confirmation-dialog',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteUserFromGroup(id, 'observer');
      }
    });
  }

  /**
   * Delete user from group based on user level (coOwner, member, observer).
   * @param id ID of the user to be deleted
   * @param userlevel Level of the user ('coOwner', 'member', 'observer')
   */
  deleteUserFromGroup(id: string, userlevel: string) {
    this.groupsService
      .deleteSupport(this.gid, userlevel, id)
      .pipe(
        map((response) => {
          return response;
        }),
      )
      .subscribe(() => {
        this.toast.success('User removed from group Successfully');
        if (userlevel == 'coOwner') {
          this.getSupportGroupDetails();
        }
        if (userlevel == 'member') {
          this.getMemberGroupDetails();
        }
        if (userlevel == 'observer') {
          this.getObserverGroupDetails();
        }
        // userlevel == "coOwner"
        //   ? this.getSupportGroupDetails()
        //   : this.getMemberGroupDetails();
      });
  }

  /**
   * Fetch group details based on group ID.
   * @param gid Group ID
   */
  getGroupsDetails(gid: string) {
    this.groupsService.getGroupsDetail(gid).subscribe((result) => {
      this.members = result;
      if (this.members.owner_demographics) {
        this.Owner = this.members.owner_demographics.firstName + ' ' + this.members.owner_demographics.lastName;
      }
      this.groupForm.patchValue({
        gName: result.name,
        description: result.description,
        owner: result.owner,
      });
    });
  }

  /**
   * Update group details.
   * Validates form and updates group data.
   */
  updateGroup() {
    this.submitted = true;
    const postData = {
      name: this.groupForm.value.gName,
      description: this.groupForm.value.description,
      owner: this.groupForm.value.owner,
    };
    if (this.groupForm.valid) {
      this.groupsService.updateGroup(postData, this.gid).subscribe({
        next: (response) => {
          if (response) {
            const obj = this.managersList.find((item: User) => item.user === postData.owner);
            this.Owner = obj !== undefined ? obj.name : this.Owner;
            this.toast.success('Group updated Successfully');
          }
        },
      });
    }
  }

  /**
   * Toggle display of some UI element.
   */
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  /**
   * Toggle visibility of some UI element.
   */
  toggle() {
    this.isVisible = !this.isVisible;
  }

  /**
   * Toggle owner status.
   */
  toggleOwner() {
    this.isOwner = !this.isOwner;
  }

  /**
   * Sort support group based on column and order.
   * @param eve Object containing column and order details
   */
  sortCoOwnerGrp(eve: { column: string; order: string }) {
    this.sort = eve.column + '|' + eve.order;
    this.getSupportGroupDetails();
  }

  /**
   * Sort member group based on column and order.
   * @param eve Object containing column and order details
   */
  sortMemberGrp(eve: { column: string; order: string }) {
    this.sort = eve.column + '|' + eve.order;
    this.getMemberGroupDetails();
  }

  /**
   * Sort observer group based on column and order.
   * @param eve Object containing column and order details
   */
  sortObserverGrp(eve: { column: string; order: string }) {
    this.sort = eve.column + '|' + eve.order;
    this.getObserverGroupDetails();
  }

  /**
   * Fetch managers list.
   * Retrieves a list of managers and assigns it to `managersList`.
   */
  getManagers() {
    this.usersService.getManagers().subscribe(
      (result) => {
        if (result.data && result.data.length > 0) {
          this.managersList = result.data;
        }
      },
      (error) => {
        if (error) {
          //console.log(error);
        }
      },
    );
  }
}

/**
 * Represents a user in the system.
 */
interface User {
  /**
   * Unique identifier for the user.
   */
  id: string;

  /**
   * Username of the user.
   */
  user: string;

  /**
   * Client associated with the user.
   */
  client: string;

  /**
   * List of clinical trials associated with the user.
   */
  clinicalTrials: ClinicalTrial[];

  /**
   * List of content groups associated with the user.
   */
  contentGroups: ContentGroup[];

  /**
   * URL of the user's image.
   */
  imageUrl: string;

  /**
   * Full name of the user.
   */
  name: string;

  /**
   * List of roles assigned to the user.
   */
  roles: Role[];

  /**
   * Status of the user (active, inactive, etc.).
   */
  status: string;

  /**
   * Type of user (e.g., admin, regular).
   */
  userType: string;

  /**
   * List of user groups the user belongs to.
   */
  userGroups: UserGroup[];
}

/**
 * Represents a clinical trial.
 */
interface ClinicalTrial {
  /**
   * Unique identifier for the clinical trial.
   */
  id: string;

  /**
   * Name of the clinical trial.
   */
  name: string;
}

/**
 * Represents a content group.
 */
interface ContentGroup {
  /**
   * Unique identifier for the content group.
   */
  id: string;

  /**
   * Name of the content group.
   */
  name: string;
}

/**
 * Represents a role.
 */
interface Role {
  /**
   * Unique identifier for the role.
   */
  id: string;

  /**
   * Name of the role.
   */
  name: string;

  /**
   * Label or description of the role.
   */
  label: string;
}

/**
 * Represents a user group.
 */
interface UserGroup {
  /**
   * Name or identifier of the group.
   */
  group: string;

  /**
   * Name of the group.
   */
  groupName: string;

  /**
   * Relation or role of the user within the group.
   */
  relationToGroup: string;
}

/**
 * Represents data for a member.
 */
interface MemberData {
  /**
   * List of content groups associated with the member.
   */
  contentGroups: Array<ContentGroup>;

  /**
   * Unique identifier for the member.
   */
  id: string;

  /**
   * URL of the member's image.
   */
  imageUrl: string;

  /**
   * Full name of the member.
   */
  name: string;

  /**
   * Type of the member.
   */
  userType: string;

  /**
   * List of roles assigned to the member.
   */
  roles: Role[];

  /**
   * Username of the member.
   */
  user: string;
}
