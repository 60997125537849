import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { PermissionsModule } from 'src/app/modules/permissions/permissions.module';
import { DirectivesModule } from '../shared/directives/directive.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { NotesComponent } from './component/notes.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { NotesWidgetComponent } from './notes-widget/notes-widget.component';

/**
 * Module that selects a subset of Feather icons from FeatherModule.
 */
const matModules = [FeatherModule.pick(allIcons)];

@NgModule({
  declarations: [NotesDialogComponent, NotesWidgetComponent, NotesComponent],
  imports: [
    CommonModule,
    matModules,
    FormsModule,
    NgbModule,
    PermissionsModule,
    MatDialogModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
  ],
  exports: [matModules, PermissionsModule],
})
export class NotesModule {
  public getComponent() {
    return NotesWidgetComponent;
  }
}
