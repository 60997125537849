import { Pipe, PipeTransform } from '@angular/core';
/**
 * A pipe that filters an array of objects based on a search string and an optional search key.
 * If a search key is provided, it filters by the specified property of each object.
 * If no search key is provided, it filters by searching through all properties of each object.
 */
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  /**
   * Filters an array of objects based on a search string and an optional search key.
   *
   * @param items The array of objects to be filtered.
   * @param searchText The string to search for within the objects.
   * @param searchKey Optional. The specific object property to filter by.
   * @returns The filtered array of objects.
   */
  transform(items: any[], searchText: string, searchKey?: string) {
    if (!items) return [];
    if (!searchText) return items;
    if (searchKey) {
      return items.filter((items) => {
        return String(items[searchKey]).toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      return items.filter((item) => {
        return Object.keys(item).some((key) => {
          return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }
  }
}
